import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DefaultInput, SelectInput } from "../../../../common";
import { FormValues } from "../../types";

type TProps = { productIndex?: number };

export const VisibleWrap = ({ productIndex }: TProps) => {
  const { t } = useTranslation();
  const visibilityOptions = [
    { label: t("show"), value: "true" },
    { label: t("not.show"), value: "false" },
  ];

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const basePath =
    productIndex !== undefined
      ? (`variationProducts.${productIndex}` as const)
      : ("product" as const);
  return (
    <>
      <Controller
        control={control}
        name={`${basePath}.show`}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            width="100%"
            value={visibilityOptions.find((el) => el.value === value)}
            options={visibilityOptions}
            label={t("visibility")}
            error={
              productIndex !== undefined
                ? errors?.variationProducts?.[productIndex]?.show?.message
                : errors?.product?.show?.message
            }
            onChange={(v) => {
              if (v) {
                onChange(v.value);
              }
            }}
          />
        )}
      />
      <DefaultInput
        width="100%"
        {...register(`${basePath}.sku`, { valueAsNumber: true })}
        label={t("SKU")}
        type="number"
        placeholder={t("enter.code")}
        error={
          productIndex === undefined
            ? errors?.product?.sku?.message
            : errors?.variationProducts?.[productIndex]?.sku?.message
        }
      />
    </>
  );
};
