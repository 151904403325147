import { useTranslation } from "react-i18next";
import { TOption, TSort, useTypedSelector } from "../../common";
import {
  ApiCategoryService,
  RESPONSE,
  getMainProductSelector,
  getUserSelector,
  productActions,
} from "../../store";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { TCategory } from "../../store/category/types";
import { DROP_DOWN_LIST_LIMIT, PAGE_LIMIT } from "./consts";
import { TLang } from "../../types";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Image } from "./styled";
import { api } from "../../common/config/api";

export const useProductLogic = () => {
  const { accessToken } = useTypedSelector(getUserSelector);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const local = i18n.language as TLang;
  const [searchParams, setSearchParams] = useSearchParams({});
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(0);

  const { products, response, loading } = useTypedSelector(
    getMainProductSelector
  );

  const [searchForm, setSearchForm] = useState({
    value: "",
    start: "",
    end: "",
  });
  const [filterForm, setFilterForm] = useState({
    category: { value: "", label: "" } as TOption,
    search: "",
  });
  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    if (!sortParamsForm.order) {
      return { id: null, desc: false };
    }
    return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
  }, [sortParamsForm]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const data = useMemo(
    () =>
      products.data.map((product: any) => {
        const publish_date = product?.createdAt;
        return {
          id: <>{product.sku}</>,
          photo: (
            <Image
              src={`${api.withImageAPI}/product/preview/${product.preview}`}
            />
          ),
          label: <>{product.description && product.description?.title}</>,
          publish_date: <>{moment(publish_date).format("DD.MM.YYYY HH:mm")}</>,
          category: (
            <>
              {product.category.title &&
              typeof product.category.title === "string"
                ? product.category.title
                : product.category.description?.find(
                    (el: any) => el.lang === local
                  )?.title}
            </>
          ),
          total: <>{product.amount}</>,
          price: <>{product.price}</>,
          sellStatus: <>{t(product.sellStatus.split(" ").join("."))}</>,
        };
      }),
    [products]
  );

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    loadOptions: async (search: string, loadOptions: any, additions: any) => {
      const page = additions?.page || 0;

      const options = await Requests.getCategories(page);

      return {
        options: (options.data.data as TCategory[])
          .filter((el) => !!el.productsTotalCount && !el.categoriesTotalCount)
          .map((item) => ({
            value: item._id,
            label: item.title,
          })),
        hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
        additional: {
          page: page + 1,
        },
      };
    },
    onChangeHandler: (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setSearchForm({ ...searchForm, [input.name]: input.value });
      Requests.getProducts();
    },
    onSubmitSearchHandler: (e: SyntheticEvent) => {
      e.preventDefault();
      Requests.getProducts();
    },
    paginateDropDownChangeHandler: (value?: TOption) => {
      if (value) {
        setFilterForm(() => ({ category: value, search: "" }));
      } else {
        setFilterForm({ category: { value: "", label: "" }, search: "" });
      }
    },
    searchInDropdown: (value: string) => {
      setFilterForm((prev) => ({ ...prev, search: value }));
    },
    handlerBlurFilter: () => {
      setFilterForm((form: any) => ({
        ...form,
        category: { value: "", label: "" },
      }));
    },
    sortToggleHandler: (sortBy: string, order: TSort) => {
      setSortParamsForm({ sortBy, order });
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    addButtonClickHandler: () => {
      navigate("/product-create");
    },
    editClickHandler: (e: SyntheticEvent, index: number) => {
      navigate(`/product/${products.data[index]._id}`);
    },
    removeProducts: () => {
      selectedItems.forEach((index) => {
        const { _id } = products?.data[index];
        Requests.removeProduct(_id);
      });
      setRemoveButtonState(false);
      setSelectedItems([]);
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("product.code"),
        accessor: "id",
        width: 100,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("photo"),
        accessor: "photo",
      },
      {
        Header: t("label"),
        accessor: "label",
        width: 200,
      },
      {
        Header: t("publish.date"),
        accessor: "publish_date",
        width: 200,
      },
      {
        Header: t("category"),
        accessor: "category",
        width: 200,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total"),
        accessor: "total",
        width: 75,
      },
      {
        Header: t("price"),
        accessor: "price",
        width: 100,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("product.status"),
        accessor: "sellStatus",
        width: 150,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const Requests = {
    getProducts: () => {
      dispatch(
        productActions.getProducts({
          limit: PAGE_LIMIT,
          page,
          lang: local,
          query: searchForm.value,
          date_start: searchForm.start,
          date_end: searchForm.end,
          category: [filterForm.category.value],
          ...sortParamsForm,
        })
      );
    },
    removeProduct: (_id: string) => {
      dispatch(productActions.removeProduct({ _id }));
    },
    getCategories: async (page: number = 0) => {
      return await ApiCategoryService.getCategories({
        token: accessToken as string,
        limit: DROP_DOWN_LIST_LIMIT,
        page,
        lang: local,
        name: filterForm.search,
      });
    },
  };

  useEffect(() => {
    Requests.getProducts();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, filterForm, tableSortBy, searchForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (response === RESPONSE.REMOVED) {
      Requests.getProducts();
    }
  }, [response]);

  useEffect(() => {
    if (!loading && products?.meta?.needReload) Requests.getProducts();
  });

  return {
    Requests,
    Events,
    page,
    setPage,
    filterForm,
    tableSortBy,
    searchForm,
    columns,
    data,
    removeButtonState,
  };
};
