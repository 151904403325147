import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ApiProductService,
  getUserSelector,
  showToastAction,
} from "../../store";
import {
  Assets,
  ButtonNew,
  generateErrorToast,
  generateSuccessToast,
  useTypedSelector,
} from "../../common";

import { BasicData, CreateVariation, ProductSubBar } from "./components";
import {
  Image,
  Container,
  Wrapper,
  Header,
  Title,
  TitleBlock,
  DateBlock,
  FlexStyledContainer,
} from "./styled";
import { formSchema, FormValues, TDescription } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import {
  createproductFunction,
  setdefaultData,
  editProduct,
  hasDuplicateVariations,
} from "./helpers";
import useSWR from "swr";
import { getLocale } from "../../types";
import { TProduct } from "../../store/product/types";
import moment from "moment";
import VariationList from "./components/VariationList/VariationList";

const CreateProduct: FC = () => {
  const { t } = useTranslation();
  const local = getLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useTypedSelector(getUserSelector);
  const token = `Bearer ${accessToken}`;
  const { id } = useParams();
  const [nestedCategories, setNestedCategories] = useState<
    Record<string, { label: string; value: string }[]>
  >({});

  const params = { lang: local, token, _id: id || "" };

  const { data: productData } = useSWR(
    params._id ? ["getProduct", params] : null,
    ([, par]) => ApiProductService.getProduct(par),
    { revalidateOnFocus: false }
  );

  const { data: descriptionsData } = useSWR(
    id ? ["getDescription", { _id: id, token }] : null,
    ([, par]) => ApiProductService.getDescription(par),
    { revalidateOnFocus: false }
  );
  const productInfo = productData?.data.data as TProduct;

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "simple",
      product: { show: "true" },
      variations: [],
    },

    mode: "onChange",
  });

  const [activePage, setActivePage] = useState<string>("mainData");

  const onSubmit = async (data: FormValues) => {
    if (hasDuplicateVariations(data.variationProducts)) {
      return dispatch(
        showToastAction.request(
          generateErrorToast(t("the.variation.value.must.not.be.repeated"))
        )
      );
    }
    try {
      if (id) {
        await editProduct(data, token, id);
      } else {
        await createproductFunction(data, token);
      }
      navigate("../products-management");
      dispatch(
        showToastAction.request(
          generateSuccessToast(
            id
              ? t("product.updated.successfully")
              : t("product.was.created.successfully")
          )
        )
      );
    } catch (error) {
      dispatch(
        showToastAction.request(generateErrorToast(t("product.creating.error")))
      );
    }
  };

  const onDeleteClick = async () => {
    await ApiProductService.removeProduct({
      token: `Bearer ${accessToken}`,
      _id: id,
    });

    navigate("/products-management");
  };
  const descriptions = descriptionsData?.data.data as TDescription[];
  useEffect(() => {
    if (productInfo && descriptions) {
      setdefaultData(
        productInfo,
        methods.reset,
        token,
        descriptions,
        setNestedCategories
      );
    }
  }, [descriptions, methods.reset, productInfo, token]);

  const onSaveClick = () => {
    const { errors } = methods.formState;
    if (Object.keys(errors).length > 0) {
      return dispatch(
        showToastAction.request(generateErrorToast(t("enterAllValues")))
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <Container as={"form"} onSubmit={methods.handleSubmit(onSubmit)}>
        <Header>
          <TitleBlock>
            <Image
              onClick={() => navigate("../products-management")}
              src={Assets.ARROW_LEFT}
            />
            <Title>{id ? t("edit") : t("create.product")}</Title>
            {id && (
              <DateBlock>
                <p>
                  {t("createdAt.date")}{" "}
                  {moment(productInfo?.createdAt).format("YYYY-MM-DD HH:mm")}
                </p>
                <p>
                  {t("updatedAt.date")}{" "}
                  {moment(productInfo?.updatedAt).format("YYYY-MM-DD HH:mm")}
                </p>
              </DateBlock>
            )}
          </TitleBlock>
          <FlexStyledContainer width={id ? "450px" : "200px"}>
            {id && (
              <ButtonNew onClick={onDeleteClick} theme={"red"}>
                {" "}
                {t("delete")}{" "}
              </ButtonNew>
            )}
            <ButtonNew onClick={onSaveClick} type="submit" theme="green">
              {!id ? t("create") : t("save")}
            </ButtonNew>
          </FlexStyledContainer>
        </Header>
        <Wrapper>
          <ProductSubBar
            setActivePage={setActivePage}
            activePage={activePage}
          />
          <>
            {activePage === "mainData" && (
              <BasicData
                changeTab={() => setActivePage("variations")}
                nestedCategories={nestedCategories}
                setNestedCategories={setNestedCategories}
              />
            )}
            {activePage === "variations" && <CreateVariation />}
            {activePage === "variationProducts" && <VariationList />}
          </>
        </Wrapper>
      </Container>
    </FormProvider>
  );
};

export default CreateProduct;
