import styled from "styled-components";
import { colors } from "../../../../../../common";

export const InputsWrap = styled.div<{ level: number }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 10px;
  margin-left: ${({ level }) => `${Math.min(level * 15, 200)}px`};
  border-left: 2px solid ${({ level }) => `hsl(${level * 30}, 70%, 70%)`};
  background-color: ${({ level }) => `hsl(${level * 30}, 90%, 95%)`};
  border-radius: 4px;

  &:hover {
    background-color: ${({ level }) => `hsl(${level * 30}, 90%, 90%)`};
  }
`;

export const AddSubCategory = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background-color: ${colors.green};
  color: ${colors.white};
  padding: 12px;
  border-radius: 12px;
  width: 250px;
`;
export const DeleteButton = styled(AddSubCategory)`
  background-color: ${colors.red};
`;
export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
