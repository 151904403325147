import { UploadWrap } from "./styled";
import { RiUploadFill } from "react-icons/ri";
import { UploadProps } from "./types";

export const UploadPhoto = ({
  isRound = false,
  onClick,
  error,
  width = "219px",
  height = "219px",
}: UploadProps) => {
  return (
    <UploadWrap
      width={width}
      height={height}
      onClick={onClick}
      $round={isRound}
      $error={error}
    >
      <RiUploadFill />
      {/* <p>Upload or drag the file(s)</p> */}
    </UploadWrap>
  );
};
