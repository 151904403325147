import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
  FlexContainer,
  SelectInput,
  useTypedSelector,
} from "../../../../common";

import { ApiCategoryService, getUserSelector } from "../../../../store";
import { ApiBrandServices } from "../../../../store/brand/api.service";
import { TCategories } from "../../../../store/category/types";
import { getLocale } from "../../../../types";
import { FormValues } from "../../types";
import { handleCategoryChange, handleSubCategoryChange } from "./helpers";
import { TProps } from "./types";

const Catalog = ({ setNestedCategories, nestedCategories }: TProps) => {
  const { t, i18n } = useTranslation();
  const local = getLocale();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "subcategories",
  });

  const { accessToken } = useTypedSelector(getUserSelector);

  const { data: categoriesData } = useSWR(
    [
      "getCategoriesProducts",
      { limit: 200, lang: local, token: accessToken || "" },
    ],
    ([, par]) => ApiCategoryService.getCategoriesParent(par),
    { keepPreviousData: true, revalidateOnFocus: false }
  );

  const categories = categoriesData?.data as TCategories;

  const categoryOptions = categories?.data?.map((category) => ({
    label: category?.title,
    value: category?._id,
  }));

  const { data: brandsFes } = useSWR(
    [
      "getBrands",
      {
        limit: 200,
        token: `Bearer ${accessToken}`,
        sortBy: "createdAt",
        order: "asc",
        page: 0,
        lang: i18n.language,
      },
    ],
    ([, par]) => ApiBrandServices.getBrands(par),
    { keepPreviousData: true, revalidateOnFocus: false }
  );

  const brandOptions = brandsFes?.data?.data?.map((brand: any) => ({
    label: brand?.title,
    value: brand?._id,
  })) as { label: string; value: string }[];

  return (
    <FlexContainer
      direction="column"
      gap="30px"
      style={{ marginTop: "8px", padding: "20px" }}
    >
      <Controller
        control={control}
        name="category"
        render={({ field: { value, onChange } }) => (
          <SelectInput
            error={errors?.category?.message}
            placeholder={t("choose.category")}
            label={t("category")}
            options={categoryOptions}
            value={categoryOptions?.find((el) => el.value === value)}
            onChange={async (v) => {
              onChange(v?.value);
              const cat = await handleCategoryChange({
                categoryId: v?.value || "",
                setNestedCategories,
                accessToken: accessToken || "",
                local,
              });
              if (cat?.length) {
                setValue("subcategories", [
                  {
                    parentId: v?.value || "",
                    value: { label: "", value: "" },
                  },
                ]);
              } else {
                setValue("subcategories", []);
              }
            }}
          />
        )}
      />
      {fields?.map((item, index) => (
        <Controller
          control={control}
          key={item.id}
          name={`subcategories.${index}.value`}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              value={value.value ? value : undefined}
              placeholder={t("choose.subcategory")}
              label={t("subcategory")}
              options={nestedCategories[item.parentId]}
              error={errors?.subcategories?.[index]?.value?.value?.message}
              onChange={async (v) => {
                onChange(v);
                const cat = await handleSubCategoryChange({
                  parentCategoryId: item.parentId,
                  subcategoryId: v?.value || "",
                  nestedCategories,
                  setNestedCategories,
                  local,
                  accessToken: accessToken || "",
                });
                if (cat?.length) {
                  append({
                    parentId: v?.value || "",
                    value: { label: "", value: "" },
                  });
                } else {
                  for (let j = fields.length - 1; j > index; j--) {
                    remove(j);
                  }
                }
              }}
            />
          )}
        />
      ))}
      <Controller
        control={control}
        name="brand"
        render={({ field: { value, onChange } }) => (
          <SelectInput
            placeholder={t("choose.brand")}
            label={t("brand")}
            options={brandOptions}
            value={brandOptions?.find((el) => el.value === value)}
            onChange={(v) => onChange(v?.value)}
            error={errors?.brand?.message}
          />
        )}
      />
    </FlexContainer>
  );
};

export default Catalog;
