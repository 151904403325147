import { useTranslation } from "react-i18next";

import {
  FiltersWrapper,
  FlexContainer,
  HeaderWrapper,
  SubPagesBar,
  useTypedSelector,
  Table,
  DateInput,
  SearchBar,
  DataTotal,
  Pagination,
  ButtonNew,
} from "../../common";

import { getMainAvailabilitySelector } from "../../store";

import {
  MainContainer,
  Container,
  Header,
  Title,
  TitleBlock,
  FlexStyledContainer,
  ButtonBlock,
} from "./styled";
import { useNotificationLogic } from "./useNotificationLogic";
import { useEffect } from "react";

const Availability = () => {
  const { t } = useTranslation();

  const {
    searchParams,
    tableSortBy,
    setSearchParams,
    paginationSections,
    sortParamsForm,
    Requests,
    setPage,
    page,
    searchForm,
    Events,
    removeButtonState,
    data,
    columns,
  } = useNotificationLogic();

  const { availabilitys } = useTypedSelector(getMainAvailabilitySelector);

  useEffect(() => {
    Requests.getAvailability();
  }, []);

  useEffect(() => {
    Requests.getAvailability();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, sortParamsForm, searchForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  return (
    <Container>
      <Header>
        <TitleBlock>
          <Title>{t("orders")}</Title>
        </TitleBlock>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew
                width={195}
                theme="red"
                onClick={Events.removeCheckedItemsHandler}
              >
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />
        <FlexContainer direction="column" gap="30px">
          <HeaderWrapper>
            <FiltersWrapper style={{ marginLeft: "-30px" }}>
              <SearchBar
                name="searchValue"
                placeholder={t("search")}
                value={searchForm.searchValue}
                onChange={Events.onChangeHandler}
                onSubmit={Events.onSubmitSearchHandler}
              />
              <DateInput
                name="startDateValue"
                value={searchForm.startDateValue}
                onChange={Events.onChangeHandler}
              />
              <DateInput
                name="endDateValue"
                value={searchForm.endDateValue}
                onChange={Events.onChangeHandler}
              />
            </FiltersWrapper>
            <DataTotal>
              {t("total.orders")} {availabilitys.meta?.totalCount}
            </DataTotal>
          </HeaderWrapper>

          <Table
            columns={columns}
            data={data}
            checkboxClickHandler={Events.checkboxClickHandler}
            sortBy={tableSortBy}
          />
        </FlexContainer>

        <Pagination
          page={page}
          pageCount={
            availabilitys.meta
              ? Math.ceil(availabilitys.meta.totalCount / 9)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />
      </MainContainer>
    </Container>
  );
};

export default Availability;
