import { nanoid } from "@reduxjs/toolkit";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { z } from "zod";
import { ApiProductService } from "../../../../store";
import { TProduct } from "../../../../store/product/types";
import { FormValues, ProductSchema, VariationDataSchema } from "../../types";

type Tprops = {
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  token: string;
  product?: TProduct;
};
type Tproduct = z.infer<typeof ProductSchema>;

type TVariationDataSchema = z.infer<typeof VariationDataSchema>;

const updateProductsVariations = (
  products: Tproduct[],
  oldVariations: TVariationDataSchema[],
  newVariations: TVariationDataSchema[]
): Tproduct[] => {
  const variationMap = new Map<
    string,
    { newVariationId: string; valueMap: Map<string, string> }
  >();

  oldVariations.forEach((oldVariation, index) => {
    const newVariation = newVariations[index];
    if (newVariation) {
      const valueMap = new Map<string, string>();

      oldVariation.values.forEach((oldValue, i) => {
        const newValue = newVariation.values[i];
        if (newValue) {
          valueMap.set(oldValue.id, newValue.id);
        }
      });

      variationMap.set(oldVariation.id, {
        newVariationId: newVariation.id,
        valueMap,
      });
    }
  });

  const updatedProducts = products.map((product) => {
    if (!product.variations) return product;

    const updatedVariations = product.variations.map((variation) => {
      const mapping = variationMap.get(variation.variation);
      if (mapping) {
        const newVariationId = mapping.newVariationId;
        const newValueId = mapping.valueMap.get(variation.value);
        if (newValueId) {
          return {
            ...variation,
            variation: newVariationId,
            value: newValueId,
          };
        }
      }
      return variation;
    });

    return {
      ...product,
      variations: updatedVariations,
    };
  });

  return updatedProducts;
};

// export const onSaveVariation = async ({
//   token,
//   setValue,
//   watch,
//   product,
// }: Tprops) => {
//   const currentGroup = watch("group");

//   const variations = watch("variations")?.sort((a) =>
//     a.type === "text" ? 1 : -1
//   );
//   const currentVariationsIds = variations?.map((item) => item.id) || [];

//   const startVariationsIds =
//     product?.group?.variations?.map((item) => item.variation._id) || [];

//   const newVariations =
//     variations?.filter((item) => !startVariationsIds.includes(item.id)) || [];

//   const deletedVariations =
//     product?.group?.variations?.filter(
//       (item) => !currentVariationsIds?.includes(item.variation._id)
//     ) || [];

//   const existingVariations =
//     variations?.filter((item) => startVariationsIds.includes(item.id)) || [];

//   if (deletedVariations.length > 0) {
//     const deletePromises = deletedVariations.map((item) =>
//       ApiProductService.removeVariation({ token, _id: item.variation._id })
//     );
//     await Promise.all(deletePromises);
//   }
//   if (existingVariations.length > 0) {
//     const updatePromises = existingVariations.map((item) =>
//       ApiProductService.editVariation({
//         token,
//         _id: item.id,
//         data: {
//           order: item.order,
//           title: [
//             { lang: "ua", title: item.title.ua },
//             { lang: "en", title: item.title.en },
//             { lang: "de", title: item.title.de },
//           ],
//           type: item.type,
//           values: item.values.map((value) => ({
//             bgUrl: value.bgUrl || "",
//             color: value.color || "",
//             order: value.order || 1,
//             title: [
//               { lang: "ua", title: value?.title?.ua || "" },
//               { lang: "en", title: value?.title?.en || "" },
//               { lang: "de", title: value?.title?.de || "" },
//             ],
//           })),
//         },
//       })
//     );
//     await Promise.all(updatePromises);
//   }
//   const createdData =
//     newVariations?.map((el) => ({
//       order: el.order,
//       title: [
//         { lang: "ua", title: el.title.ua },
//         { lang: "en", title: el.title.en },
//         { lang: "de", title: el.title.de },
//       ],
//       type: el.type,
//       values: el.values.map((value) => ({
//         bgUrl: value.bgUrl || "",
//         color: value.color || "",
//         order: value.order || 1,
//         title: [
//           { lang: "ua", title: value?.title?.ua || "" },
//           { lang: "en", title: value?.title?.en || "" },
//           { lang: "de", title: value?.title?.de || "" },
//         ],
//       })),
//     })) || [];
//   const variationsBulk =
//     createdData?.length &&
//     (await ApiProductService.createVariationsBulk({
//       token,
//       data: createdData,
//     }));

//   const createdVariations = variationsBulk
//     ? variationsBulk?.data?.map((item) => ({
//         id: item?.variation?._id,
//         order: item?.variation?.order,
//         type: item?.variation?.type,
//         title: {
//           en:
//             item?.variation?.title?.find((el) => el.lang === "en")?.title || "",
//           ua:
//             item?.variation?.title?.find((el) => el.lang === "ua")?.title || "",
//           de:
//             item?.variation?.title?.find((el) => el.lang === "de")?.title || "",
//         },
//         values: item?.values?.map((value) => ({
//           id: value._id,
//           bgUrl: value.bgUrl,
//           color: value.color,
//           order: value.order,
//           title: {
//             en: value?.title?.find((el) => el.lang === "en")?.title || "",
//             ua: value?.title?.find((el) => el.lang === "ua")?.title || "",
//             de: value?.title?.find((el) => el.lang === "de")?.title || "",
//           },
//         })),
//       }))
//     : [];

//   const updateData = [...existingVariations, ...createdVariations].sort((a) =>
//     a.type === "text" ? 1 : -1
//   );

//   const updatedProducts = updateProductsVariations(
//     watch("variationProducts") || [],
//     variations || [],
//     updateData
//   );
//   setValue("variationProducts", updatedProducts);

//   setValue("variations", updateData);

//   const updated = updateData.map((item) => ({
//     variation: item.id,
//     values: item.values.map((el) => el.id),
//   }));

//   if (currentGroup) {
//     await ApiProductService.editGroup({
//       token,
//       _id: currentGroup,
//       data: { variations: updated },
//     });
//   } else {
//     const group = await ApiProductService.createNewGroup({
//       token,
//       data: { variations: updated },
//     });
//     setValue("group", group.data._id);
//   }
// };
export const onSaveVariation = async ({ token, setValue, watch }: Tprops) => {
  const currentGroup = watch("group");
  const variations = watch("variations")?.sort((a) =>
    a.type === "text" ? 1 : -1
  );

  if (currentGroup) {
    // ApiProductService.deleteGroup({ token, _id: currentGroup });

    const promises = variations
      ?.filter((item) => item.id.length > 5)
      .map((item) =>
        ApiProductService.removeVariation({ token, _id: item.id })
      );

    promises?.length && (await Promise.all(promises));
  }

  const createdData =
    variations?.map((el) => ({
      order: el.order,
      title: [
        { lang: "ua", title: el.title.ua },
        { lang: "en", title: el.title.en },
        { lang: "de", title: el.title.de },
      ],
      type: el.type,
      values: el.values.map((value) => ({
        bgUrl: value.bgUrl || "",
        color: value.color || "",
        order: value.order || 1,
        title: [
          { lang: "ua", title: value?.title?.ua || "" },
          { lang: "en", title: value?.title?.en || "" },
          { lang: "de", title: value?.title?.de || "" },
        ],
      })),
    })) || [];

  const variationsBulk = await ApiProductService.createVariationsBulk({
    token,
    data: createdData,
  });
  const updateData = variationsBulk?.data
    ?.map((item) => ({
      id: item?.variation?._id,
      order: item?.variation?.order,
      type: item?.variation?.type,
      title: {
        en: item?.variation?.title?.find((el) => el.lang === "en")?.title || "",
        ua: item?.variation?.title?.find((el) => el.lang === "ua")?.title || "",
        de: item?.variation?.title?.find((el) => el.lang === "de")?.title || "",
      },
      values: item?.values?.map((value) => ({
        id: value._id,
        bgUrl: value.bgUrl,
        color: value.color,
        order: value.order,
        title: {
          en: value?.title?.find((el) => el.lang === "en")?.title || "",
          ua: value?.title?.find((el) => el.lang === "ua")?.title || "",
          de: value?.title?.find((el) => el.lang === "de")?.title || "",
        },
      })),
    }))
    ?.sort((a) => (a.type === "text" ? 1 : -1));
  const updatedProducts = updateProductsVariations(
    watch("variationProducts") || [],
    variations || [],
    updateData
  );
  setValue("variationProducts", updatedProducts);

  setValue("variations", updateData);
  const updated = updateData.map((item) => ({
    variation: item.id,
    values: item.values.map((el) => el.id),
  }));

  if (currentGroup) {
    await ApiProductService.editGroup({
      token,
      _id: currentGroup,
      data: { variations: updated },
    });
  } else {
    const group = await ApiProductService.createNewGroup({
      token,
      data: { variations: updated },
    });
    setValue("group", group.data._id);
  }
};

export const defaultVariAtion = (order: number, type: "text" | "color") => ({
  id: nanoid(5),
  order: order + 1,
  title: {
    ua: "",
    en: "",
    de: "",
  },
  type,
  values: [
    {
      id: nanoid(5),
      order: 6,
      title: {
        ua: "",
        en: "",
        de: "",
      },
      color: "",
      bgUrl: "",
    },
  ],
});
