import styled from "styled-components";
import { colors } from "../../../../common";

export const MainContainer = styled.main`
  /* max-width: 1609px;
  height: 925px; */
  min-height: 60px;
  margin-top: 26px;

  overflow-y: auto;

  background: white;
`;

export const CharacteristicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 660px;
  padding-bottom: 25px;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.strokeInput};
  }
`;
export const DeleteButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  right: 10px;
  top: -20px;
  color: ${colors.red};
  z-index: 2;
  svg {
    width: 26px;
    height: 26px;
  }
`;
