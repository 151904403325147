import { SyntheticEvent, useMemo, useState } from "react";
import {
  TDataTable,
  TOption,
  TSort,
  generateErrorToast,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import { api } from "../../../../common/config/api";
import { TBrandValidationSchema, TForm, TFormTypes } from "./types";
import {
  brandActions,
  getMainBrandSelector,
  showToastAction,
} from "../../../../store";
import { DROP_DOWN_LIST_LIMIT } from "./consts";
import { getLocale } from "../../../../types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Image } from "../../styled";
import { useTranslation } from "react-i18next";

export const useBrandLogic = () => {
  const [page, setPage] = useState<number>(0);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brands, response } = useTypedSelector(getMainBrandSelector);

  const local = getLocale();

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [formType, setFormType] = useState<TFormTypes>("create");

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [form, setForm] = useState<TForm>({
    name: "",
    photo: null,
    _id: "",
    description: "",
    order: 1,
  });

  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const data: TDataTable[] = useMemo(
    () =>
      brands.data.map((brand: any) => {
        return {
          photo: <Image src={`${api.withImageAPI}/brand/${brand.image}`} />,
          title: brand.title,
          description: <>{brand.description}</>,
        };
      }),
    [brands]
  );

  const Requests = {
    getBrands: () => {
      dispatch(
        brandActions.getBrands({
          limit: DROP_DOWN_LIST_LIMIT,
          page: page,
          lang: local,
          ...sortParamsForm,
        })
      );
    },
    createBrand: () => {
      const data = new FormData();

      data.append("title", form.name);
      data.append("description", form.description);
      data.append("img", form.photo as File);
      data.append("order", form.order as any);

      dispatch(brandActions.createBrand({ data }));
    },
    removeBrands: (_ids: string[]) => {
      dispatch(brandActions.removeBrand({ _ids }));
    },
    editBrand: () => {
      const data = new FormData();

      data.append("title", form.name);
      data.append("description", form.description);
      data.append("order", form.order as any);
      form.photo && data.append("img", form.photo as File);

      dispatch(brandActions.editBrand({ data, _id: form._id as string }));
    },
  };

  const schema = useMemo<TBrandValidationSchema>(
    () => ({
      name: {
        condition: !!form.name,
        error: t("enter.name"),
      },
      description: {
        condition: !!form.description,
        error: t("enter.description"),
      },
      photo: {
        condition: form.photo !== null,
        error: t("enter.photo"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    sortToggleHandler: (sortBy: string, order: TSort) => {
      setSortParamsForm({ sortBy, order });
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    onPhotoChange: (file: File | null) => {
      setForm({ ...form, photo: file as File });
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createBrand();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: any) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editBrand();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: any) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    addButtonClickListner: () => {
      setForm({
        name: "",
        photo: null,
        order: 1,
        description: "",
      });
      setFormType("create");
      setPopupVisiablity(true);
    },
    removeBrands: () => {
      Requests.removeBrands(
        selectedItems.map((index) => brands.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },
    paginateDropDownChangeHandler: (value: TOption) => {
      setForm((form) => ({ ...form, section: value }));
    },
    removeBrandsHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeBrands([brands.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
      await setFormType("edit");
      await setPopupVisiablity(true);
      await setForm({
        name: brands?.data[index]?.title,
        photo: `${api.withImageAPI}/brand/${brands.data[index].image}`,
        _id: brands.data[index]._id,
        order: brands?.data[index].order,
        description: brands?.data[index]?.description,
      });
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("photo"),
        accessor: "photo",
        width: 350,
      },
      {
        Header: t("label"),
        accessor: "title",
        width: 400,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("description"),
        accessor: "description",
        width: 400,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //   title: t("sections"),
      //   onClickHandler: () => {
      //     navigate("/catalog/sections");
      //   },
      // },
      {
        title: t("categories"),
        onClickHandler: () => {
          navigate("/catalog/categories");
        },
      },
      // {
      //   title: t("subcategories"),
      //   onClickHandler: () => {
      //     navigate("/catalog/subcategories");
      //   },
      // },
      {
        title: t("brands"),
        active: true,
      },
    ],
    []
  );
  return {
    tableSortBy,
    removeButtonState,
    response,
    page,
    setPage,
    Events,
    Requests,
    paginationSections,
    columns,
    data,
    popupVisiablity,
    setPopupVisiablity,
    form,
    formType,
    setForm,
    sortParamsForm,
    brands,
  };
};
