import styled from "styled-components";
import { colors } from "../../../../../../common";

export const AddSubCategory = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background-color: ${colors.green};
  color: ${colors.white};
  padding: 12px;
  border-radius: 12px;
`;
export const InputsWrap = styled.div<{ level: number }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: ${({ level }) => `${level * 10}px`};
`;
