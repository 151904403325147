import { useFormContext, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ButtonNew,
  DefaultInput,
  FlexContainer,
  Label,
} from "../../../../common";
import { FormValues } from "../../types";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { MainContainer, CharacteristicsWrapper, DeleteButton } from "./styled";

const ProductCharacteristics = ({
  productIndex,
}: {
  productIndex?: number;
}) => {
  const {
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();
  const basePath =
    productIndex !== undefined
      ? (`variationProducts.${productIndex}.formCharacteristics` as const)
      : ("product.formCharacteristics" as const);

  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: basePath,
  });
  const onAddClick = async () => {
    const isvalid = await trigger(basePath);
    if (isvalid) {
      append({
        ua: { key: "", value: "" },
        en: { key: "", value: "" },
        de: { key: "", value: "" },
      });
    }
  };

  return (
    <MainContainer>
      <FlexContainer
        gap="25px"
        direction="column"
        style={{ marginBottom: "20px" }}
      >
        <Label>{t("characteristics")}</Label>
        {fields?.map((characteristic, index) => {
          return (
            <CharacteristicsWrapper key={characteristic.id}>
              <DeleteButton
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  remove(index);
                }}
              >
                <MdOutlineDeleteOutline />
              </DeleteButton>
              {(["ua", "en", "de"] as const).map((item) => (
                <FlexContainer gap="20px" key={item}>
                  <DefaultInput
                    label={`${t("сharacteristic")} ${item}`}
                    placeholder={t(`enter.characteristic.${item}`)}
                    width={"320px"}
                    {...register(`${basePath}.${index}.${item}.key`)}
                    error={
                      productIndex !== undefined
                        ? errors?.variationProducts?.[productIndex]
                            ?.formCharacteristics?.[index]?.[item]?.key?.message
                        : errors?.product?.formCharacteristics?.[index]?.[item]
                            ?.key?.message
                    }
                  />
                  <DefaultInput
                    label={t("significance")}
                    placeholder={t(`enter.value.${item}`)}
                    width={"320px"}
                    {...register(`${basePath}.${index}.${item}.value`)}
                    error={
                      productIndex !== undefined
                        ? errors?.variationProducts?.[productIndex]
                            ?.formCharacteristics?.[index]?.[item]?.value
                            ?.message
                        : errors?.product?.formCharacteristics?.[index]?.[item]
                            ?.value?.message
                    }
                  />
                </FlexContainer>
              ))}
            </CharacteristicsWrapper>
          );
        })}
      </FlexContainer>
      <ButtonNew onClick={onAddClick} type="button" height={40} width={139}>
        {t("add")}
      </ButtonNew>
    </MainContainer>
  );
};

export default ProductCharacteristics;
