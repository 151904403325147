import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  BANNER_RESPONSE,
  getBannerSelector,
  showToastAction,
} from "../../store";

import {
  ButtonNew,
  useTypedSelector,
  Table,
  RelativePreloader,
  generateErrorToast,
} from "../../common";
import { getUserSelector } from "../../store/user";

import {
  ButtonContainer,
  Container,
  MainContainer,
  Header,
  Title,
} from "./styled";
import { useBannerLogic } from "./useBannerLogic";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { banners, response, loading } = useTypedSelector(getBannerSelector);
  const { permissions } = useTypedSelector(getUserSelector);

  const {
    isDuplicate,
    columns,
    form,
    Events,
    Requests,
    data,
    disabled,
    setForm,
  } = useBannerLogic();

  useEffect(() => {
    if (isDuplicate) {
      dispatch(showToastAction.request(generateErrorToast(t("not.less1"))));
    }
  }, [form]);

  useEffect(() => {
    setForm(
      banners.map((banner: any) => {
        const { start, end, _id, order } = banner;
        return { start, end, order: "" + order, _id, image: null };
      })
    );
  }, [banners]);

  useEffect(() => {
    Requests.getBanners();
  }, []);

  // Send request if get response from saveBanners request
  useEffect(() => {
    response === BANNER_RESPONSE.SAVED && Requests.getBanners();
  }, [response]);

  const banner = permissions.filter((el: string) => el === "banner").join();
  if (banner !== "banner") navigate("/404");
  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <Title>{t("banners")}</Title>
            <ButtonNew
              disabled={disabled}
              onClick={Events.onSaveClickHandler}
              theme={"green"}
            >
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            <Table
              columns={columns}
              data={data}
              removeClickHandler={Events.removeItemButtonClickHandler}
              removable
            />
            <ButtonContainer>
              <ButtonNew
                width={139}
                height={40}
                onClick={Events.addButtonClickHandler}
              >
                <span>{t("add")}</span>
              </ButtonNew>
            </ButtonContainer>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </>
  );
};

export default IndexPage;
