import { useTranslation } from "react-i18next";
import {
  ButtonNew,
  colors,
  FlexContainer,
  H2,
  Input,
  Popup,
  useTypedSelector,
} from "../../../../../../common";
import { TCategoryForm, TProps } from "./types";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { AddSubCategory, InputsWrap } from "./styled";
import { Subcategories } from "../Subcategories/Subcategories";
import { ApiCategoryService, getUserSelector } from "../../../../../../store";

import {
  buildCategoryTree,
  createCategoryRecursively,
  createId,
  syncCategoryTree,
  updateMainCategoryIfChanged,
} from "./helpers";
import useSWR from "swr";
import { TLang } from "../../../../../../types";
import { useEffect } from "react";

export const CreateCategoryPopup = ({
  category,
  closeModal,
  mutate,
  setSnackbar,
}: TProps) => {
  const { t, i18n } = useTranslation();
  const local = i18n.language as TLang;

  const { accessToken } = useTypedSelector(getUserSelector);

  const defaultCategory = {
    nameEN: "",
    nameUK: "",
    nameDE: "",
    id: createId(),
    parentId: null,
    subcategories: [],
  };

  const methods = useForm<TCategoryForm>({
    defaultValues: !category ? defaultCategory : {},
  });
  const token = `Bearer ${accessToken}`;

  const params = { id: category?._id, local, token };

  const { data: categoriesTree, mutate: treeMutate } = useSWR(
    params?.id ? ["getCategoriesTree", params] : null,
    ([, par]) => ApiCategoryService.getCategoriesWithChildren(par),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (category && categoriesTree) {
      const currentCategoryData = categoriesTree.data;

      methods.reset({
        nameEN:
          currentCategoryData.description.find((el) => el.lang === "en")
            ?.title || "",
        nameUK:
          currentCategoryData.description.find((el) => el.lang === "ua")
            ?.title || "",
        nameDE:
          currentCategoryData.description.find((el) => el.lang === "de")
            ?.title || "",
        id: category._id,
        parentId: null,
        subcategories: buildCategoryTree(currentCategoryData),
      });
    }
  }, [categoriesTree, category, methods]);

  const onSubmit = async (data: TCategoryForm) => {
    try {
      if (category && categoriesTree) {
        await updateMainCategoryIfChanged(categoriesTree.data, data, token);
        await syncCategoryTree(
          buildCategoryTree(categoriesTree?.data),
          data.subcategories,
          data.id,
          token
        );
      } else {
        await createCategoryRecursively(data, null, token);
      }
      closeModal();
      mutate();
      treeMutate();
      setSnackbar({
        open: true,
        message: category
          ? t("category.updated.successfully")
          : t("category.was.created.successfully"),
        severity: "success",
      });
    } catch (error) {
      console.error("Error creating categories:", error);

      setSnackbar({
        open: true,
        message: !category
          ? t("category.creating.error")
          : t("failed.to.update.category"),
        severity: "error",
      });
    }
  };

  return (
    <Popup backgroundClickListener={closeModal} width="770px" height="100%">
      <FormProvider {...methods}>
        <FlexContainer
          onSubmit={methods.handleSubmit(onSubmit)}
          as={"form"}
          justify="space-between"
          direction="column"
          style={{ height: "100%", overflow: "auto" }}
          wrap="nowrap"
        >
          <FlexContainer gap="20px" align="center" justify="center">
            {!category && (
              <H2 style={{ marginTop: "40px" }}>{t("create.category")}</H2>
            )}
            {category && (
              <H2 style={{ marginTop: "40px" }}>{t("edit.category")}</H2>
            )}
            <FlexContainer
              gap="15px"
              justify="center"
              direction="column"
              style={{
                padding: "20px",
                borderBottom: `1px solid ${colors.gray}`,
              }}
            >
              <InputsWrap level={0}>
                <Controller
                  rules={{ required: "required" }}
                  control={methods.control}
                  name="nameUK"
                  render={({
                    field: { value, onChange, name },
                    fieldState: { error },
                  }) => (
                    <Input
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={t("name")}
                      placeholder={t("enter.name.ua")}
                      error={error?.message}
                      width="100%"
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name="nameEN"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      width="100%"
                      name="nameEN"
                      value={value}
                      onChange={onChange}
                      label={t("name")}
                      placeholder={t("enter.name.en")}
                      error={methods.formState?.errors?.nameEN?.message}
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name="nameDE"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      width="100%"
                      name="nameDE"
                      value={value}
                      onChange={onChange}
                      label={t("name")}
                      placeholder={t("enter.name.de")}
                      error={methods.formState?.errors?.nameDE?.message}
                    />
                  )}
                />
                <AddSubCategory
                  onClick={() =>
                    methods.setValue("subcategories", [
                      ...methods.watch("subcategories"),
                      {
                        ...defaultCategory,
                        parentId: methods.watch("id"),
                        id: createId(),
                      },
                    ])
                  }
                  type="button"
                >
                  {t("addSubcategory")}
                </AddSubCategory>
              </InputsWrap>
              <Subcategories level={1} name="subcategories" />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer
            style={{ marginBottom: "40px", marginTop: "20px" }}
            gap="30px"
            justify="center"
          >
            <ButtonNew theme="gray" style="transparant" onClick={closeModal}>
              {t("cancel")}
            </ButtonNew>

            {!category && (
              <ButtonNew
                disabled={methods.formState.isSubmitting}
                type="submit"
                theme="green"
              >
                {t("create")}
              </ButtonNew>
            )}
            {category && (
              <ButtonNew
                disabled={methods.formState.isSubmitting}
                type="submit"
                theme="green"
              >
                {t("edit")}
              </ButtonNew>
            )}
          </FlexContainer>
        </FlexContainer>
      </FormProvider>
    </Popup>
  );
};
