import { SyntheticEvent, useMemo, useState } from "react";
import {
  TDataTable,
  TOption,
  TSort,
  generateErrorToast,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import { DROP_DOWN_LIST_LIMIT } from "./consts";
import {
  ApiCategoryService,
  categoryActions,
  getUserSelector,
  showToastAction,
} from "../../../../store";
import { TCategoryValidationSchema, TForm, TSortParamsForm } from "./types";
import { ApiSectionService } from "../../../../store/section/api.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PAGE_LIMIT } from "../../consts";
import { useTranslation } from "react-i18next";
import { TLang } from "../../../../types";
import {
  TCategories,
  TCategory,
  TSection,
} from "../../../../store/category/types";
import { api } from "../../../../common/config/api";
import useSWR from "swr";
import { Image } from "../../styled";

export const useCategoriesLogic = () => {
  const { t, i18n } = useTranslation();
  const local = i18n.language as TLang;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useTypedSelector(getUserSelector);
  const [category, setCategory] = useState<TCategory | null>(null);
  const [page, setPage] = useState<number>(0);

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [form, setForm] = useState<TForm>({
    nameUa: "",
    nameEn: "",
    nameDe: "",
    photo: null,
    _id: "",
    section: { value: "", label: "" } as TOption,
  });

  const [sortParamsForm, setSortParamsForm] = useState<TSortParamsForm>({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const getCategoriesParams = {
    limit: PAGE_LIMIT,
    page: page,
    lang: local,
    token: `Barear ${accessToken}`,
    ...sortParamsForm,
  };
  const { data: categoriesData, mutate } = useSWR(
    ["getCategories", getCategoriesParams],
    ([, par]) => ApiCategoryService.getCategoriesParent(par),
    { keepPreviousData: true, revalidateOnFocus: false }
  );
  const categories = categoriesData?.data as TCategories;

  const data: TDataTable[] = useMemo(
    () =>
      categories?.data?.map((category: any) => {
        return {
          photo: (
            <Image src={`${api.withImageAPI}/category/${category.image}`} />
          ),
          title: category?.title,
          productsTotalCount: <>{category?.productsTotalCount}</>,
          categoriesTotalCount: <>{category?.categoriesTotalCount}</>,
        };
      }),
    [categories]
  );

  const Requests = {
    getSections: async (page: number) => {
      return await ApiSectionService.getSections({
        token: accessToken as string,
        page,
        limit: DROP_DOWN_LIST_LIMIT,
      });
    },

    removeCategories: (_ids: string[]) => {
      dispatch(categoryActions.removeCategories({ _ids }));
      setTimeout(() => {
        mutate();
      }, 100);
    },
    editCategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameDe,
          lang: "de",
        })
      );
      form.photo && data.append("img", form.photo as File);
      form.section && data.append("section", form.section.value);

      dispatch(categoryActions.editCategory({ data, _id: form._id as string }));
      mutate();
    },

    getSection: async (_id: string) => {
      return await ApiSectionService.getSection({
        token: accessToken as string,
        _id,
        lang: local,
      });
    },
  };

  const schema = useMemo<TCategoryValidationSchema>(
    () => ({
      name: {
        condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.category.name.in.all.languages"),
      },
      section: {
        condition: !!form.section.value,
        error: t("select.section"),
      },
      photo: {
        condition: form.photo !== null,
        error: t("enter.photo"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    sortToggleHandler: (sortBy: string, order: TSort) => {
      setSortParamsForm({ sortBy, order });
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    onPhotoChange: (file: File | null) => {
      setForm({ ...form, photo: file as File });
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editCategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    addButtonClickListner: () => {
      setCategory(null);
      setForm({
        nameUa: "",
        nameDe: "",
        nameEn: "",
        photo: null,
        section: { value: "", label: "" } as TOption,
      });
      setPopupVisiablity(true);
    },
    removeCategories: () => {
      Requests.removeCategories(
        selectedItems.map((index) => categories.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },
    loadSectionOptions: async (
      search: string,
      loadOptions: any,
      additions: any
    ) => {
      const page = additions?.page || 0;

      const options = await Requests.getSections(page as number);

      return {
        options: (options.data.data as TSection[]).map((item) => ({
          value: item._id,
          label: item.title,
        })),
        hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
        additional: {
          page: page + 1,
        },
      };
    },
    paginateDropDownChangeHandler: (value: TOption) => {
      setForm((form) => ({ ...form, section: value }));
    },
    removeCategoryHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeCategories([categories.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
      // const response = await Requests.getSection(
      //   categories.data[index].section
      // );
      // dispatch(
      //   categoryActions.getDescriptionCategory({
      //     _id: categories.data[index]._id,
      //   })
      // );
      const value = categories.data[index];
      setCategory(value);
      setPopupVisiablity(true);
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: t("photo"),
      //   accessor: "photo",
      //   width: 200,
      // },
      {
        Header: t("label"),
        accessor: "title",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.product"),
        accessor: "productsTotalCount",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.subcategories"),
        accessor: "categoriesTotalCount",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //   title: t("sections"),
      //   onClickHandler: () => {
      //     navigate("/catalog/sections");
      //   },
      // },
      {
        title: t("categories"),
        active: true,
      },
      // {
      //   title: t("subcategories"),
      //   onClickHandler: () => {
      //     navigate("/catalog/subcategories");
      //   },
      // },
      {
        title: t("brands"),
        onClickHandler: () => {
          navigate("/catalog/brand");
        },
      },
    ],
    []
  );

  return {
    removeButtonState,
    popupVisiablity,
    page,
    Events,
    Requests,
    paginationSections,
    data,
    tableSortBy,
    columns,
    setForm,
    sortParamsForm,
    setPage,
    setPopupVisiablity,
    categories,
    mutate,
    currentCategory: category,
  };
};
