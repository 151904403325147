import { ApiCategoryService } from "../../../../store";

type Tnested = Record<
  string,
  {
    label: string;
    value: string;
  }[]
>;
type THandleSubcategory = {
  parentCategoryId: string;
  subcategoryId: string;
  nestedCategories: Tnested;
  setNestedCategories: React.Dispatch<React.SetStateAction<Tnested>>;
  local: string;
  accessToken: string;
};

export const handleSubCategoryChange = async ({
  parentCategoryId,
  subcategoryId,
  nestedCategories,
  setNestedCategories,
  local,
  accessToken,
}: THandleSubcategory) => {
  const categoryKeys = Object.keys(nestedCategories);
  const currentIndex = categoryKeys.indexOf(parentCategoryId);

  setNestedCategories((prev) => {
    const updatedCategories: typeof nestedCategories = {};

    categoryKeys.slice(0, currentIndex + 1).forEach((key) => {
      updatedCategories[key] = prev[key];
    });

    return updatedCategories;
  });

  try {
    const { data: nestedSubcategories } =
      await ApiCategoryService.getCategoriesfromParent({
        id: subcategoryId,
        lang: local,
        token: accessToken || "",
      });

    if (nestedSubcategories.length > 0) {
      setNestedCategories((prev) => {
        const updatedCategories = {
          ...prev,
          [subcategoryId]: nestedSubcategories.map((nestedSubcategory) => ({
            label: nestedSubcategory.title,
            value: nestedSubcategory._id,
          })),
        };

        return updatedCategories;
      });
    }
    return nestedSubcategories;
  } catch (error) {
    console.error("Error loading subcategories:", error);
  }
};

export const handleCategoryChange = async ({
  categoryId,
  setNestedCategories,
  accessToken,
  local,
}: {
  categoryId: string;
  setNestedCategories: React.Dispatch<React.SetStateAction<Tnested>>;
  accessToken: string;
  local: string;
}) => {
  setNestedCategories({});
  try {
    const { data: subcategories } =
      await ApiCategoryService.getCategoriesfromParent({
        id: categoryId,
        lang: local,
        token: `Bearer ${accessToken}`,
      });

    if (subcategories.length > 0) {
      const options = subcategories.map((subcategory: any) => ({
        label: subcategory.title,
        value: subcategory._id,
      }));

      setNestedCategories((prev) => ({
        ...prev,
        [categoryId]: options,
      }));
    } else {
      setNestedCategories((prev) => {
        const updated = { ...prev };
        delete updated[categoryId];
        return updated;
      });
    }
    return subcategories;
  } catch (error) {
    console.error("Error loading subcategories:", error);
  }
};
