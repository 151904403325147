import { AxiosResponse } from "axios";
import { USER_URL } from "./config";
import { HttpService } from "./http.service";
import {
  TLogoutRequestPayload,
  TRefreshTokenPayload,
  TRefreshTokenResponse,
  TUserActionsPayload,
  TUserPostResponse,
} from "./types";

export class ApiUserService extends HttpService {
  static async login(
    data: TUserActionsPayload
  ): Promise<AxiosResponse<TUserPostResponse>> {
    return await this.request<TUserPostResponse>({
      url: USER_URL.login,
      method: "POST",
      data,
    });
  }

  static refresh_token(
    data: TRefreshTokenPayload
  ): Promise<AxiosResponse<TRefreshTokenResponse>> {
    return this.request<TRefreshTokenResponse>({
      url: USER_URL.refresh_token,
      method: "POST",
      data,
    });
  }

  static logout({
    token,
  }: TLogoutRequestPayload): Promise<AxiosResponse<TRefreshTokenResponse>> {
    return this.request<TRefreshTokenResponse>({
      url: USER_URL.logout,
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
  }
}
