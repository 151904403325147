import { useFormContext, useWatch } from "react-hook-form";
import { FormValues } from "../../types";
import { HiddenInput, InputsWrap, Wrapper } from "./styled";
import { api } from "../../../../common/config/api";
import { Label } from "../../styled";
import { useTranslation } from "react-i18next";
import { UploadPhoto, CustomImage } from "../../../../common";
import { ChangeEvent, useRef } from "react";

type TPhotoItem = { type: "oldPhotos" | "photos"; name: string; src: string };

export const PhotosWrap = ({ productIndex }: { productIndex?: number }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();
  const basePath =
    productIndex !== undefined
      ? (`variationProducts.${productIndex}` as const)
      : ("product" as const);

  // const {   oldPhotos, oldPreview } = useWatch({
  //   control,
  //   name: `${basePath}`,
  // });
  const photos = watch(basePath)?.photos;
  const preview = watch(basePath)?.preview;
  const oldPhotos = watch(basePath)?.oldPhotos;
  const oldPreview = watch(basePath)?.oldPreview;

  const previewRef = useRef<HTMLInputElement | null>(null);
  const photosRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const generatePreviewSrc = () => {
    if (oldPreview?.length) {
      return api.withImageAPI + "/product/preview/" + oldPreview;
    } else if (preview?.length) {
      return URL.createObjectURL(preview[0]);
    } else return "";
  };
  const generatePhotos = () => {
    const photosArray: TPhotoItem[] = [];
    oldPhotos?.map((item) =>
      photosArray.push({
        src: api.withImageAPI + "/product/gallery/" + item,
        name: item,
        type: "oldPhotos",
      })
    );
    [...(photos || [])].map((item) =>
      photosArray.push({
        src: URL.createObjectURL(item),
        name: item.name,
        type: "photos",
      })
    );
    return photosArray;
  };

  const onClearPreview = () => {
    setValue(`${basePath}.preview`, undefined);
    setValue(`${basePath}.oldPreview`, undefined);
  };

  const onPhotoChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: "preview" | "photos"
  ) => {
    const { files } = e.target;
    if (files?.length) {
      if (type === "photos") {
        const existingFiles =
          watch(`${basePath}.photos`) || new DataTransfer().files;
        const dataTransfer = new DataTransfer();

        Array.from(existingFiles).forEach((file) =>
          dataTransfer.items.add(file)
        );
        Array.from(files).forEach((file) => dataTransfer.items.add(file));
        setValue(`${basePath}.photos`, dataTransfer.files);
      } else {
        setValue(`${basePath}.preview`, files);
      }
    }
  };

  const onDeletePhoto = (item: TPhotoItem) => {
    if (item.type === "oldPhotos") {
      setValue(
        `${basePath}.oldPhotos`,
        watch(`${basePath}.oldPhotos`)?.filter((el) => el !== item.name)
      );
    } else {
      const currentFiles = watch(`${basePath}.photos`);
      if (!currentFiles) return;

      const filesArray = Array.from(currentFiles);

      const updatedFilesArray = filesArray.filter(
        (el) => item.name !== el.name
      );

      const dataTransfer = new DataTransfer();
      updatedFilesArray.forEach((file) => dataTransfer.items.add(file));

      setValue(`${basePath}.photos`, dataTransfer.files);
    }
  };

  return (
    <Wrapper>
      <HiddenInput
        ref={previewRef}
        onChange={(e) => onPhotoChange(e, "preview")}
        type="file"
        accept="image/*"
      />
      <HiddenInput
        onChange={(e) => onPhotoChange(e, "photos")}
        ref={photosRef}
        type="file"
        accept="image/*"
        multiple
      />
      <div>
        <Label>{t("preview")}</Label>
        {generatePreviewSrc()?.length ? (
          <CustomImage
            withDeleteIcon
            src={generatePreviewSrc()}
            onDeleteClick={onClearPreview}
          />
        ) : (
          <UploadPhoto
            onClick={() => previewRef.current?.click()}
            error={
              productIndex !== undefined
                ? !!errors?.variationProducts?.[productIndex]?.preview?.message
                : !!errors?.product?.preview?.message
            }
          />
        )}
      </div>
      <div>
        {" "}
        <Label>{t("photo")}</Label>
        <InputsWrap>
          {generatePhotos()?.map((item, index) => (
            <div key={index}>
              <CustomImage
                withDeleteIcon
                src={item.src}
                onDeleteClick={() => onDeletePhoto(item)}
              />
            </div>
          ))}
          <UploadPhoto onClick={() => photosRef.current?.click()} />
        </InputsWrap>
      </div>
    </Wrapper>
  );
};
