import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DefaultInput, FlexContainer } from "../../../../common";
import { FormValues } from "../../types";

export const PriceWrap = ({ productIndex }: { productIndex?: number }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const basePath =
    productIndex !== undefined
      ? (`variationProducts.${productIndex}` as const)
      : ("product" as const);

  return (
    <FlexContainer gap="20px" wrap="nowrap">
      <DefaultInput
        width="100%"
        type={"number"}
        {...register(`${basePath}.price`, { valueAsNumber: true })}
        label={t("price")}
        placeholder={t("enter.price")}
        error={
          productIndex !== undefined
            ? errors?.variationProducts?.[productIndex]?.price?.message
            : errors?.product?.price?.message
        }
      />

      <DefaultInput
        width="100%"
        type={"number"}
        {...register(`${basePath}.discountPrice`, { valueAsNumber: true })}
        label={t("discount.price")}
        placeholder={t("enter.discount.price")}
        error={
          productIndex !== undefined
            ? errors?.variationProducts?.[productIndex]?.discountPrice?.message
            : errors?.product?.discountPrice?.message
        }
      />

      <DefaultInput
        width="100%"
        type={"number"}
        {...register(`${basePath}.amount`, { valueAsNumber: true })}
        label={t("amount")}
        placeholder={t("enter.amount")}
        error={
          productIndex !== undefined
            ? errors?.variationProducts?.[productIndex]?.amount?.message
            : errors?.product?.amount?.message
        }
      />
    </FlexContainer>
  );
};
