import { TFirebase, TLang } from '../../types'
import { ORDER_RESPONSE } from './consts'
import { TSort } from "../../common";

export type TInitialState = {
   orders: TOrders
   order: TOrder | null
   archive: TOrders
   loading: boolean
   response: any
   stats: any
   graf: any
   delivery:any
}

export type TOrders = {
   data: TOrder[]
   meta: TOrdersMeta | null
}

export type TOrder = {
   _id: any
   id: string
   receiver: {
      phone: string | number,
      name: string | number
      anotherReceiver: boolean
   }
   customer: TCustomer
   items: TItem[]
   orderStatus: TOrderStatus
   paymentStatus: TPaymentStatus
   paymentType: PaymentType
   delivery: TDelivery
   totalPrice: number
   createdAt: string
   updatedAt: string
   paymentCode: number
   call: true
   comment: string
}

export type TOrderStatus = 'new' | 'pending payment' | 'processing' | 'delivering' | 'completed' | 'canceled'

export type TPaymentStatus = 'paid' | 'processing' | 'failure' | 'unpaid'

export type TItem = {
   count: number
   price: number
   product: any
}

export type TOrdersMeta = {
   lang: TLang
   totalCount: number
   needReload: boolean
}

export type TDelivery = {
   cityRecipient: string
   form: 'pickup' | 'movers'
   address?: string
   lon?: number
   lat?: number
}

export type TGetOrdersPayload = {
   limit?: number
   page?: number
   lang?: TLang
   field?: string
   value?: string
   start?: string
   end?: string
   regex?: string
   sortBy?: string
   order?: TSort
   auth?: string
}

export type TGetDeliveryPayload = {
   query: string
}

export type TGetOrdersRequestPayload = {
   token: string
} & TGetOrdersPayload

export type TGetDeliveryRequestPayload = {
   token: string
} & TGetDeliveryPayload

export type TCustomer = {
   createdAt: string
   email: string
   phone: string
   email_verified: boolean
   firebase: TFirebase
   name: string
   secondName: string
   picture: string
   updatedAt: string
   _id: string
}

export type TSetOrderState = {
   loading?: boolean
   response?: TOrderResponse
}

export type TOrderResponse = ORDER_RESPONSE | null

export type TGetOrderPayload = {
   _id: string,
   lang?: string
}

export type TGetOrderRequestPayload = TGetOrderPayload & {
   token: string,
}

export type TSetOrderPayload = TOrder | null

export type TGetArchivePayload = TGetOrdersPayload

export type TGetArchiveRequestPayload = TGetArchivePayload & {
   token: string
}

export type TEditOrderPayload = {
   _id: string
   data: TEditOrderData
}

export type TEditOrderData = {
   items?: {
      count: number
      product: string
   }[]
   delivery: {
      form: DeliveryForm,
      address?: string,
      cityRecipient: string,
      lon?: number,
      lat?: number
   }
   paymentStatus?:any
   orderStatus?: TOrderStatus
   paymentType: any
}

export enum OrderStatus {
   new = 'new',
   'pending.payment' = 'pending payment',
   processing = 'processing',
   delivering = 'delivering',
   completed = 'completed',
   canceled = 'canceled'
}

export enum DeliveryForm {
   pickup = 'pickup',
   courier = 'courier',
   movers = 'delivery'
}

export enum PaymentStatus {
   unpaid = 'unpaid',
   processing = 'processing',
   failure = 'failure',
   paid = 'paid',
   expired = 'expired',
   refunded = 'refunded'
}

export enum PaymentType {
   cash = 'cash',
   onlineStripe = 'online-stripe',
   onlineLiqpay = 'online-liqpay'
}

export type TEditOrderRequestPayload = TEditOrderPayload & {
   token: string
}

export type TRemoveOrderRequestPayload = any

export type TGetStatisticRequestPayload = any

export type TGetStatisticPayload = any
