import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  AlignContainer,
  Assets,
  ButtonNew,
  Divider,
  FlexContainer,
  useTypedSelector,
  Input,
  RelativePreloader,
} from "../../common";

import { bonusActions, getBonusesSelector } from "../../store";
import { getUserSelector } from "../../store/user";

import {
  Container,
  Header,
  MainContainer,
  Title,
  StyledImage,
  Line,
  DeleteButton,
  Span,
  InputBonusBlock,
} from "./styled";
import { useBonusSystemLogic } from "./useBonusSystemLogic";

const BonusSystem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { Events, form, disabled, setForm, setDisabled } =
    useBonusSystemLogic();

  const bonuses = useTypedSelector(getBonusesSelector);
  const { permissions } = useTypedSelector(getUserSelector);

  useEffect(() => {
    form.map((el) => {
      if (
        el.discount >= 0 &&
        el.discount <= 100 &&
        el.discount.toString() !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
  }, [form]);

  useEffect(() => {
    dispatch(bonusActions.getBonusesRequest());
  }, []);

  useEffect(() => {
    if (bonuses.data) {
      setForm(bonuses.data);
    }
  }, [bonuses.data]);

  const clientGroup = permissions
    .filter((el: string) => el === "clientGroup")
    .join();
  if (clientGroup !== "clientGroup") navigate("/404");

  return (
    <>
      {!bonuses.loading ? (
        <Container>
          <Header>
            <Title>{t("bonus.system")}</Title>
            <ButtonNew
              disabled={disabled}
              onClick={Events.onSaveClickHandler}
              theme={"green"}
            >
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            <FlexContainer gap="30px" className="bonuses_form_container">
              {form.map((bonus: any, index: number) => {
                return (
                  <FlexContainer
                    key={index}
                    gap="30px"
                    className="bonus_form_container"
                    align={"center"}
                  >
                    <Input
                      width={"568px"}
                      name="name"
                      label={t("customer.group")}
                      placeholder={t("enter.client.group")}
                      value={bonus.name}
                      onChange={Events.inputHandler}
                    />
                    {bonus.discount >= 0 &&
                    bonus.discount <= 100 &&
                    bonus.discount !== "" ? (
                      <>
                        <Input
                          width={"568px"}
                          name="discount"
                          type="percentage"
                          label={t("discount")}
                          placeholder={t("enter.discount")}
                          value={bonus.discount.toString()}
                          onChange={Events.inputHandler}
                        />
                      </>
                    ) : (
                      <InputBonusBlock>
                        <Input
                          width={"568px"}
                          name="discount"
                          type="percentage"
                          label={t("discount")}
                          placeholder={t("enter.discount")}
                          value={bonus.discount.toString()}
                          onChange={Events.inputHandler}
                        />
                        <Span>{t("enter.discount.least.0.more.than.100")}</Span>
                      </InputBonusBlock>
                    )}
                    <AlignContainer>
                      <DeleteButton
                        style={{ marginTop: "15px" }}
                        onClick={Events.removeButtonClickHandler}
                      >
                        <StyledImage src={Assets.DELETE_ICON} />
                      </DeleteButton>
                    </AlignContainer>
                    <Line />
                  </FlexContainer>
                );
              })}
            </FlexContainer>
            <Divider height={30} />
            <ButtonNew
              width={139}
              height={40}
              onClick={Events.onAddClickHandler}
            >
              <span>{t("add")}</span>
            </ButtonNew>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={bonuses.loading} />
      )}
    </>
  );
};

export default BonusSystem;
