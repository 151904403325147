import React, { useState } from "react";
import {
  ContainerPicker,
  PopupContainer,
  Wrapper,
  ColorPickerText,
} from "../../../../common/components/ColorPicker/styled";
import { EColors } from "../../../../common";
import { ErrorP } from "./styled";

const ColorPickerProduct = ({
  changeColor,
  selectColor,
  selectedColors,
  error,
}: {
  changeColor: (color: string) => void;
  selectColor: string;
  selectedColors: string[];
  error?: string;
}) => {
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handleClick = (event: any, color: string) => {
    changeColor(color);
    setPopupIsOpen(false);
  };

  return (
    <Wrapper>
      <ContainerPicker
        onClick={() => setPopupIsOpen(true)}
        style={{ background: selectColor }}
      >
        {selectColor === "" && (
          <ColorPickerText>{"Натисніть, щоб вибрати колір"}</ColorPickerText>
        )}
      </ContainerPicker>
      {popupIsOpen && (
        <PopupContainer>
          {Object.values(EColors)
            .filter((el) => !selectedColors.includes(el))
            ?.map((color, idx) =>
              selectColor === color ? (
                <></>
              ) : (
                <ContainerPicker
                  key={idx + color}
                  onClick={(event) => handleClick(event, color)}
                  style={{ background: color }}
                />
              )
            )}
        </PopupContainer>
      )}
      {error && <ErrorP>{error}</ErrorP>}
    </Wrapper>
  );
};

export default ColorPickerProduct;
