import { AxiosResponse } from "axios";
import { HttpService } from "../http.service";
import { TResponse } from "../types";
import { PRODUCT_URL } from "./config";
import {
  TCareateVariationData,
  TCreateGroupData,
  TCreateGroupRequestPayload,
  TCreateGroupRes,
  TCreatePhotoProductRequestPayload,
  TCreateProductRequestPayload,
  TCreateVariationBulkRequestPayload,
  TCreateVariationValueRequestPayload,
  TEditGroupRequestPayload,
  TEditProductRequestPayload,
  TEditVariationRequestPayload,
  TEditVariationValueRequestPayload,
  TGetGroupRequestPayload,
  TGetProductRequestPayload,
  TGetProductsDescriptionRequestPayload,
  TGetProductsGroupRequestPayload,
  TGetProductsRequestPayload,
  TGetVariationValueRequestPayload,
  TRemoveProductRequestPayload,
  TRemoveVariationRequestPayload,
  TVariationRes,
} from "./types";

export class ApiProductService extends HttpService {
  static getProducts({
    token,
    limit = 10,
    page = 0,
    lang = "en",
    date_end = "",
    date_start = "",
    ...props
  }: TGetProductsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getProducts}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        limit,
        skip: page * limit,
        lang,
        date_start,
        date_end,
        ...props,
      },
    });
  }

  static getProductsGroup({
    token,
    field = "",
    value = "",
  }: TGetProductsGroupRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getProductsGroup}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        value,
        field,
      },
    });
  }

  static getProduct({
    token,
    _id,
    lang = "ua",
  }: TGetProductRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getProduct}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        lang,
      },
    });
  }

  static getVariationValue({
    token,
    _id,
  }: TGetVariationValueRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getVariationValue}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }

  static getGroup({
    token,
    _id,
  }: TGetGroupRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getGroup}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }

  static createProduct({
    data,
    token,
  }: TCreateProductRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: PRODUCT_URL.createProduct,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static createVariationBulk({
    data,
    token,
  }: TCreateVariationBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: PRODUCT_URL.createVariationBulk,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static async createVariationsBulk({
    data,
    token,
  }: {
    data: TCareateVariationData;
    token: string;
  }) {
    const { data: variationData } = await this.request<TVariationRes>({
      url: PRODUCT_URL.createVariationBulk,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
    return variationData;
  }

  static async createNewGroup({
    data,
    token,
  }: {
    token: string;
    data: TCreateGroupData;
  }) {
    const { data: groupData } = await this.request<TCreateGroupRes>({
      url: PRODUCT_URL.createGroup,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
    return groupData;
  }

  static getGroups({
    token,
    _id,
    limit = 1000,
  }: TGetGroupRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getGroups}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        limit,
      },
    });
  }

  static createGroup({
    data,
    token,
  }: TCreateGroupRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: PRODUCT_URL.createGroup,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static editProduct({
    token,
    _id,
    data,
  }: TEditProductRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.editProduct}/${_id}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static editVariation({
    token,
    _id,
    data,
  }: TEditVariationRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.editVariation}/${_id}`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static editVariationValue({
    token,
    _id,
    data,
  }: TEditVariationValueRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.editVariationValue}/${_id}`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static editGroup({
    token,
    _id,
    data,
  }: TEditGroupRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.editGroup}/${_id}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }
  static deleteGroup({
    token,
    _id,
  }: TGetProductsDescriptionRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.editGroup}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
  }
  static removeProduct({
    token,
    _id,
  }: TRemoveProductRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.removeProduct}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
  }

  static removeVariation({
    token,
    _id,
  }: TRemoveVariationRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.removeVariation}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
  }

  static createVariationValue({
    data,
    token,
  }: TCreateVariationValueRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: PRODUCT_URL.createVariationValue,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }
  static createPhotoProduct({
    data,
    token,
  }: TCreatePhotoProductRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: PRODUCT_URL.createPhotoProduct,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }
  static getDescription({
    token,
    _id,
  }: TGetProductsDescriptionRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${PRODUCT_URL.getDescription}/${_id}/descriptions`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }
}
