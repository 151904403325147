import styled from "styled-components";
import { colors } from "../../styles";

import { TStyledLabelProps, TStyledInputProps } from "./types";
// style for Input
export const StyledInput = styled.input<TStyledInputProps>`
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  outline: none;
  /* border: none; */
  padding: ${({ $padding }) => $padding};
  border: 2px solid
    ${({ $error }) => (!$error ? colors.strokeInput : colors.red)};
  height: ${({ height }) => height};
  width: ${({ width, $withPrefix }) =>
    $withPrefix ? `calc(${width} - 50px)` : width};
  font-size: 16px;
  background-color: ${({ fill }) => fill};
  transition: border-color 0.2s;
  color: ${colors.black};
  &[disabled] {
    border: 2px solid ${colors.gray};
    background-color: ${colors.gray};
  }
  &:focus {
    border: 2px solid ${({ $error }) => (!$error ? "#f39000" : colors.red)};
  }
`;
export const ShowPasswordButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  left: 90%;
  bottom: 7px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
// style for label
export const Label = styled.label<TStyledLabelProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: ${({ width }) => width};
  color: ${colors.black};
  span {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 12px;
  }
`;
export const ErrorMessage = styled.div<{ $bottomError: string }>`
  position: absolute;
  font-size: 12px;
  bottom: -43px;
  color: ${colors.red};
  height: 40px;
`;

export const Prefix = styled.div<{ height: string; $error: boolean }>`
  height: ${({ height }) => height};
  font-size: 16px;
  border-radius: 0 8px 8px 0;
  border: 1px solid
    ${({ $error }) => (!$error ? colors.strokeInput : colors.red)};
  border-left: none;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
