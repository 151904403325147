import Select, { MultiValue, SingleValue } from "react-select";
import { ErrorP, selectStyles, SelectWrap } from "./styled";
import { useTranslation } from "react-i18next";
import { TOption, TSelectProps } from "./types";

export const SelectInput = <IsMulti extends boolean = false>({
  width = "335px",
  height = "45px",
  onChange,
  value,
  options,
  error,
  label,
  disabled = false,
  placeholder,
  defaultValue,
  isClearable = false,
  menuPortalTarget,
  isMulti,
  inputValue,
  onInputChange,
}: TSelectProps<IsMulti>) => {
  const { t } = useTranslation();

  return (
    <>
      <SelectWrap width={width} height={height}>
        {label && <p>{label}</p>}
        <Select
          onInputChange={onInputChange}
          inputValue={inputValue}
          isMulti={isMulti}
          menuPortalTarget={menuPortalTarget}
          options={options}
          noOptionsMessage={() => t("noOptionsAvailable")}
          styles={selectStyles(height, width, !!error)}
          onChange={(newValue, actionMeta) => {
            if (onChange) {
              onChange(
                newValue as IsMulti extends true
                  ? MultiValue<TOption>
                  : SingleValue<TOption>,
                actionMeta
              );
            }
          }}
          value={value as MultiValue<TOption> | SingleValue<TOption>}
          classNamePrefix="select"
          className="react-select"
          placeholder={placeholder}
          isDisabled={disabled}
          defaultValue={defaultValue}
          isClearable={isClearable}
        />
        {error && <ErrorP className="error">{error}</ErrorP>}
      </SelectWrap>
    </>
  );
};
