import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../../common";
import { FormValues } from "../../../../types";
import { ItemsWrap, Label, Wrapper } from "./styled";

export const Variations = ({ mainIndex }: { mainIndex: number }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as "ua" | "en" | "de";
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const variations = watch("variations")?.sort((a) =>
    a.type === "text" ? 1 : -1
  );

  return (
    <Wrapper key={mainIndex}>
      <Label>{t("variations")}</Label>
      {variations?.map((item, i) => {
        const options =
          item.type === "text"
            ? item.values.map((el) => ({
                value: el.id,
                label: el?.title?.[lang] || "",
              }))
            : item.values?.map((el) => ({
                value: el.id,
                label: el?.color || "",
                color: el?.color,
              }));
        //   .filter(
        //   (el) =>
        //     el.value === currentValue || !choiseVariants?.includes(el.value)
        // );
        return (
          <ItemsWrap key={item.id}>
            <Controller
              control={control}
              name={`variationProducts.${mainIndex}.variations.${i}.value`}
              render={({ field: { value, onChange } }) => (
                <SelectInput
                  placeholder={t("enter.variation")}
                  error={
                    errors?.variationProducts?.[mainIndex]?.variations?.[i]
                      ?.value?.message
                  }
                  label={item.title?.[lang] || t("no.title")}
                  width="100%"
                  options={options}
                  value={options.find((el) => el.value === value)}
                  onChange={(v) => {
                    setValue(
                      `variationProducts.${mainIndex}.variations.${i}.variation`,
                      item.id
                    );
                    onChange(v?.value);
                  }}
                />
              )}
            />
          </ItemsWrap>
        );
      })}
    </Wrapper>
  );
};
