import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bonusActions, getBonusesSelector } from "../../store";
import { useTypedSelector } from "../../common";

export const useBonusSystemLogic = () => {
  const dispatch = useDispatch();

  const bonuses = useTypedSelector(getBonusesSelector);

  const [form, setForm] = useState(bonuses.data);
  const [disabled, setDisabled] = useState(false);

  const Events = {
    inputHandler: (e: SyntheticEvent, value: string | number) => {
      const input = e.target as HTMLInputElement;
      const bonusContainerIndex = Utils.getBonusIndex(e);

      setForm((form: any) =>
        form.map((bonus: any, index: any) => {
          if (index !== bonusContainerIndex) return bonus;
          return { ...bonus, [input.name]: value };
        })
      );
    },
    onAddClickHandler: () => {
      setForm(form.concat([{ name: "", discount: 0 }] as typeof form));
    },
    onSaveClickHandler: async () => {
      await dispatch(bonusActions.saveBonusesRequest(form));
    },
    removeButtonClickHandler: (e: SyntheticEvent) => {
      const bonusContainerIndex = Utils.getBonusIndex(e);

      const bonus = form[bonusContainerIndex];

      const id = bonus._id;

      id && dispatch(bonusActions.deleteBonusRequest({ id }));

      setForm(
        form.filter((bonus: any, index: number) => {
          return index !== bonusContainerIndex;
        })
      );
    },
  };

  useEffect(() => {
    form.map((el) => {
      if (
        el.discount >= 0 &&
        el.discount <= 100 &&
        el.discount.toString() !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
  }, [form]);

  const Utils = {
    getBonusIndex: (e: SyntheticEvent): number => {
      const target = e.target as HTMLElement;
      const bonusesContainer = target.closest(".bonuses_form_container");
      const bonusContainer = target.closest(".bonus_form_container");
      const bonusContainerIndex = Array.prototype.indexOf.call(
        bonusesContainer?.children,
        bonusContainer
      );

      return bonusContainerIndex;
    },
  };

  return { Events, form, setForm, disabled, setDisabled };
};
