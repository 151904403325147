import React, {SyntheticEvent, useMemo, useState} from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePlacesWidget } from 'react-google-autocomplete';

import {
	ButtonNew, generateErrorToast,
	Input, useTypedSelector, useValidation,
} from "../../../common";
import {createShopAction, getMainSettingSelector, getShopAction, showToastAction} from "../../../store";

import {
	FormContainer,
	InputBlock,
	InputRef,
	InputsBlock,
	ModalContainer,
	ModalTitle,
	StyledButtons,
	Label,
	InputPhoneBlock,
	Span
} from "./styled";
import { TCoordinates, TModalForm } from './types';

const ModalForm = ({ setModalActive, setFormData, formData }:TModalForm) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [value, setValue] = useState<string>('');
	const [coordinates, setCoordinates] = useState<TCoordinates>({
		lat: 0,
		lon: 0
	});

	const { logo } = useTypedSelector(getMainSettingSelector);

	const handleModal = () => {
		setModalActive(false)
	};

	const validPhone = formData.phone.replace(/[\s\-]/g, '');

	const schema = useMemo<any>(
		() => ({
			phone: {
				condition: !!formData.phone && validPhone.match(/^((\+?3)?8)?((0\(\d{2}\)?)|(\(0\d{2}\))|(0\d{2}))\d{7}$/),
				error: t('phone.must.be.valid.phone.number')
			},
			name:{
				condition: !!formData.name,
				error: t('enter.title')
			},
			address: {
				condition: !!value,
				error: t('enter.address')
			},
			worker: {
				condition: !!formData.workMode,
				error: t('enter.work.mode')
			}
		}),
		[formData, value]
	);

	const {
		validation,
		enableValidation,
		disableValidation,
		//eslint-disable-next-line
	} = useValidation(schema);

	const { ref }:any = usePlacesWidget({
		language: 'ua',
		options: {
			types: ['address'],
		},
		apiKey: "AIzaSyDFIMKjdPiUc47BWjndFq6LlifowMsfz34",
		onPlaceSelected: (place) => {
			setValue(place.formatted_address)
			setCoordinates({
				lat: place.geometry.location.lat(),
				lon: place.geometry.location.lng(),
			})
		},
	});

	const inputHandler = (e: SyntheticEvent) => {
		const input = (e.target as HTMLInputElement)
		setFormData((prev:any) => ({...prev, [input.name]: input.value}))
	}

	const createShopHandle = async () => {
		try {
			enableValidation();
			await validation();
			dispatch(createShopAction.request(
				{
					name: formData.name,
					phone: `+38${formData.phone}`,
					address: value,
					lon: coordinates.lon,
					lat: coordinates.lat,
					workMode: formData.workMode,
				}));
			setFormData({
				name: '',
				phone: '',
				workMode: ''
			})
			dispatch(getShopAction.request({}));
			handleModal();
			disableValidation()
		} catch (error:any) {
			error?.map((err:string) => {
				dispatch(showToastAction.request(generateErrorToast(err)));
			});
		}
	};

	return (
		<ModalContainer>
			<ModalTitle>{t('add.shop')}</ModalTitle>
			<FormContainer>
				<InputsBlock>
					<InputBlock>
						<Input
							width={'568px'}
							label={t("title")}
							name="name"
							value={formData.name}
							placeholder={t('enter.title')}
							onChange={text => inputHandler(text)}
						/>
					</InputBlock>
					<InputBlock>
						<InputPhoneBlock>
							<Span>
									+38
							</Span>
							<Input
								width={'568px'}
								label={t('phone')}
								name="phone"
								value={formData.phone}
								placeholder={t('enter.phone')}
								onChange={text => inputHandler(text)}
							/>
						</InputPhoneBlock>
					</InputBlock>
				</InputsBlock>
				<InputsBlock>
					<InputBlock>
						<Label>{t('address')}</Label>
						<InputRef ref={ref} color={logo?.data?.mainColor} placeholder={t('enter.address')}/>
					</InputBlock>
					<InputBlock>
						<Input
							width={'568px'}
							label={t('workMode')}
							name="workMode"
							value={formData.workMode}
							placeholder={t('enter.work.mode')}
							onChange={text => inputHandler(text)}
						/>
					</InputBlock>
				</InputsBlock>
			</FormContainer>
			<StyledButtons>
				<ButtonNew
					onClick={handleModal}
					theme={'red'}
				>{t('cancel')}</ButtonNew>
				<ButtonNew
					onClick={createShopHandle}
					theme={'green'}
				>{t('add')}</ButtonNew>
			</StyledButtons>
		</ModalContainer>
	);
};

export default ModalForm;