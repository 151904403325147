import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getMainOrderSelector, orderActions } from "../../store";
import { RESPONSE } from "../../store/order/consts";
import { getUserSelector } from "../../store/user";
import { getLocale } from "../../types";

import {
	FiltersWrapper,
	FlexContainer,
	HeaderWrapper,
	Pagination,
	TSort,
	useTypedSelector,
	Table,
	ButtonNew,
	SearchBar, DateInput
} from "../../common";

import {
	MainContainer,
	Container,
	ButtonBlock,
	FlexStyledContainer,
	Header,
	Title,
	TitleBlock,
	ChartContainer,
	DateBlock
} from "./styled";
import Chart from "./components/Chart/Chart";

const Statistics = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const local = getLocale();

	const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [page, setPage] = useState<number>(0);
	const [searchForm, setSearchForm] = useState({
		searchValue: '',
		startDateValue: '',
		endDateValue: ''
	});
	const [searchParams, setSearchParams] = useSearchParams({})
	const [sortParamsForm, setSortParamsForm] = useState({
		sortBy: '',
		order: '' as TSort
	});

	const [searchGraf, setSearchGraf] = useState({
		start: '',
		end: ''
	});

	const { orders, loading, response } = useTypedSelector(getMainOrderSelector);
	const { permissions } = useTypedSelector(getUserSelector);

	const tableSortBy = useMemo(() => {
		{
			if (!sortParamsForm.order) {
				return { id: null, desc: false }
			}
			return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
		}
	}, [sortParamsForm]);

	const Requests = {
		getOrders: () => {
			dispatch(
				orderActions.getOrders({
					limit: 6,
					page,
					lang: local,
					regex: searchForm.searchValue,
					...sortParamsForm
				})
			)
		},
		getStatistic: () => {
			dispatch(
				orderActions.getStatistic({})
			)
		},
		getGraf: () => {
			dispatch(
					orderActions.getGraf({
						start: searchGraf.start,
						end: searchGraf.end
					})
				)
			},
			removeOrders: (_ids: string[]) => {
				dispatch(orderActions.removeOrder({_ids}))
			}
		};

	const Events = {
		onPageChangeHandler: ({ selected }: { selected: number }) => {
			setPage(selected)
		},
		onChangeHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchForm({ ...searchForm, [input.name]: input.value })
			Requests.getOrders()
		},
		onChangeGrafHandler: (e: React.SyntheticEvent) => {
			const input = e.target as HTMLInputElement
			setSearchGraf({ ...searchGraf, [input.name]: input.value })
			Requests.getGraf()
		},
		onSubmitSearchHandler: (e: SyntheticEvent) => {
			e.preventDefault()
			Requests.getOrders()
		},
		editClickHandler: (e: SyntheticEvent, index: number) => {
			navigate(`../orders/profile/${orders.data[index]._id}`)
		},
		sortToggleHandler: (sortBy: string, order: any) => {
			setSortParamsForm({ sortBy, order })
		},
		checkboxClickHandler: (e: SyntheticEvent, hasCheckboxesActiveState: boolean, ckeckedItemsIndex: number[]) => {
			setRemoveButtonState(hasCheckboxesActiveState)
			setSelectedItems(ckeckedItemsIndex)
		},
		removeOrders: () => {
			Requests.removeOrders(selectedItems.map((index) => orders.data[index]._id))
			setRemoveButtonState(false)
			setSelectedItems([])
		}
	}

	const data = useMemo(
		() =>
			orders.data.map((order:any) => {
				const created_at = order.createdAt
				return {
					id: <>{order.id}</>,
					createdAt:moment(created_at).format('DD.MM.YYYY HH:mm'),
					customer: (
						<>
							<p>
								{order.customer?.name ?? '-'} {order.customer?.secondName}
							</p>
							<p>{order.customer?.email}</p>
						</>
					),
					phone:<p>{order.customer?.phone}</p>,
					paymentStatus: <>{t(order.paymentStatus.split(' ').join('.'))}</>,
					total: order?.itemsPrice?.toFixed(2)
				}
			}),
		[orders]
	)

	const columns = useMemo(
		() => [
			{
				Header: t('order.code'),
				accessor: 'id',
				width: 100,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('date'),
				accessor: 'createdAt',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('client'),
				accessor: 'customer',
				width: 300,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('phone'),
				accessor: 'phone',
				width: 200,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('payment'),
				accessor: 'paymentStatus',
				width: 300,
				sortToggleHandler: Events.sortToggleHandler
			},
			{
				Header: t('sum'),
				accessor: 'total',
				width: 300,
				sortToggleHandler: Events.sortToggleHandler
			}
		],
		[]
	)

	useEffect(() => {
		Requests.getOrders()
		setSearchParams({ ...searchParams, page: '' + page })
	}, [page, sortParamsForm, searchForm]);

	useEffect(() => {
		if(response === RESPONSE.REMOVED){
			Requests.getOrders();
		}
	}, [response]);

	useEffect(() => {
		Requests.getStatistic()
		Requests.getGraf()
	}, [])

	useEffect(() => {
		Requests.getGraf()
	}, [searchGraf])

	// Set default page uri
	useEffect(() => {
		setPage(!isNaN(parseInt(searchParams.get('page') as string)) ? parseInt(searchParams.get('page') as string) : 0)
	}, []);

	useEffect(() => {
		if (!loading && orders?.meta?.needReload) Requests.getOrders()
	});

	const statistics = permissions.filter((el:string) => el === 'statistic').join();
	if(statistics !== 'statistic') navigate('/404');
	return (
	 <Container>
			<Header>
				<TitleBlock>
					<Title>{t('statistics')}</Title>
				</TitleBlock>
				<FlexStyledContainer>
					{removeButtonState && (
						<ButtonBlock>
							<ButtonNew width={195} theme="red" onClick={Events.removeOrders}>
								{t('remove.all')}
							</ButtonNew>
						</ButtonBlock>
					)}
				</FlexStyledContainer>
				<DateBlock>
					<DateInput name="start" value={searchGraf.start} onChange={Events.onChangeGrafHandler} />
					<DateInput name="end" value={searchGraf.end} onChange={Events.onChangeGrafHandler} />
				</DateBlock>
			</Header>
		 <ChartContainer>
			 <Chart />
			 <Chart doughnut={true}/>
		 </ChartContainer>
			<MainContainer>
				<FlexContainer direction="column" gap="30px">
					<HeaderWrapper>
						<FiltersWrapper style={{marginLeft: '-30px'}}>
							<SearchBar
								name="searchValue"
								placeholder={t('search')}
								value={searchForm.searchValue}
								onChange={Events.onChangeHandler}
								onSubmit={Events.onSubmitSearchHandler}
							/>
						</FiltersWrapper>
					</HeaderWrapper>

					<Table
						columns={columns}
						data={data}
						checkboxClickHandler={Events.checkboxClickHandler}
						editClickHandler={Events.editClickHandler}
						sortBy={tableSortBy}
						editable
					/>
				</FlexContainer>

				<Pagination
					page={page}
					pageCount={orders.meta ? Math.ceil(orders.meta.totalCount / 7) : 1}
					onPageChange={Events.onPageChangeHandler}
				/>
			</MainContainer>
		</Container>
	);
}

export default Statistics;