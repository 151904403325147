import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  ButtonNew,
  colors,
  FlexContainer,
  H2,
  Input,
  Label,
  PaginateDropDownList,
  Pagination,
  Popup,
  SubPagesBar,
  Table,
  useTypedSelector,
} from "../../../../common";
import { PAGE_LIMIT } from "../../consts";
import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
} from "../../styled";

import {
  getMainSubcategorySelector,
  SUBCATEGORY_RESPONSE,
} from "../../../../store";

import { useSubcategoriesLogic } from "./useSubcategoriesLogic";

const IndexPage: FC = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({});

  const {
    paginationSections,
    removeButtonState,
    page,
    setPage,
    columns,
    tableSortBy,
    data,
    Events,
    form,
    setForm,
    popupVisiablity,
    setPopupVisiablity,
    sortParamsForm,
    formType,
    Requests,
  } = useSubcategoriesLogic();

  //  const dispatch = useDispatch()

  const { subcategories, response, description } = useTypedSelector(
    getMainSubcategorySelector
  );

  // Request to receive data
  useEffect(() => {
    Requests.getSubcategories();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, sortParamsForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (description) {
      setForm((form) => ({
        ...form,
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
      }));
    }
  }, [description]);

  useEffect(() => {
    if (response == SUBCATEGORY_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        nameUa: "",
        nameDe: "",
        nameEn: "",
        category: { value: "", label: "" },
      });
      Requests.getSubcategories();
    }

    if (response == SUBCATEGORY_RESPONSE.REMOVED) {
      Requests.getSubcategories();
    }

    if (response == SUBCATEGORY_RESPONSE.EDITED) {
      Requests.getSubcategories();
    }
  }, [response]);

  return (
    <Container>
      <Header>
        <Title>{t("subcategory")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeSubcategories}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.subCategory")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />
        <Table
          columns={columns}
          data={data}
          sortBy={tableSortBy}
          editable
          removeClickHandler={Events.removeCategoryHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
          removable
        />
        <Pagination
          page={page}
          pageCount={
            subcategories.meta
              ? Math.ceil(subcategories.meta.totalCount / PAGE_LIMIT)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <Popup backgroundClickListener={Events.closePopupClickHandler}>
            <FlexContainer
              justify="space-between"
              direction="column"
              style={{ height: "100%" }}
              wrap="nowrap"
            >
              <FlexContainer gap="20px" align="center" justify="center">
                {formType == "create" && (
                  <H2 style={{ marginTop: "40px" }}>
                    {t("create.subCategory")}
                  </H2>
                )}
                {formType == "edit" && (
                  <H2 style={{ marginTop: "40px" }}>{t("edit.subCategory")}</H2>
                )}

                <FlexContainer
                  gap="30px"
                  align="flex-start"
                  justify="center"
                  style={{
                    padding: "30px 0px",
                    borderBottom: `1px solid ${colors.gray}`,
                  }}
                >
                  <Input
                    name="nameUa"
                    label={t("name")}
                    placeholder={t("enter.name.ua")}
                    value={form.nameUa}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameEn"
                    label={t("name")}
                    placeholder={t("enter.name.en")}
                    value={form.nameEn}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameDe"
                    label={t("name")}
                    placeholder={t("enter.name.de")}
                    value={form.nameDe}
                    onChange={Events.inputChangeHandler}
                  />
                </FlexContainer>
                <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  gap="20px"
                >
                  <FlexContainer direction="column" width="auto">
                    <Label>{t("category")}</Label>
                    <PaginateDropDownList
                      loadOptions={Events.loadCategoriesOptions}
                      placeholder={t("select.category")}
                      onChange={Events.paginateDropDownChangeHandler}
                      value={form.category}
                    />
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                style={{ marginBottom: "40px" }}
                gap="30px"
                justify="center"
              >
                <ButtonNew
                  theme="gray"
                  style="transparant"
                  onClick={Events.closePopupClickHandler}
                >
                  {t("cancel")}
                </ButtonNew>

                {formType == "create" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.createButtonClickHandler}
                  >
                    {t("create")}
                  </ButtonNew>
                )}
                {formType == "edit" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.editButtonClickHandler}
                  >
                    {t("edit")}
                  </ButtonNew>
                )}
              </FlexContainer>
            </FlexContainer>
          </Popup>
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
