import React, { SyntheticEvent } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Divider, Table, useTypedSelector } from "../../../../common";
import { api } from "../../../../common/config/api";

import { Image, MainContainer, ColorBlock } from "./styled";
import {
  ApiProductService,
  getUserSelector,
  productActions,
} from "../../../../store";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../../types";
import useSWR from "swr";
import { TProduct } from "../../../../store/product/types";

const VariationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const local = getLocale();
  const { accessToken } = useTypedSelector(getUserSelector);
  const token = `Bearer ${accessToken}`;

  const params = { lang: local, token, _id: id || "" };
  const { data: productData } = useSWR(
    params._id ? ["getProduct", params] : null,
    ([, par]) => ApiProductService.getProduct(par),
    { revalidateOnFocus: false, revalidateIfStale: false }
  );

  const newProduct = productData?.data.data;
  const getGroupParams = {
    field: "group._id",
    value: newProduct?.group?._id,
    token,
  };
  const { data: productGroupData, mutate } = useSWR(
    getGroupParams.value ? ["getProductsgroup", getGroupParams] : null,
    ([, par]) => ApiProductService.getProductsGroup(par),
    { revalidateOnFocus: false }
  );
  const productsGroup = (productGroupData?.data?.data as TProduct[]) || [];

  const Requests = {
    removeProduct: (_id: string[]) => {
      dispatch(productActions.removeProduct({ _id }));
    },
  };

  const Events = {
    removeProductHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeProduct([productsGroup?.[index]._id]);
      mutate();
    },
  };

  const columns = [
    {
      Header: t("id"),
      accessor: "id",
      width: 50,
    },
    {
      Header: t("photo"),
      accessor: "photo",
      width: 300,
    },
    {
      Header: t("name"),
      accessor: "name",
      width: 300,
    },
    {
      Header: t("date"),
      accessor: "date",
      width: 250,
    },
    {
      Header: t("total"),
      accessor: "total",
      width: 200,
    },
    {
      Header: t("color"),
      accessor: "color",
      width: 200,
    },
    {
      Header: t("text"),
      accessor: "text",
      width: 200,
    },
    {
      Header: t("price"),
      accessor: "price",
      width: 200,
    },
    {
      Header: t("status"),
      accessor: "status",
      width: 400,
    },
  ];

  const colorTypeProduct = productsGroup?.map(
    (el) => el?.variations?.filter((el) => el?.variation?.type === "color")?.[0]
  );
  const textTypeProduct = productsGroup?.map(
    (el) => el?.variations?.filter((el) => el?.variation?.type === "text")?.[0]
  );

  const data = productsGroup?.map((v, index) => {
    return {
      id: <>{v?.sku}</>,
      photo: (
        <Image
          style={{ width: "40px", height: "40" }}
          src={`${api.withImageAPI}/product/preview/${v?.preview}`}
        />
      ),
      name: <>{v?.description?.title}</>,
      date: <>{moment(v?.createdAt).format("YYYY-MM-DD HH:mm")}</>,
      total: <>{v?.amount}</>,
      color: (
        <ColorBlock
          style={{
            backgroundColor: `${colorTypeProduct[index]?.value?.bgUrl}`,
          }}
        />
      ),
      text: <>{textTypeProduct[index]?.value?.title?.[0]?.title ?? "-"}</>,
      price: <>{v?.price}</>,
      status: <>{t(v?.sellStatus)}</>,
    };
  });

  return (
    <MainContainer>
      <Table
        columns={columns}
        data={data}
        removable
        removeClickHandler={Events.removeProductHandler}
      />
      <Divider height={30} />
    </MainContainer>
  );
};

export default VariationList;
