import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PAGE_LIMIT } from "../../consts";
import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
} from "../../styled";
import {
  ButtonNew,
  colors,
  FlexContainer,
  H2,
  Input,
  Pagination,
  PhotoPicker,
  Popup,
  SubPagesBar,
  Table,
  useTypedSelector,
} from "../../../../common";
import { getMainSectionSelector, SECTION_RESPONSE } from "../../../../store";

import { useSectionLogic } from "./useSectionlogic";

const IndexPage: FC = () => {
  const { t } = useTranslation();

  const { sections, response, description } = useTypedSelector(
    getMainSectionSelector
  );

  const {
    page,
    setPage,
    searchParams,
    popupVisiablity,
    setPopupVisiablity,
    removeButtonState,
    setSearchParams,
    formType,
    form,
    setForm,
    data,
    columns,
    paginationSections,
    Requests,
    Events,
  } = useSectionLogic();

  // Request to receive data
  useEffect(() => {
    Requests.getSections();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (description) {
      setForm((form) => ({
        ...form,
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
      }));
    }
  }, [description]);

  useEffect(() => {
    if (response === SECTION_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        nameUa: "",
        nameDe: "",
        nameEn: "",
        photo: null,
      });
      Requests.getSections();
    }

    if (response === SECTION_RESPONSE.REMOVED) {
      Requests.getSections();
    }

    if (response === SECTION_RESPONSE.EDITED) {
      Requests.getSections();
    }
  }, [response]);

  return (
    <Container>
      <Header>
        <Title>{t("sections")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeSections}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.section")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />
        <Table
          columns={columns}
          data={data}
          editable
          removable
          removeClickHandler={Events.removeSectionHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
        />
        <Pagination
          page={page}
          pageCount={
            sections.meta ? Math.ceil(sections.meta.totalCount / PAGE_LIMIT) : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <Popup backgroundClickListener={Events.closePopupClickHandler}>
            <FlexContainer
              justify="space-between"
              direction="column"
              style={{ height: "100%" }}
              wrap="nowrap"
            >
              <FlexContainer gap="20px" align="center" justify="center">
                {formType == "create" && (
                  <H2 style={{ marginTop: "40px" }}>{t("create.section")}</H2>
                )}
                {formType == "edit" && (
                  <H2 style={{ marginTop: "40px" }}>{t("edit.section")}</H2>
                )}{" "}
                <FlexContainer
                  gap="30px"
                  align="flex-start"
                  justify="center"
                  style={{
                    padding: "30px 0px",
                    borderBottom: `1px solid ${colors.gray}`,
                  }}
                >
                  <Input
                    name="nameUa"
                    label={t("name")}
                    placeholder={t("enter.name.ua")}
                    value={form.nameUa}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameEn"
                    label={t("name")}
                    placeholder={t("enter.name.en")}
                    value={form.nameEn}
                    onChange={Events.inputChangeHandler}
                  />
                  <Input
                    name="nameDe"
                    label={t("name")}
                    placeholder={t("enter.name.de")}
                    value={form.nameDe}
                    onChange={Events.inputChangeHandler}
                  />
                  <FlexContainer justify="space-between" width="318px">
                    <PhotoPicker
                      width={150}
                      height={150}
                      label={t("photo")}
                      onChange={Events.onPhotoChange}
                      value={formType == "edit" ? (form.photo as string) : null}
                    />
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                style={{ marginBottom: "40px" }}
                gap="30px"
                justify="center"
              >
                <ButtonNew
                  theme="gray"
                  style="transparant"
                  onClick={Events.closePopupClickHandler}
                >
                  {t("cancel")}
                </ButtonNew>

                {formType == "create" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.createButtonClickHandler}
                  >
                    {t("create")}
                  </ButtonNew>
                )}
                {formType == "edit" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.editButtonClickHandler}
                  >
                    {t("edit")}
                  </ButtonNew>
                )}
              </FlexContainer>
            </FlexContainer>
          </Popup>
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
