import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { ButtonNew, FlexContainer } from "../../../../common";

import { FormContainer, MainContainer } from "../../styled";
import { FormValues } from "../../types";
import { NameWrap } from "../NameWrap/NameWrap";
import { PhotosWrap } from "../PhotosWrap/PhotosWrap";
import { PriceWrap } from "../PriceWrap/PriceWrap";
import { ProductCharacteristics } from "../ProductCharacteristics";
import { VisibleWrap } from "../VisibleWrap/VisibleWrap";
import { Variations } from "./components";
import { defaultprod } from "./consts";

import { ButtonWrap, DeleteButton, MessageWindow, ProductWrap } from "./styled";

const CreateVariation = () => {
  const { t } = useTranslation();
  const { watch, control } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "variationProducts",
  });
  const onAddClick = () => {
    append(defaultprod);
  };

  return watch("type") === "simple" ? (
    <MessageWindow>{t("select.product.type")}</MessageWindow>
  ) : (
    <>
      {fields.map((item, i) => (
        <ProductWrap key={item.id}>
          {i !== 0 && (
            <DeleteButton type="button" onClick={() => remove(i)}>
              <MdOutlineDeleteOutline />
            </DeleteButton>
          )}
          <MainContainer>
            <FormContainer>
              <FlexContainer direction="row" gap="20px" wrap="nowrap">
                <VisibleWrap productIndex={i} />
              </FlexContainer>
              <NameWrap productIndex={i} /> <PriceWrap productIndex={i} />
              <Variations mainIndex={i} />
              <ProductCharacteristics productIndex={i} />
            </FormContainer>

            <FlexContainer>
              <FlexContainer direction="column" align="center" gap="">
                <PhotosWrap productIndex={i} />
              </FlexContainer>{" "}
            </FlexContainer>
          </MainContainer>
        </ProductWrap>
      ))}
      <ButtonWrap>
        <ButtonNew onClick={onAddClick} type="button" theme="green">
          {t("add")}
        </ButtonNew>
      </ButtonWrap>
    </>
  );
};

export default CreateVariation;
