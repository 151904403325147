import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGE_LIMIT } from "./consts";

import {
  ButtonNew,
  DataTotal,
  DateInput,
  FiltersWrapper,
  FlexContainer,
  HeaderWrapper,
  PaginateDropDownList,
  Pagination,
  SearchBar,
  Table,
  useTypedSelector,
} from "../../common";

import { getMainProductSelector, getUserSelector } from "../../store";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
  TitleBlock,
} from "./styled";
import { useProductLogic } from "./useProductLogic";

const ProductsManagement: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { permissions } = useTypedSelector(getUserSelector);

  const { products } = useTypedSelector(getMainProductSelector);

  const {
    Events,
    page,
    filterForm,
    tableSortBy,
    searchForm,
    columns,
    data,
    removeButtonState,
  } = useProductLogic();

  const product = permissions.filter((el: string) => el === "product").join();

  if (product !== "product") navigate("/404");
  return (
    <Container>
      <Header>
        <TitleBlock>
          <Title>{t("manage.products")}</Title>
        </TitleBlock>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeProducts}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickHandler}>
              {t("add.product")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <FlexContainer direction="column" gap="30px">
          <HeaderWrapper>
            <FiltersWrapper>
              <SearchBar
                name="value"
                placeholder={t("search")}
                value={searchForm.value}
                onChange={Events.onChangeHandler}
                onSubmit={Events.onSubmitSearchHandler}
              />
              <DateInput
                name="start"
                value={searchForm.start}
                onChange={Events.onChangeHandler}
              />
              <DateInput
                name="end"
                value={searchForm.end}
                onChange={Events.onChangeHandler}
              />
              <PaginateDropDownList
                isClearable
                onInputChange={Events.searchInDropdown}
                inputValue={filterForm.search}
                loadOptions={Events.loadOptions}
                placeholder={t("filter.by.category")}
                onChange={Events.paginateDropDownChangeHandler}
                value={filterForm.category}
              />
            </FiltersWrapper>
            <DataTotal>
              {t("total.product")}: {products.meta?.totalCount}
            </DataTotal>
          </HeaderWrapper>

          <Table
            columns={columns}
            data={data}
            sortBy={tableSortBy}
            checkboxClickHandler={Events.checkboxClickHandler}
            editable
            editClickHandler={Events.editClickHandler}
          />
        </FlexContainer>

        <Pagination
          page={page}
          pageCount={
            products.meta ? Math.ceil(products.meta.totalCount / PAGE_LIMIT) : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />
      </MainContainer>
    </Container>
  );
};

export default ProductsManagement;
