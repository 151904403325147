import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducer";
import rootSaga from "./saga";
import { authMiddleware } from "./middlewares";
import { ConnectionActionsList } from "./connection";
import { userActionsList } from "./user";

const persistedReducer = persistReducer(
  {
    key: "dev.e-commerce",
    storage,
    whitelist: ["user"],
  },
  rootReducer
);

const authBlackList = [userActionsList, ConnectionActionsList];
const authWhiteList = [{}];

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  //   authMiddleware({ blackList: authBlackList, whiteList: authWhiteList }),
  sagaMiddleware,
];
const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
