import styled from "styled-components";
import { colors } from "../../styles";

export const UploadWrap = styled.div<{
  $round?: boolean;
  $error?: boolean;
  width: string;
  height: string;
}>`
  align-items: center;
  gap: 8px;
  cursor: pointer;
  display: flex;
  color: ${({ $error }) => (!$error ? colors.gray : colors.red)};
  justify-content: center;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 2px dashed ${({ $error }) => (!$error ? colors.gray : colors.red)};
  background-color: ${colors.greyBg};
  border-radius: ${({ $round }) => ($round ? "50%" : "8px")};
  p {
    font-weight: 600;
    font-size: 14px;
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;
