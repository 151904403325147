import { useTranslation } from "react-i18next";

import {
  Container,
  Delete,
  Image,
  Name,
  VariationBlock,
  VariationsContainer,
  VariationsHeader,
  VariationsMain,
  VariationWrapper,
} from "../../styled";

import { TProduct, VariationType } from "../../../../store/product/types";

import {
  Assets,
  ButtonNew,
  DefaultInput,
  Divider,
  FlexContainer,
  generateErrorToast,
  generateSuccessToast,
  SelectInput,
  useTypedSelector,
} from "../../../../common";
import { FormValues } from "../../types";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { VariationValue } from "../VariationValue";
import {
  ApiProductService,
  getUserSelector,
  showToastAction,
} from "../../../../store";
import { onSaveVariation, defaultVariAtion } from "./helpers";
import { useDispatch } from "react-redux";
import { defaultprod } from "../CreateVariation/consts";
import { useState } from "react";
import Dialog from "../../../../common/components/Dialog/Dialog";
import useSWR from "swr";
import { useParams } from "react-router-dom";

const VariationContainer = ({ changeTab }: { changeTab: () => void }) => {
  const { t, i18n } = useTranslation();
  const { accessToken } = useTypedSelector(getUserSelector);
  const token = `Bearer ${accessToken}`;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [indexElement, setIndexElement] = useState(0);

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const onDeleteClick = (i: number) => {
    setIndexElement(i);
    openDialog();
  };

  const {
    control,
    watch,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>();
  const { id } = useParams();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "variations",
  });

  const onAddGroup = () => {
    append(
      defaultVariAtion(
        fields.length,
        watch("variations")?.[0]?.type === "color" ? "text" : "color"
      )
    );
  };

  const variationTypeOptions = Object.keys(VariationType).map((prop) => ({
    label: t(prop),
    value: VariationType[prop as keyof typeof VariationType],
  }));

  const params = { lang: i18n.language, token, _id: id || "" };

  const { data: productData } = useSWR(
    params._id ? ["getProduct", params] : null,
    ([, par]) => ApiProductService.getProduct(par),
    { revalidateOnFocus: false, revalidateIfStale: false }
  );
  const productInfo = productData?.data.data as TProduct;

  const onSaveClick = async () => {
    const variations = getValues("variations");
    const isValid = await trigger("variations");

    if (variations?.length && isValid) {
      try {
        await onSaveVariation({
          token,
          setValue,
          watch,
          product: productInfo,
        });
        !watch("variationProducts")?.length &&
          setValue("variationProducts", [defaultprod]);

        dispatch(
          showToastAction.request(
            generateSuccessToast(t("group.was.created.successfully"))
          )
        );
        changeTab();
      } catch (error) {
        console.log(error);

        dispatch(showToastAction.request(generateErrorToast("Error")));
      }
    }
  };

  const onConfirm = () => {
    const variationID = watch(`variations`)?.[indexElement]?.id;
    const variationProducts = watch("variationProducts");

    const updatedVariationProducts = variationProducts?.map((product) => ({
      ...product,
      variations: product.variations?.filter(
        (variation) => variation.variation !== variationID
      ),
    }));

    setValue("variationProducts", updatedVariationProducts);

    remove(indexElement);
  };
  return (
    <Container className={"variations_form_container"}>
      <Dialog onConfirm={onConfirm} open={open} onClose={closeDialog} />

      {fields?.map(
        (variationTitle, index) =>
          variationTitle && (
            <VariationWrapper
              className={"variation_form_container"}
              key={index}
            >
              <VariationsContainer>
                <VariationBlock>
                  <VariationsHeader>
                    <Name>{t("create.variation")}</Name>
                    {fields?.length > 1 ? (
                      <Delete onClick={() => onDeleteClick(index)}>
                        <Image src={Assets.CROSS} />
                        {t("delete")}
                      </Delete>
                    ) : null}
                  </VariationsHeader>
                  <VariationsMain>
                    <FlexContainer
                      gap={"25px"}
                      style={{ padding: "10px 0" }}
                      align={"center"}
                    >
                      {(["ua", "en", "de"] as const).map((item) => (
                        <DefaultInput
                          key={item}
                          width="300px"
                          {...register(`variations.${index}.title.${item}`)}
                          label={`${t("title")} ${item}`}
                          placeholder={`${t("enter.title")} ${item}`}
                          error={
                            errors?.variations?.[index]?.title?.[item]?.message
                          }
                        />
                      ))}

                      <Controller
                        control={control}
                        name={`variations.${index}.type`}
                        render={({ field: { value, onChange } }) => (
                          <SelectInput
                            disabled={fields?.length === 1 ? false : true}
                            width="300px"
                            label={t("display.type")}
                            placeholder={t("enter.display.type")}
                            options={variationTypeOptions}
                            onChange={(v) => onChange(v?.value)}
                            value={variationTypeOptions.find(
                              (el) => el.value === value
                            )}
                          />
                        )}
                      />
                    </FlexContainer>
                  </VariationsMain>
                </VariationBlock>
                <Container className={"variationsValue_form_container"}>
                  <VariationValue index={index} />
                </Container>
              </VariationsContainer>
              <Divider height={30} />
            </VariationWrapper>
          )
      )}
      {watch("variations")?.length !== 2 ? (
        <>
          <ButtonNew onClick={onAddGroup} width={139} height={40} type="button">
            {t("add")}
          </ButtonNew>
          <Divider height={30} />
        </>
      ) : (
        ""
      )}
      {fields?.length ? (
        <ButtonNew
          disabled={!watch("variations")?.length}
          type="button"
          onClick={onSaveClick}
          theme={"green"}
          width={139}
          height={40}
        >
          {t("save")}
        </ButtonNew>
      ) : null}
    </Container>
  );
};

export default VariationContainer;
