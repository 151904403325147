import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ButtonNew,
  Pagination,
  SubPagesBar,
  Table,
  useTypedSelector,
} from "../../../../common";

import { PAGE_LIMIT } from "../../consts";

import {
  CATEGORY_RESPONSE,
  getMainCategorySelector,
  getUserSelector,
} from "../../../../store";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
} from "../../styled";

import { useCategoriesLogic } from "./useCategoriesLogic";
import { CreateCategoryPopup } from "./components";
import { Alert } from "@mui/material";
import { Snackbar } from "@material-ui/core";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { response, description } = useTypedSelector(getMainCategorySelector);
  const { permissions } = useTypedSelector(getUserSelector);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const {
    removeButtonState,
    categories,
    popupVisiablity,
    page,
    Events,
    paginationSections,
    data,
    tableSortBy,
    columns,
    setForm,
    sortParamsForm,
    setPage,
    setPopupVisiablity,
    mutate,
    currentCategory,
  } = useCategoriesLogic();

  // Request to receive data
  useEffect(() => {
    // Requests.getCategories();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, sortParamsForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (response == CATEGORY_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        nameUa: "",
        nameEn: "",
        nameDe: "",
        photo: null,
        section: { value: "", label: "" },
      });
    }
  }, [response]);

  useEffect(() => {
    if (description) {
      setForm((form) => ({
        ...form,
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
      }));
    }
  }, [description]);

  const category = permissions
    ?.filter((el: string) => el === "category")
    .join();
  if (category !== "category") navigate("/404");

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() =>
          setSnackbar({ message: "", open: false, severity: "success" })
        }
      >
        <Alert
          severity={snackbar.severity as "error" | "success" | "info"}
          onClose={() =>
            setSnackbar({ message: "", open: false, severity: "success" })
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Header>
        <Title>{t("categories")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeCategories}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.category")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />

        <Table
          columns={columns}
          data={data || []}
          sortBy={tableSortBy}
          removeClickHandler={Events.removeCategoryHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
          editable
          removable
        />

        <Pagination
          page={page}
          pageCount={
            categories?.meta
              ? Math.ceil(categories?.meta?.totalCount / PAGE_LIMIT)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <CreateCategoryPopup
            category={currentCategory || undefined}
            mutate={mutate}
            closeModal={Events.closePopupClickHandler}
            setSnackbar={setSnackbar}
          />
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
