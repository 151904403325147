import styled from 'styled-components';

import {
   TFlexContainer,
   TAlignContainer,
   TSpaceBeetwen,
   TProfileImage,
   TItemsPerRowGridContainer
} from './types';
import { colors, FLEX, FONT } from "../../styles";

export const FlexContainer = styled.div<TFlexContainer>`
   display: flex;
   flex-direction: ${({ direction }) => direction || 'row'};
   gap: ${({ gap }) => (gap ? gap : '0px')};
   width: ${({ width }) => width || '100%'};
   align-items: ${({ align }) => align};
   justify-content: ${({ justify: jusitify }) => jusitify};
   position: ${({ position }) => position};
   flex-wrap: ${({ wrap }) => wrap || 'wrap'};
   row-gap: ${({ rowGap }) => (rowGap ? rowGap : 'auto')};
   column-gap: ${({ columnGap }) => (columnGap ? columnGap : 'auto')};
`

export const HeaderWrapper = styled.div`
   ${FLEX({ justify: 'space-between' })}

   margin-top: 30px;
   margin-left: 30px;
   margin-right: 30px;
`;

export const AlignContainer = styled.div<TAlignContainer>`
   display: flex;
   align-items: ${({ align }) => align || 'center'};
   justify-content: ${({ justify: jusitify }) => jusitify || 'center'};
`;

export const Label = styled.label`
   margin-bottom: 8px;
   margin-left: 15px;
   line-height: 16px;
   ${FONT({ color: colors.black, size: '14px', weight: '500' })}
`;

export const SpaceBetweenContainer = styled.div<TSpaceBeetwen>`
   display: flex;
   justify-content: space-between;
   flex-direction: ${({ direction }) => direction || 'row'};
`

export const H2 = styled.h2`
   ${FONT({ size: '24px', weight: '700' })}
`;

export const FiltersWrapper = styled.div`
   ${FLEX({})}
   gap: 60px;
`;

export const DataTotal = styled.div`
   ${FONT({ size: '14px', weight: '500' })};

   @media (max-width: 1600px) {
      margin-top: 20px;
   }
`;

export const ProfileImage = styled.div<TProfileImage>`
   background-image: ${({ src }) => `url(${src})`};
   background-size: cover;
   background-position: center;
   height: ${({ size }) => size || '46px'};
   width: ${({ size }) => size || '46px'};
   border-radius: 100%;
   margin: 0 auto;
`;

export const ItemsPerRowGridContainer = styled.div<TItemsPerRowGridContainer>`
   display: grid;
   grid-template-columns: ${({ perRow }) => `repeat(${perRow},1fr)`};
   align-items: flex-start;
   width: ${({ width }) => width || 'auto'};
   height: auto;
   max-height: ${({ height }) => height || '0px'};
   padding: 30px;
   column-gap: ${({ columnGap }) => columnGap || '0px'};
   row-gap: ${({ rowGap }) => rowGap || '0px'};
`;

