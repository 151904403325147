import { FC, useEffect, useState } from "react";
import { Label } from "../Styled";

import { useTypedSelector } from "../../hooks";
import { getMainSettingSelector } from "../../../store";
import { Divider } from "../Divider";

import {
  Container,
  StyledInput,
  SwitchPasswordVisiableButton,
  Error,
} from "./styled";
import { TInputProps } from "./types";

const Input: FC<TInputProps> = ({
  label = "",
  value = "",
  width = "318px",
  height = "45px",
  placeholder,
  type = "text",
  disabled = false,
  onChange,
  name,
  isValid,
  error,
  readonly = false,
  align = "flex-start",
  defaultValue,
}) => {
  const [visiable, setVisiable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const { logo } = useTypedSelector(getMainSettingSelector);

  const Events = {
    switchVisiability: () => {
      setVisiable(!visiable);
    },
    onKeyPressFormatedField: (e: any) => {
      const key = e.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        setInputValue(inputValue.replace(/[^0-9]/g, "").slice(0, -1));
      }
    },
  };

  useEffect(() => {
    if (type === "percentage") {
      setInputValue(value.replace(/[^0-9]/g, ""));
      if (inputValue.substring(0, 1) === "0") {
        setInputValue(inputValue.substring(1));
      }
    }
  }, [value]);

  return (
    <Container align={align} width={width}>
      {(type === "text" || type === "number") && (
        <>
          {label && <Label>{label}</Label>}

          <StyledInput
            color={logo?.data?.mainColor}
            name={name}
            type={type}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e, e.target.value)}
            required
            readOnly={readonly}
            height={height}
          />
          <Divider height={5} />

          <Error>{!isValid ? error : ""}</Error>
        </>
      )}

      {type === "password" && (
        <>
          {label && <Label>{label}</Label>}
          <StyledInput
            color={logo?.data?.mainColor}
            name={name}
            type={visiable ? "text" : "password"}
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e, e.target.value)}
            height={height}
            required
          />

          <SwitchPasswordVisiableButton
            visiable={visiable}
            onClick={Events.switchVisiability}
          />
        </>
      )}

      {type === "percentage" && (
        <>
          {label && <Label>{label}</Label>}
          <StyledInput
            color={logo?.data?.mainColor}
            name={name}
            type="text"
            defaultValue={inputValue ? inputValue + "%" : "0%"}
            placeholder={placeholder}
            onChange={(e) =>
              onChange &&
              onChange(e, parseInt(e.target.value.replace(/[^0-9]/g, "")))
            }
            height={height}
            required
            onKeyDown={Events.onKeyPressFormatedField}
          />
        </>
      )}
    </Container>
  );
};

export default Input;
