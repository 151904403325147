import { UseFormReset } from "react-hook-form";
import { z } from "zod";
import { ApiCategoryService, ApiProductService } from "../../store";
import { TCategory } from "../../store/category/types";
import { TProduct } from "../../store/product/types";
import { getLocale, TLang } from "../../types";
import {
  FormValues,
  ProductSchema,
  TDescription,
  TProductInForm,
} from "./types";

type TproductForm = z.infer<typeof ProductSchema>;

const buildFormCharacteristics = (
  descriptionUa?: TDescription,
  descriptionEn?: TDescription,
  descriptionDe?: TDescription
): TproductForm["formCharacteristics"] => {
  const maxLength = Math.max(
    descriptionUa?.attributes.length || 0,
    descriptionEn?.attributes.length || 0,
    descriptionDe?.attributes.length || 0
  );

  const formCharacteristics = maxLength
    ? Array.from({ length: maxLength }, (_, index) => ({
        ua: descriptionUa?.attributes[index] || { key: "", value: "" },
        en: descriptionEn?.attributes[index] || { key: "", value: "" },
        de: descriptionDe?.attributes[index] || { key: "", value: "" },
      }))
    : [];

  return formCharacteristics;
};

export const setInitialCategoryAndSubcategories = async ({
  category,
  local,
  accessToken,
}: {
  category: TCategory;
  local: string;
  accessToken: string;
}) => {
  const subcategories: {
    parentId: string;
    value: { label: string; value: string };
  }[] = [];
  const nestedCategoriesStack: {
    key: string;
    options: { label: string; value: string }[];
  }[] = [];

  let currentCategory = category;

  while (currentCategory?.parent) {
    const categoryForIteration = currentCategory;

    try {
      const { data: parentCategoryData } = await ApiCategoryService.getCategory(
        {
          _id: categoryForIteration.parent || "",
          lang: local as TLang,
          token: accessToken || "",
        }
      );

      const parentCategory = parentCategoryData?.data as TCategory;

      if (!parentCategory) {
        console.error("Parent category not found.");
        break;
      }

      const { data: siblingCategories } =
        await ApiCategoryService.getCategoriesfromParent({
          id: parentCategory._id,
          lang: local,
          token: accessToken || "",
        });

      nestedCategoriesStack.push({
        key: parentCategory._id,
        options: siblingCategories.map((sibling) => ({
          label: sibling.title || "Untitled",
          value: sibling._id || "unknown",
        })),
      });

      subcategories.unshift({
        parentId: parentCategory._id,
        value: nestedCategoriesStack[
          nestedCategoriesStack.length - 1
        ]?.options.find((el) => el.value === categoryForIteration._id) || {
          label: categoryForIteration.title || "Untitled",
          value: categoryForIteration._id || "unknown",
        },
      });

      currentCategory = parentCategory;
    } catch (error) {
      console.error("Error processing category:", error);
      break;
    }
  }

  const nestedCategories: Record<string, { label: string; value: string }[]> =
    {};
  nestedCategoriesStack.reverse().forEach((item) => {
    nestedCategories[item.key] = item.options;
  });

  return {
    category: currentCategory?._id || category._id,
    subcategories,
    nestedCategories,
  };
};

export const transformProductToForm = (
  product: TProduct,
  descriptionUa: TDescription | undefined,
  descriptionEn: TDescription | undefined,
  descriptionDe: TDescription | undefined
): TproductForm => {
  return {
    amount: product.amount || 0,
    descriptionDe: descriptionDe?.description,
    descriptionEn: descriptionEn?.description,
    descriptionUa: descriptionUa?.description,
    discountPrice: product?.discountPrice,
    formCharacteristics: descriptionUa?.attributes?.length
      ? buildFormCharacteristics(descriptionUa, descriptionEn, descriptionDe)
      : [],
    nameUa: descriptionUa?.title || "",
    nameDe: descriptionDe?.title || "",
    nameEn: descriptionEn?.title || "",
    show: product?.show ? "true" : "false",
    sku: product?.sku ? +product.sku : 0,
    price: product.price || 0,
    oldPhotos: product?.gallery?.map((item) => item.image) || [],
    oldPreview: product?.preview,
    variations: product?.variations?.[0]?.variation?._id
      ? product?.variations
          ?.sort((a) => (a?.variation?.type === "text" ? 1 : -1))
          .map((variation) => ({
            variation: variation?.variation?._id,
            value: variation?.value._id,
          }))
      : undefined,
  };
};

export const setdefaultData = async (
  product: TProduct,
  reset: UseFormReset<FormValues>,
  token: string,
  descriptions: TDescription[],
  setNestedCategories: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          label: string;
          value: string;
        }[]
      >
    >
  >
) => {
  const local = getLocale();
  const descriptionUa = descriptions?.find((el) => el.lang === "ua");
  const descriptionEn = descriptions?.find((el) => el.lang === "en");
  const descriptionDe = descriptions?.find((el) => el.lang === "de");
  const productInForm = transformProductToForm(
    product,
    descriptionUa,
    descriptionEn,
    descriptionDe
  );
  const { category, subcategories, nestedCategories } =
    await setInitialCategoryAndSubcategories({
      accessToken: token,
      category: product.category,
      local,
    });
  setNestedCategories(nestedCategories);
  reset({
    category,
    subcategories,
    brand: product?.brand,
    group: product?.group?._id,
    type: product.type === "variated" ? "variated" : "simple",
    variations: product.group?.variations?.[0]?.variation?._id
      ? product.group?.variations?.map((item) => ({
          id: item?.variation?._id,
          type: item.variation?.type,
          order: item.variation?.order,
          title: item.variation?.title.reduce((acc, el) => {
            acc[el.lang] = el?.title || "";
            return acc;
          }, {} as Record<"en" | "de" | "ua", string>),
          values: item.values.map((value) => ({
            id: value?._id,
            order: value?.order,
            bgUrl: value?.bgUrl,
            color: value?.color,
            title: value?.title.reduce((acc, el) => {
              acc[el.lang] = el.title || "";
              return acc;
            }, {} as Record<"en" | "de" | "ua", string>),
          })),
        }))
      : [],
    variationProducts:
      product.type === "variated" ? [productInForm] : undefined,
    product: product.type === "variated" ? undefined : productInForm,
  });
};

const generateImage = async (
  token: string,
  photos?: FileList,
  preview?: FileList,
  oldPhotos?: string[],
  oldPreview?: string
) => {
  const formData = new FormData();

  if (preview?.length) {
    formData.append("preview", preview[0]);
  }

  if (photos?.length) {
    Array.from(photos).forEach((file) => {
      formData.append("gallery", file);
    });
  }

  let previewPhoto: string = oldPreview || "";
  let gallery = oldPhotos || [];

  if (formData.has("preview") || formData.has("gallery")) {
    const res = await ApiProductService.createPhotoProduct({
      token,
      data: formData,
    });

    previewPhoto = res.data.data.preview || oldPreview;
    gallery = res.data.data.gallery?.length
      ? [...(oldPhotos || []), ...res.data.data.gallery]
      : oldPhotos || [];
  }

  return {
    preview: previewPhoto,
    photos: gallery,
  };
};

const generateDescriptions = (product?: TproductForm) => {
  const languages = ["en", "ua", "de"] as const;
  const returnname = (lang: "en" | "ua" | "de") => {
    if (lang === "ua") {
      return "nameUa";
    } else if (lang === "de") {
      return "nameDe";
    } else if (lang === "en") {
      return "nameEn";
    } else return "nameUa";
  };
  const returnDesc = (lang: "en" | "ua" | "de") => {
    if (lang === "ua") {
      return "descriptionUa";
    } else if (lang === "de") {
      return "descriptionDe";
    } else if (lang === "en") {
      return "descriptionEn";
    } else return "descriptionUa";
  };
  return languages.map((lang) => ({
    lang,
    title: product?.[returnname(lang)] || "",
    description: product?.[returnDesc(lang)] || "",
    attributes: product?.formCharacteristics
      ? product?.formCharacteristics
          .map((el) => el[lang])
          .filter((attr) => attr && attr.key && attr.value)
      : [],
  }));
};

const createProductData = (
  lastSubcategory: string,
  preview: string,
  gallery: string[],
  type: string,
  brand: string,
  item?: TproductForm,
  group?: string
) => {
  return {
    sku: item?.sku?.toString() || "0",
    sellStatus: item?.amount ? "available" : "notavailable",
    price: item?.price || 0,
    show: item?.show === "true",
    category: lastSubcategory,
    type,
    preview,
    gallery: gallery.map((g, i) => ({ order: i, image: g })),
    amount: item?.amount || 0,
    brand,
    discountPrice: item?.discountPrice,
    description: generateDescriptions(item),
    group: type === "simple" ? undefined : group,
    variations:
      type !== "simple"
        ? item?.variations?.map((variation) => ({
            variation: variation.variation,
            value: variation.value,
          }))
        : [],

    dimensions: {
      weight: 0.1,
      width: 0.01,
      height: 0.01,
      length: 0.01,
    },
  };
};
export const createproductFunction = async (
  data: FormValues,
  token: string
) => {
  const lastSubcategory = data.subcategories?.length
    ? data.subcategories[data.subcategories.length - 1].value.value
    : data.category;

  const isSimple = data.type === "simple";

  if (isSimple) {
    const images = await generateImage(
      token,
      data.product?.photos,
      data.product?.preview,
      data.product?.oldPhotos,
      data.product?.oldPreview
    );
    const preview = images.preview;
    const gallery = images.photos;

    const productData = createProductData(
      lastSubcategory,
      preview,
      gallery,
      data.type,
      data.brand,
      data.product,
      data.group
    );

    await ApiProductService.createProduct({ token, data: productData });
  } else {
    await Promise.all(
      data.variationProducts?.map(async (item) => {
        const images = await generateImage(
          token,
          item?.photos,
          item?.preview,
          item?.oldPhotos,
          item?.oldPreview
        );

        const preview = images.preview;
        const gallery = images.photos;

        const productData = createProductData(
          lastSubcategory,
          preview,
          gallery,
          data.type,
          data.brand,
          item,
          data.group
        );

        await ApiProductService.createProduct({
          token,
          data: productData,
        });
      }) || []
    );
  }
};

export const editProduct = async (
  data: FormValues,
  token: string,
  id: string
) => {
  const lastSubcategory = data.subcategories?.length
    ? data.subcategories[data.subcategories.length - 1].value.value
    : data.category;
  if (data.type === "variated") {
    const currentProduct = data.variationProducts?.[0];

    const newProducts = data.variationProducts?.slice(1);

    if (newProducts?.length) {
      const promises = newProducts.map(async (item) => {
        if (item) {
          const images = await generateImage(
            token,
            item.photos,
            item.preview,
            item.oldPhotos,
            item.oldPreview
          );

          const preview = images.preview;
          const gallery = images.photos;

          const productData = createProductData(
            lastSubcategory,
            preview,
            gallery,
            data.type,
            data.brand,
            item,
            data.group
          );

          await ApiProductService.createProduct({
            token,
            data: productData,
          });
        }
      });

      await Promise.all(promises);
    }
    const images = await generateImage(
      token,
      currentProduct?.photos,
      currentProduct?.preview,
      currentProduct?.oldPhotos,
      currentProduct?.oldPreview
    );

    const preview = images.preview;
    const gallery = images.photos;

    const productData = createProductData(
      lastSubcategory,
      preview,
      gallery,
      data.type,
      data.brand,
      currentProduct,
      data.group
    );
    await ApiProductService.editProduct({ token, _id: id, data: productData });
  } else {
    const product = data.product;
    const images = await generateImage(
      token,
      product?.photos,
      product?.preview,
      product?.oldPhotos,
      product?.oldPreview
    );

    const preview = images.preview;
    const gallery = images.photos;

    const productData = createProductData(
      lastSubcategory,
      preview,
      gallery,
      data.type,
      data.brand,
      product,
      data.group
    );
    await ApiProductService.editProduct({ token, _id: id, data: productData });
  }
};

export const hasDuplicateVariations = (
  variationProducts: FormValues["variationProducts"]
): boolean => {
  const seen = new Set<string>();

  for (const product of variationProducts || []) {
    const variations = product.variations || [];

    const variationsJson = JSON.stringify(
      variations.map((v) => ({ variation: v.variation, value: v.value }))
    );

    if (seen.has(variationsJson)) {
      return true;
    }

    seen.add(variationsJson);
  }

  return false;
};
