import { TCategoryWithChild } from "./../../../../../../store/category/types";
import { ApiCategoryService } from "../../../../../../store";
import { TCategoryForm } from "./types";

export const createId = () => {
  let timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const updateMainCategoryIfChanged = async (
  currentCategory: TCategoryWithChild,
  updatedNode: TCategoryForm,
  accessToken: string
): Promise<void> => {
  const hasChanged =
    currentCategory.description.find((el) => el.lang === "en")?.title !==
      updatedNode.nameEN ||
    currentCategory.description.find((el) => el.lang === "ua")?.title !==
      updatedNode.nameUK ||
    currentCategory.description.find((el) => el.lang === "de")?.title !==
      updatedNode.nameDE;

  if (!hasChanged) {
    return;
  }

  try {
    await ApiCategoryService.editCategory({
      _id: updatedNode.id,
      token: accessToken,
      data: createCategoryFormData(updatedNode, null),
    });
  } catch (error) {
    console.error(`Failed to update category ${updatedNode.nameUK}:`, error);
  }
};

export const createCategoryFormData = (
  data: TCategoryForm,
  parentId: string | null
): FormData => {
  const formData = new FormData();

  formData.append(
    "description[]",
    JSON.stringify({
      title: data.nameEN,
      lang: "en",
    })
  );
  formData.append(
    "description[]",
    JSON.stringify({
      title: data.nameUK,
      lang: "ua",
    })
  );
  formData.append(
    "description[]",
    JSON.stringify({
      title: data.nameDE,
      lang: "de",
    })
  );
  formData.append("show", "true");

  if (parentId) {
    formData.append("parent", parentId);
  }

  return formData;
};

export const createCategoryRecursively = async (
  data: TCategoryForm,
  parentId: string | null,
  accessToken: string
) => {
  const response = await ApiCategoryService.createCategory({
    token: accessToken,
    data: createCategoryFormData(data, parentId),
  });

  const createdCategoryId = response.data?.data || null;

  if (data.subcategories && data.subcategories.length > 0) {
    await Promise.all(
      data.subcategories.map((subcategory) =>
        createCategoryRecursively(subcategory, createdCategoryId, accessToken)
      )
    );
  }
};

export function buildCategoryTree(
  rootCategoryData: TCategoryWithChild
): TCategoryForm[] {
  const categoryMap = new Map<string, TCategoryForm>();

  rootCategoryData.categories.forEach((category) => {
    categoryMap.set(category._id, {
      id: category._id,
      parentId: category.parent,
      nameEN: category.description.find((el) => el.lang === "en")?.title || "",
      nameUK: category.description.find((el) => el.lang === "ua")?.title || "",
      nameDE: category.description.find((el) => el.lang === "de")?.title || "",
      subcategories: [],
    });
  });

  const rootCategory: TCategoryForm = {
    id: rootCategoryData._id,
    parentId: null,
    nameEN:
      rootCategoryData.description.find((el) => el.lang === "en")?.title || "",
    nameUK:
      rootCategoryData.description.find((el) => el.lang === "ua")?.title || "",
    nameDE:
      rootCategoryData.description.find((el) => el.lang === "de")?.title || "",
    subcategories: [],
  };

  categoryMap.forEach((category) => {
    if (category.parentId === rootCategory.id) {
      rootCategory.subcategories.push(category);
    } else if (category.parentId) {
      const parentCategory = categoryMap.get(category.parentId);
      if (parentCategory) {
        parentCategory.subcategories.push(category);
      }
      // else {
      //   console.warn(
      //     `Батьківська категорія з ID ${category.parentId} не знайдена.`
      //   );
      // }
    }
  });

  return rootCategory.subcategories;
}

export const syncCategoryTree = async (
  currentTree: TCategoryForm[],
  updatedTree: TCategoryForm[],
  parentId: string | null,
  accessToken: string
): Promise<void> => {
  const updatedMap = new Map(updatedTree.map((node) => [node.id, node]));

  for (const currentNode of currentTree) {
    const updatedNode = updatedMap.get(currentNode.id);

    if (!updatedNode) {
      try {
        await ApiCategoryService.removeCategory({
          _id: currentNode.id,
          token: accessToken,
        });
        console.log(`Category ${currentNode.id} removed.`);
      } catch (error) {
        console.error(`Failed to remove category ${currentNode.id}:`, error);
      }
      continue;
    }

    if (
      currentNode.nameEN !== updatedNode.nameEN ||
      currentNode.nameUK !== updatedNode.nameUK ||
      currentNode.nameDE !== updatedNode.nameDE ||
      currentNode.parentId !== parentId
    ) {
      try {
        await ApiCategoryService.editCategory({
          _id: currentNode.id,
          token: accessToken,
          data: createCategoryFormData(updatedNode, parentId),
        });
        console.log(`Category ${currentNode.id} updated.`);
      } catch (error) {
        console.error(`Failed to edit category ${currentNode.id}:`, error);
      }
    }

    await syncCategoryTree(
      currentNode.subcategories,
      updatedNode.subcategories || [],
      currentNode.id,
      accessToken
    );

    updatedMap.delete(currentNode.id);
  }

  for (const [newId, newNode] of updatedMap.entries()) {
    try {
      const response = await ApiCategoryService.createCategory({
        token: accessToken,
        data: createCategoryFormData(newNode, parentId),
      });

      const createdCategoryId = response.data?.data || null;

      if (createdCategoryId) {
        console.log(
          `Category ${newNode.nameUK} created with parentId ${parentId}.`
        );

        await syncCategoryTree(
          [],
          newNode.subcategories || [],
          createdCategoryId,
          accessToken
        );
      }
    } catch (error) {
      console.error(`Failed to create category ${newId}:`, error);
    }
  }
};
