import { useTranslation } from "react-i18next";

import { FlexContainer, SelectInput } from "../../../../common";

import { FormContainer, MainContainer } from "../../styled";

import { Catalog } from "../Catalog";
import { VariationContainer } from "../VariationContainer";
import { Controller, useFormContext } from "react-hook-form";
import { FormValues } from "../../types";
import { PhotosWrap } from "../PhotosWrap/PhotosWrap";
import { NameWrap } from "../NameWrap/NameWrap";
import { PriceWrap } from "../PriceWrap/PriceWrap";
import { VisibleWrap } from "../VisibleWrap/VisibleWrap";
import { ProductCharacteristics } from "../ProductCharacteristics";
import { TProps } from "./types";

const BasicData = ({
  changeTab,
  nestedCategories,
  setNestedCategories,
}: TProps) => {
  const { t } = useTranslation();
  const productTypeOptions = [
    { label: t("simple"), value: "simple" },
    { label: t("variated"), value: "variated" },
  ];
  const { control, watch, setValue } = useFormContext<FormValues>();

  const isSimpleProduct = watch("type") === "simple";
  return (
    <MainContainer>
      <FormContainer>
        <FlexContainer gap="20px">
          <FlexContainer direction="row" gap="20px" wrap="nowrap">
            <Controller
              control={control}
              name="type"
              render={({ field: { value, onChange } }) => (
                <SelectInput
                  width="100%"
                  value={productTypeOptions.find((el) => el.value === value)}
                  options={productTypeOptions}
                  label={t("product.type")}
                  onChange={(v) => {
                    if (v) {
                      onChange(v.value);
                      if (v.value === "variated") {
                        setValue("product", undefined);
                      } else {
                        setValue("variationProducts", undefined);
                      }
                    }
                  }}
                />
              )}
            />

            {isSimpleProduct && <VisibleWrap />}
          </FlexContainer>
        </FlexContainer>
        {isSimpleProduct ? (
          <>
            <NameWrap /> <PriceWrap />{" "}
          </>
        ) : null}
        {!isSimpleProduct ? (
          <VariationContainer changeTab={changeTab} />
        ) : (
          <ProductCharacteristics />
        )}
      </FormContainer>

      <FlexContainer direction="column" align="center" gap="">
        <Catalog
          nestedCategories={nestedCategories}
          setNestedCategories={setNestedCategories}
        />
        {isSimpleProduct && <PhotosWrap />}
      </FlexContainer>
    </MainContainer>
  );
};

export default BasicData;
