import { SyntheticEvent, useMemo, useState } from "react";
import { TForm, TFormTypes, TSectionValidationSchema } from "./types";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  generateErrorToast,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import {
  getMainSectionSelector,
  sectionActions,
  showToastAction,
} from "../../../../store";
import { api } from "../../../../common/config/api";
import { Image } from "../../styled";
import { useTranslation } from "react-i18next";
import { PAGE_LIMIT } from "../../consts";
import { useDispatch } from "react-redux";
import { getLocale } from "../../../../types";

export const useSectionLogic = () => {
  const { sections, description } = useTypedSelector(getMainSectionSelector);
  const dispatch = useDispatch();
  const local = getLocale();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams({});

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [formType, setFormType] = useState<TFormTypes>("create");

  const [form, setForm] = useState<TForm>({
    nameUa: "",
    nameEn: "",
    nameDe: "",
    photo: null,
    _id: "",
  });

  const schema = useMemo<TSectionValidationSchema>(
    () => ({
      name: {
        condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.section.name.in.all.languages"),
      },
      photo: {
        condition: form.photo !== null,
        error: t("enter.photo"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const data = useMemo(
    () =>
      sections.data.map((section) => {
        return {
          photo: <Image src={`${api.withImageAPI}/section/${section.image}`} />,
          label: section.title,
          totalProducts: <>{section.productsTotalCount}</>,
          totalCategories: <>{section.categoriesTotalCount}</>,
          totalSubcategories: <>{section.subCategoriesTotalCount}</>,
        };
      }),
    [sections]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("photo"),
        accessor: "photo",
        width: 100,
      },
      {
        Header: t("label"),
        accessor: "label",
        width: 250,
      },
      {
        Header: t("total.product"),
        accessor: "totalProducts",
        width: 250,
      },
      {
        Header: t("total.categories"),
        accessor: "totalCategories",
        width: 250,
      },
      {
        Header: t("total.subcategories"),
        accessor: "totalSubcategories",
        width: 250,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //   title: t("sections"),
      //   active: true,
      // },
      {
        title: t("categories"),
        onClickHandler: () => {
          navigate("/catalog/categories");
        },
      },
      // {
      //   title: t("subcategories"),
      //   onClickHandler: () => {
      //     navigate("/catalog/subcategories");
      //   },
      // },
      {
        title: t("brands"),
        onClickHandler: () => {
          navigate("/catalog/brand");
        },
      },
    ],
    []
  );

  const Requests = {
    getSections: () => {
      dispatch(
        sectionActions.getSections({
          limit: PAGE_LIMIT,
          page: page,
          lang: local,
        })
      );
    },
    createSection: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameDe,
          lang: "de",
        })
      );
      data.append("img", form.photo as File);

      dispatch(sectionActions.createSection({ data }));
    },
    editSection: () => {
      const data = new FormData();
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameDe,
          lang: "de",
        })
      );
      form.photo instanceof File && data.append("img", form.photo);
      dispatch(sectionActions.editSection({ data, id: form._id as string }));
    },
    removeSections: (ids: string[]) => {
      dispatch(sectionActions.removeSections({ ids }));
    },
  };

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    addButtonClickListner: () => {
      setForm({
        nameUa: "",
        nameEn: "",
        nameDe: "",
        photo: null,
      });
      setFormType("create");
      setPopupVisiablity(true);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    onPhotoChange: (file: File | null) => {
      setForm({ ...form, photo: file as File });
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createSection();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editSection();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
      await dispatch(
        sectionActions.getDescriptionSection({
          _id: sections.data[index]._id,
        })
      );
      await setForm({
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
        photo: `${api.withImageAPI}/section/${sections.data[index].image}`,
        _id: sections.data[index]._id,
      });
      setFormType("edit");
      setPopupVisiablity(true);
    },
    removeSections: () => {
      Requests.removeSections(
        selectedItems.map((index) => sections.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },
    removeSectionHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeSections([sections.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };
  return {
    page,
    setPage,
    searchParams,
    popupVisiablity,
    setPopupVisiablity,
    removeButtonState,
    setSearchParams,
    selectedItems,
    setRemoveButtonState,
    setSelectedItems,
    formType,
    setFormType,
    form,
    setForm,
    data,
    columns,
    paginationSections,
    Events,
    Requests,
  };
};
