import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "./store";
import { userActions } from "./user";
import { USER_URL } from "./user/config";

// Function to update tokens
const refreshingToken = async (refreshToken: string) => {
  const { data } = await axios.post<{
    data: {
      refreshToken: string;
      accessToken: string;
    };
    meta: { message: string; statusCode: number };
  }>(USER_URL.refresh_token, { refreshToken });
  return data;
};

type QueueItem = {
  resolve: (token: string) => void;
  reject: (error: AxiosError) => void;
};

// Flag to track token update status
let isRefreshing: boolean = false;
// Queue of requests waiting for token update
let failedRequestsQueue: QueueItem[] = [];

// Function to process the request queue after the token is updated
const processQueue = (error: AxiosError | null, token: string | null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    }
  });
  failedRequestsQueue = [];
};

export class HttpService {
  static async request<T = any>(
    axiosConfig: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      return await axios.request(axiosConfig);
    } catch (e) {
      const error = e as AxiosError;
      const originalRequest = error.config as AxiosRequestConfig & {
        _retry?: boolean;
      };

      // Check for 401 error and re-request status
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = store.getState().user.refreshToken;

          try {
            // Get new tokens
            const data = await refreshingToken(refreshToken || "");
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${data.data.accessToken}`;
            const prevUserData = store.getState().user;
            store.dispatch(
              userActions.auth({
                ...prevUserData,
                accessToken: data.data.accessToken,
                refreshToken: data.data.refreshToken,
              })
            );
            // Update Authorization header for re-request
            if (!originalRequest.headers) {
              originalRequest.headers = {};
            }

            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${data.data.accessToken}`;

            // Handle successful token update and retry request
            processQueue(null, data.data.accessToken);
            return axios(originalRequest);
          } catch (refreshError) {
            const error = refreshError as AxiosError;
            processQueue(error, null);
            store.dispatch(userActions.logout());
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // Adding the request to the queue if a token update is already in progress
          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              resolve: (newToken: string) => {
                if (!originalRequest.headers) {
                  originalRequest.headers = {};
                }
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                resolve(axios(originalRequest));
              },
              reject,
            });
          });
        }
      }

      return Promise.reject(error);
    }
  }
}
