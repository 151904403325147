import React, {FC, SyntheticEvent, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";

import {
	Assets,
	Checkbox,
	ItemsPerRowGridContainer,
	PhotoPicker,
	useTypedSelector,
	Input, RelativePreloader
} from '../../common';
import {
	adminActions,
	ADMIN_RESPONSE,
	getMainAdminSelector,
} from "../../store";
import Button from "../../common/components/Button/Button";

import {
	Container,
	MainContainer,
	Header,
	Title,
	TitleBlock,
	FlexStyledContainer,
	DateBlock,
	DateText,
	Image,
	PermissionWrapper,
	PermissionTitle,
	PermissionContainer,
	PermissionBlock,
	Name
} from './styled';
import { api } from '../../common/config/api';
import { RESPONSE } from "../../store/admin/consts";
import {TData, TForm} from './types';

const EditRole: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();

	const dispatch = useDispatch()
	const { loading: adminLoading, newAdmin, response } = useTypedSelector(getMainAdminSelector);

	const [data, setData] = useState<TData[]>( [
		{title: 'order', checked: false},
		{title: 'product', checked: false},
		{title: 'client', checked: false},
		{title: 'category', checked: false},
		{title: 'support', checked: false},
		{title: 'clientGroup', checked: false},
		{title: 'banner', checked: false},
		{title: 'delivery', checked: false},
		{title: 'feedback', checked: false},
		{title: 'shop', checked: false},
		{title: 'statistic', checked: false},
		{title: 'admin', checked: false},
		{title: 'config', checked: false},
	]);

	const [role, setRole] = useState<string[]>([]);

	const addRoleArrayHandle = (el:any) => {
		let newData = data.map((element: any) => {
			if (el === element) {
				return {...element, checked: !element.checked};
			} else {
				return element;
			}
		});
		setData(newData);
		if(role.includes(el.title)) {
			setRole((prevState) =>
				prevState.filter((element: string) => el.title !== element));
		} else {
			setRole((prevState) => [...prevState, el.title]);
		}
	};

	const [form, setForm] = useState<TForm>({
		name: '',
		surname: '',
		login: '',
		password: '',
		profile_photo: null
	});

	const Events = {
		backButtonClickHandler: () => {
			setData([]);
			navigate('/role')
		},
		inputChangeHandler: (e: SyntheticEvent) => {
			const { name, value } = e.target as HTMLInputElement
			setForm((props) => ({ ...props, [name]: value }))
		},
		onProfilePhotoChange: (file: File | null) => {
			setForm({ ...form, profile_photo: file })
		},
		removeButtonClickHandler: () => {
			Requests.removeAdmin();
			navigate('/role')
		},
		saveClickHandler: () => {
			Requests.editAdmin();
			navigate('/role')
		}
	};

	const Requests = {
		getAdmin: () => {
			dispatch(adminActions.getAdmin({ _id: id as string }))
		},
		editAdmin: () => {
			const data = new FormData()
			form.name && data.append('name', form.name)
			form.profile_photo && data.append('img', form.profile_photo as File)
			form.surname && data.append('secondName', form.surname)
			form.login && data.append('login', form.login)
			form.password && data.append('password', form.password)
			role && role.map((el:string) => data.append('permissions[]',el))
			dispatch(adminActions.editAdmin({ data, _id: id as string }))
		},
		removeAdmin: () => {
			dispatch(adminActions.removeAdmin({ _id: id as string }))
		}
	};

	useEffect(() => {
		Requests.getAdmin();
	}, []);

	// Redirect if item doesnt exist
	useEffect(() => {
		!adminLoading && response === ADMIN_RESPONSE.NOT_FOUNDED && navigate('/role')
	}, [adminLoading]);

	useEffect(() => {
		if(response === RESPONSE.EDITED){
			dispatch(adminActions.getAdmin({ _id: id as string }))
		}
	}, [])

	useEffect(() => {
		if(newAdmin && data) {
			setData(data.map((el) =>
				newAdmin?.permissions?.includes(el.title) ? {title: el.title, checked: true} : {title: el.title, checked: false}));
		}
	}, [newAdmin])

	useEffect(() => {
		if (newAdmin) {
			response !== ADMIN_RESPONSE.EDITED &&
			setForm({
				...form,
				name: newAdmin.name,
				surname: newAdmin.secondName,
				login: newAdmin.login,
			})
			setRole(newAdmin?.permissions);
			if (response == ADMIN_RESPONSE.EDITED) {
				Requests.getAdmin();
			}
			if(response === ADMIN_RESPONSE.REMOVED){
				navigate('/role')
			}
		}
	}, [response, newAdmin]);

	return (
		<>
			{
				!adminLoading ? <Container>
					<Header>
						<TitleBlock>
							<Image onClick={Events.backButtonClickHandler} src={Assets.ARROW_LEFT}/>
							<Title>{t('edit')}</Title>
							<DateBlock>
								<DateText>{t('createdAt.date')}{moment(newAdmin?.createdAt).format('YYYY-MM-DD HH:mm')}</DateText>
								<DateText>{t('updatedAt.date')}{moment(newAdmin?.updatedAt).format('YYYY-MM-DD HH:mm')}</DateText>
							</DateBlock>
						</TitleBlock>
						<FlexStyledContainer>
							<Button theme="red" height={46} onClick={Events.removeButtonClickHandler}>
								{t('delete')}
							</Button>
							<Button theme="green" height={46} onClick={Events.saveClickHandler}>
								{t('save')}
							</Button>
						</FlexStyledContainer>
					</Header>
					<MainContainer>
						<ItemsPerRowGridContainer perRow={2} width="700px" columnGap="62px" rowGap="30px">
							<Input
								name="name"
								label={t('name')}
								placeholder={t('enter.name')}
								onChange={Events.inputChangeHandler}
								value={form.name}
							/>
							<Input
								name="surname"
								label={t('surname')}
								placeholder={t('enter.surname')}
								onChange={Events.inputChangeHandler}
								value={form.surname}
							/>
							<Input
								name="login"
								label={t('login')}
								placeholder={t('enter.login.limit')}
								onChange={Events.inputChangeHandler}
								value={form.login}
							/>
							<Input
								name="password"
								label={t('password')}
								placeholder={t('enter.password.limit')}
								onChange={Events.inputChangeHandler}
								value={form.password}
							/>
							<PhotoPicker
								width={150} height={150}
								label={t('profile.photo')}
								onChange={Events.onProfilePhotoChange}
								value={`${api.withImageAPI}/admin/${newAdmin?.image}`}
							/>
						</ItemsPerRowGridContainer>
						<PermissionWrapper>
							<PermissionTitle>{t('access')}</PermissionTitle>
							<PermissionContainer>
								{
									data.map((el, idx) => (
										<PermissionBlock onClick={() => addRoleArrayHandle(el.title)} key={idx}>
											<Checkbox checked={el.checked} onChange={() => addRoleArrayHandle(el)}/>
											<Name>{t(el.title)}</Name>
										</PermissionBlock>
									))
								}
							</PermissionContainer>
						</PermissionWrapper>
					</MainContainer>
				</Container> : <RelativePreloader />
			}
		</>
	)
}

export default EditRole
