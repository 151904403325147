import React from "react";
import { useFormContext, Controller, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../../common";
import { TCategoryForm } from "../CreateCategoryPopup/types";
import {
  AddSubCategory,
  ButtonsWrap,
  DeleteButton,
  InputsWrap,
} from "./styled";
import { createId } from "../CreateCategoryPopup/helpers";

type TProp = {
  name: Path<TCategoryForm>;
  level: number;
};

export const Subcategories: React.FC<TProp> = ({ name, level }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<TCategoryForm>();

  const subcategories = (watch(name) as TCategoryForm[]) || [];

  const handleAddChild = (index: number) => {
    const childPath = `${name}[${index}].subcategories` as Path<TCategoryForm>;

    const childSubcategories = (watch(childPath) as TCategoryForm[]) || [];

    const newChildSubcategory: TCategoryForm = {
      id: createId(),
      parentId: subcategories[index]?.id || null,
      nameEN: "",
      nameUK: "",
      nameDE: "",
      subcategories: [],
    };

    setValue(childPath, [...childSubcategories, newChildSubcategory], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleRemove = (index: number) => {
    const updatedSubcategories = subcategories.filter((_, i) => i !== index);
    setValue(name, updatedSubcategories, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <div>
      {subcategories.map((subcategory, index: number) => {
        const childPath =
          `${name}[${index}].subcategories` as Path<TCategoryForm>;

        return (
          <InputsWrap key={subcategory.id} level={level}>
            <Controller
              name={`${name}[${index}].nameUK` as Path<TCategoryForm>}
              render={(props) => (
                <Input
                  width="100%"
                  name={props.field.name}
                  value={props.field.value}
                  onChange={props.field.onChange}
                  label={t("name")}
                  placeholder={t("enter.name.ua")}
                  error={props.fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={`${name}[${index}].nameEN` as Path<TCategoryForm>}
              render={(props) => (
                <Input
                  width="100%"
                  name={props.field.name}
                  value={props.field.value}
                  onChange={props.field.onChange}
                  label={t("name")}
                  placeholder={t("enter.name.en")}
                  error={props.fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={`${name}[${index}].nameDE` as Path<TCategoryForm>}
              render={(props) => (
                <Input
                  width="100%"
                  name={props.field.name}
                  value={props.field.value}
                  onChange={props.field.onChange}
                  label={t("name")}
                  placeholder={t("enter.name.de")}
                  error={props.fieldState.error?.message}
                />
              )}
            />

            <ButtonsWrap>
              <AddSubCategory
                onClick={() => handleAddChild(index)}
                type="button"
              >
                {t("addSubcategory")}
              </AddSubCategory>
              <DeleteButton onClick={() => handleRemove(index)} type="button">
                {t("delete")}
              </DeleteButton>
            </ButtonsWrap>

            <Subcategories name={childPath} level={level + 1} />
          </InputsWrap>
        );
      })}
    </div>
  );
};
