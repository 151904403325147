import { z } from "zod";
import { t } from "i18next";

const productTypeOptions = ["simple", "variated"] as const;

const fileListSchema = z.preprocess(
  (val) => (val instanceof FileList ? val : undefined),
  z.union([z.instanceof(FileList), z.undefined()])
);

// type VariationTitle = {
//   title: string;
//   lang: string;
// };

// type VariationValue = {
//   order: number;
//   bgUrl: string;
//   color: string;
//   title: VariationTitle[];
//   id: string;
// };

// type VariationData = {
//   order: number;
//   title: VariationTitle[];
//   type: "text" | "color";
//   id: string;
//   values: VariationValue[];
// };
const VariationTitleSchema = z.object({
  en: z.string().nonempty({ message: t("requiredField") }),
  ua: z.string().nonempty({ message: t("requiredField") }),
  de: z.string().nonempty({ message: t("requiredField") }),
});

const VariationValueSchema = z.object({
  id: z.string().nonempty({ message: "ID cannot be empty" }),
  order: z.number().min(1, { message: "Order must be at least 1" }),
  bgUrl: z.string({ message: t("requiredField") }).optional(),
  color: z.string({ message: t("requiredField") }).optional(),
  title: z
    .object({
      en: z.string({ message: t("requiredField") }),
      ua: z.string({ message: t("requiredField") }),
      de: z.string({ message: t("requiredField") }),
    })
    .optional(),
});

export const VariationDataSchema = z
  .object({
    id: z.string().nonempty({ message: "ID cannot be empty" }),
    order: z.number().min(1, { message: "Order must be at least 1" }),
    title: VariationTitleSchema,
    type: z.enum(["text", "color"], {
      errorMap: () => ({ message: "Type must be either 'text' or 'color'" }),
    }),
    values: z.array(VariationValueSchema),
  })
  .superRefine((data, ctx) => {
    if (data.type === "color") {
      data.values.forEach((value, index) => {
        if (!value.color) {
          ctx.addIssue({
            code: "custom",
            path: ["values", index, "color"],
            message: t("requiredField"),
          });
        }
      });
    } else if (data.type === "text") {
      data.values.forEach((value, index) => {
        if (!value.title) {
          ctx.addIssue({
            code: "custom",
            path: ["values", index, "title"],
            message: t("requiredField"),
          });
        } else {
          Object.entries(value.title).forEach(([key, val]) => {
            if (!val) {
              ctx.addIssue({
                code: "custom",
                path: ["values", index, "title", key],
                message: t("requiredField"),
              });
            }
          });
        }
      });
    }
  });

export const ProductSchema = z
  .object({
    show: z.string({ required_error: t("requiredField") }),
    sku: z
      .number({
        required_error: t("requiredField"),
        invalid_type_error: t("requiredField"),
      })
      .refine((val) => !isNaN(val), {
        message: t("requiredField"),
      })
      .refine((val) => val >= 0, {
        message: t("minValueIs", { value: 0 }),
      })
      .optional(),
    nameUa: z.string().nonempty(t("requiredField")),
    nameDe: z.string().nonempty(t("requiredField")),
    nameEn: z.string().nonempty(t("requiredField")),
    descriptionUa: z.string().optional(),
    descriptionEn: z.string().optional(),
    descriptionDe: z.string().optional(),
    price: z
      .number({
        required_error: t("requiredField"),
        invalid_type_error: t("requiredField"),
      })
      .refine((val) => !isNaN(val), {
        message: t("requiredField"),
      })
      .refine((val) => val >= 0, {
        message: t("minValueIs", { value: 0 }),
      })
      .optional(),
    amount: z
      .number({
        required_error: t("requiredField"),
        invalid_type_error: t("requiredField"),
      })
      .refine((val) => !isNaN(Number(val)) && Number(val) >= 0, {
        message: t("minValueIs", { value: 0 }),
      })
      .optional(),
    discountPrice: z
      .preprocess(
        (val) => (typeof val === "number" && !isNaN(val) ? val : undefined),
        z.number().optional()
      )
      .refine((val) => val === undefined || val >= 0, {
        message: t("minValueIs", { value: 0 }),
      }),

    preview: fileListSchema.optional(),
    photos: fileListSchema.optional(),
    oldPreview: z.string().optional(),
    oldPhotos: z.array(z.string()).optional(),
    formCharacteristics: z
      .array(
        z.object({
          ua: z.object({
            key: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
            value: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
          }),
          en: z.object({
            key: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
            value: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
          }),
          de: z.object({
            key: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
            value: z
              .string({ required_error: t("requiredField") })
              .nonempty({ message: t("requiredField") }),
          }),
        })
      )
      .optional(),
    variations: z
      .array(
        z.object({
          variation: z
            .string({
              required_error: t("requiredField"),
            })
            .nonempty({ message: t("requiredField") }),
          value: z
            .string({
              required_error: t("requiredField"),
            })
            .nonempty({ message: t("requiredField") }),
        })
      )
      .optional(),
  })
  .superRefine((data, ctx) => {
    const { price, discountPrice, preview, oldPreview } = data;

    if (price !== undefined && discountPrice !== undefined) {
      if (discountPrice > price) {
        ctx.addIssue({
          code: "custom",
          path: ["discountPrice"],
          message: t(
            "the.price.with.discount.cannot.be.more.than.price.product"
          ),
        });
      }
    }

    if (
      (!oldPreview || oldPreview.length === 0) &&
      (!preview || preview.length === 0)
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["preview"],
        message: t("requiredField"),
      });
    }
  });

export const formSchema = z
  .object({
    type: z.enum(productTypeOptions).refine((value) => !!value, {
      message: t("requiredField"),
    }),
    category: z.string({
      required_error: t("requiredField"),
      invalid_type_error: t("requiredField"),
    }),
    brand: z
      .string({ required_error: t("requiredField") })
      .nonempty(t("requiredField")),
    subcategories: z.array(
      z.object({
        parentId: z.string(),
        value: z.object({
          label: z.string().min(1, t("requiredField")),
          value: z.string().min(1, t("requiredField")),
        }),
      })
    ),
    variations: z.array(VariationDataSchema).optional(),
    group: z.string().optional(),

    product: ProductSchema.optional(),
    variationProducts: z.array(ProductSchema).optional(),
  })
  .superRefine((data, ctx) => {
    const { type, product, variationProducts } = data;

    if (type === "simple") {
      if (!product) {
        ctx.addIssue({
          code: "custom",
          path: ["product"],
          message: t("requiredField"),
        });
      }
      if (variationProducts && variationProducts.length > 0) {
        ctx.addIssue({
          code: "custom",
          path: ["variationProducts"],
          message: t("not.allowed.for.simple"),
        });
      }
    }

    if (type === "variated") {
      if (!variationProducts || variationProducts.length === 0) {
        ctx.addIssue({
          code: "custom",
          path: ["variationProducts"],
          message: t("requiredField"),
        });
      }
      if (product) {
        ctx.addIssue({
          code: "custom",
          path: ["product"],
          message: t("not.allowed.for.variated"),
        });
      }
    }
  });
export type TVariationInForm = z.infer<typeof VariationDataSchema>;
export type FormValues = z.infer<typeof formSchema>;
export type TProductInForm = z.infer<typeof ProductSchema>;
export type TDescription = {
  attributes: { value: string; key: string }[];
  createdAt: string;
  description: string;
  lang: "en" | "ua" | "de";
  product: string;
  title: string;
  updatedAt: string;
  _id: string;
};
