import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

type TProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deleteWarning?: string;
};

const DeleteWarningDialog = ({
  onConfirm,
  deleteWarning,
  open,
  onClose,
}: TProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete_confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteWarning || t("delete_warning")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleConfirm} color="error" autoFocus>
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteWarningDialog;
