import { nanoid } from "@reduxjs/toolkit";

export const defaultValue = (i: number) => ({
  id: nanoid(5),
  order: i + 5,
  title: {
    ua: "",
    en: "",
    de: "",
  },
  bgUrl: "",
  color: "",
});
