import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useTypedSelector } from "../../../../common";
import { getMainSettingSelector } from "../../../../store";
import { FormValues } from "../../types";

import { SubBarBlock, Button } from "./styled";

const ProductSubBar = ({ setActivePage, activePage }: any) => {
  const { t } = useTranslation();
  const { watch, trigger } = useFormContext<FormValues>();
  const { logo } = useTypedSelector(getMainSettingSelector);
  const { id } = useParams();
  const onClickVariations = async () => {
    if (watch("type") === "variated") {
      const isValid = await trigger("variations");
      if (isValid && watch("group")) {
        setActivePage("variations");
      } else {
        // dispatch(
        //   showToastAction.request(
        //     generateErrorToast("додайте і збережіть варіації")
        //   )
        // );
      }
    } else {
      setActivePage("variations");
    }
  };
  const menuSections = [
    {
      title: t("main.data"),
      type: "mainData",
      active: false,
      onClick: () => setActivePage("mainData"),
      isVisivle: true,
    },

    {
      title: t("variations"),
      type: "variations",
      active: false,
      onClick: () => onClickVariations(),
      isVisivle: watch("group"),
    },
    {
      title: t("related.orders"),
      type: "variationProducts",
      active: false,
      onClick: () => setActivePage("variationProducts"),
      isVisivle: watch("type") === "variated" && !!id,
    },
  ];

  return (
    <SubBarBlock>
      {menuSections.map((section, key) => {
        return (
          section.isVisivle && (
            <Button
              type="button"
              color={logo?.data?.mainColor}
              key={key}
              value={activePage}
              onClick={section.onClick}
              active={section.type === activePage && true}
            >
              {section.title}
            </Button>
          )
        );
      })}
    </SubBarBlock>
  );
};

export default ProductSubBar;
