import { TLang } from "../../types";
import { TCategory } from "../category/types";

export type TInitialState = {
  products: TProducts;
  description: any;
  loading: boolean;
  response: TProductResponse;
  newProduct: any;
  variationsBulk: any;
  variation: any;
  variationValue: any;
  variations: any;
  variationsValue: any;
  group: any;
  groups: {
    data: any;
    meta: any;
  };
  photoProduct: any;
  productsGroup: any;
};

export type TProducts = {
  data: TProduct[];
  meta: TMetaProducts | null;
};

export type TEditVariationRequestPayload = any;

export type TCreatePhotoProductRequestPayload = any;

export type TGetProductsDescriptionRequestPayload = {
  token: string;
  _id: string;
};

export type TEditVariationValueRequestPayload = any;

export type TCreateProductRequestPayload = {
  token: string;
} & TCreateProductPayload;

export type TCreateProductPayload = {
  data: any;
};

export type TSubcategory = {
  createdAt: string;
  lang: string;
  subCategory: string;
  title: string;
  updatedAt: string;
  _id: string;
};

export type TProduct = {
  _id: string;
  variations?: TVariationInProd[];
  group: {
    variations?: TVariation[];
    _id: string;
  };
  type: string;
  brand: string;
  gallery: TGaleryImage[];
  discountPrice: number;
  preview: string;
  category: TCategory;
  show: boolean;
  price: number;
  sellStatus: TSellStatus;
  sku: number | string;
  createdAt: string;
  updatedAt: string;
  description: TDescription;
  count: number;
  amount: number;
  subCategory?: TSubcategory;
};

export type TSellStatus = "available" | "notavailable";
type Title = [
  { lang: "en"; title: string },
  { lang: "de"; title: string },
  { lang: "ua"; title: string }
];

export type TVariation = {
  variation: {
    _id: string;
    order: number;
    type: "color" | "text";
    title: Title;
  };
  values: {
    bgUrl?: string;
    color?: string;
    order: number;
    title: Title;
    variation: string;
    _id: string;
  }[];
};

export type TVariationInProd = {
  variation: {
    _id: string;
    order: number;
    type: "color" | "text";
    title: Title;
  };
  value: {
    bgUrl?: string;
    color?: string;
    order: number;
    title: Title;
    variation: string;
    _id: string;
  };
};

export type TGaleryImage = {
  order: number;
  image: string;
};

export type TDescription = {
  attributes: TAttribute[];
  description: string;
  title: string;
  lang: TLang;
  product: string;
  createdAt: string;
  updatedAt: string;
};

export type TAttribute = {
  key: string;
  value: string;
};

export type TMetaProducts = {
  lang: TLang;
  totalCount: number;
  needReload: boolean;
};

export type TGetProductsRequestPayload = {
  token: string;
} & TGetProductsPayload;

export type TGetProductsGroupRequestPayload = {
  token: string;
} & TGetProductGroupPayload;

export type TGetProductGroupPayload = {
  field: string;
  value: string;
};

export type TGetProductsPayload = {
  limit?: number;
  page?: number;
  lang?: TLang;
  date_start?: string;
  date_end?: string;
  query?: string;
  sortBy?: string;
  order?: any;
  category?: string[];
  brand?: string[];
  sellStatus?: number;
  price_start?: number;
  price_end?: number;
  discountPrice?: number;
};

export type TGetProductsGroupPayload = any;

export type TSetProductStatePayload = {
  loading?: boolean;
  response?: TProductResponse;
};

export type TRemoveProductPayload = any;

export type TRemoveProductRequestPayload = any;

export type TGetProductPayload = {
  _id: string;
  lang?: string;
};

export type TGetProductRequestPayload = TGetProductPayload & {
  token: string;
};

export type TGetVariationValueRequestPayload = any;

export type TEditProductPayload = {
  _id: string;
  data: FormData;
};

export type TCreateVariationBulkRequestPayload = any;

export type TCreateVariationBulkPayload = any;

export type TGetGroupRequestPayload = any;

export type TCreateGroupRequestPayload = any;

export type TGetGroupsPayload = any;

export type TProductResponse =
  | "CREATED"
  | "CREATED_PHOTO"
  | "EDITED"
  | "NOT_FOUNDED"
  | "CREATED_GROUP"
  | "CREATED_VARIATION_VALUE"
  | "CREATED_VARIATION"
  | null;

export enum VariationType {
  color = "color",
  text = "text",
}

export type TEditProductRequestPayload = any;

export type TCreateGroupPayload = any;

export type TEditGroupPayload = any;

export type TEditGroupRequestPayload = any;

export type TCreateVariationValueRequestPayload = any;

export type TCreateVariationValuePayload = any;

export type TGetDescriptionPayload = any;

export type TRemoveVariationRequestPayload = {
  token: string;
  _id: string;
};

export type TVariationItem = {
  order: number;
  title: { title: string; lang: string }[];
  type: "color" | "text";
  _id: string;
};
export type TVariationValue = {
  bgUrl: string;
  color: string;
  order: number;
  title: { title: string; lang: string }[];
  variation: string;
  _id: string;
};
export type TVariationRes = {
  meta: TMetaProducts;
  data: { variation: TVariationItem; values: TVariationValue[] }[];
};

export type TCareateVariationData = {
  order: TVariationItem["order"];
  title: TVariationItem["title"];
  type: TVariationItem["type"];
  values: Omit<TVariationValue, "_id" | "variation">[];
}[];

export type TGroupVar = { variation: string; values: string[] };

export type TCreateGroupRes = {
  data: { variations: TGroupVar[]; _id: string };
  meta: TMetaProducts;
};

export type TCreateGroupData = { variations: TGroupVar[] };
