import styled from "styled-components";
import { colors } from "../../styles";
import { TImageProps, TWrapperStyledProps } from "./types";

export const Wrapper = styled.div<TWrapperStyledProps>`
  &.wrapper_image {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    padding: 0;
    width: auto;
    position: relative;
    &:hover {
      button {
        visibility: visible;
        opacity: 1;
      }
    }
    svg {
      width: ${({ width }) => width};
      height: ${({ height }) => height};
      color: ${colors.orange};
    }
  }
`;

export const StyledImage = styled.img<TImageProps>`
  width: ${({ width }) => width};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "auto"};
  height: ${({ height }) => height};
  border-radius: ${({ $borderRaduis }) => $borderRaduis};
  object-fit: ${({ $objectFit }) => $objectFit};
  cursor: ${({ $pointer }) => ($pointer ? "pointer" : "default")};
`;
export const DeleteButton = styled.button`
  &.delete_button {
    border: none;
    outline: none;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 6px;
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;
    svg {
      color: ${colors.red};
      width: 18px;
      height: 18px;
    }
  }
`;
