import React, {
	FC,
	SyntheticEvent,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {
	Assets,
	ButtonNew,
	Divider,
	DropDownList,
	FlexContainer, generateErrorToast,
	H2,
	ProductHorizontalCard,
	SelectProductPopup,
	Textarea,
	useTypedSelector,
} from "../../common";
import {
	DeliveryForm,
	PaymentStatus,
	PaymentType,
	TEditOrderData,
	TItem,
	TOrderStatus
} from "../../store/order/types";
import { api } from '../../common/config/api';
import { ORDER_RESPONSE, RESPONSE } from "../../store/order/consts";
import {
	getMainOrderSelector,
	orderActions,
	OrderStatuses
} from "../../store/order";
import {
	ApiProductService,
	deliveryActions,
	getMainDeliverySelector,
	getUserSelector, showToastAction,
} from "../../store";

import {
	ButtonBlock,
	Container,
	DateBlock,
	DateText,
	FlexStyledContainer,
	Header,
	Image,
	InputBlock,
	InputRef,
	Label,
	MainContainer,
	Title,
	TitleBlock,
	TotalSum,
	UserContainer,
	RecipientContainer,
	SumBlock,
} from './styled'
import { TCoordinates } from './types';
import {  getLocale} from "../../types";

const ProfileOder: FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const local = getLocale();

	const { order, response } = useTypedSelector(getMainOrderSelector);
	const { accessToken } = useTypedSelector(getUserSelector);
	const { delivery, deliveryWarehouse, calculate } = useTypedSelector(getMainDeliverySelector);

	const [activeProductsList, setActiveProductsList] = useState<string[]>([]);
	const [isProductPopupActive, setIsProductPopupActive] = useState<boolean>(false);

	const [form, setForm] = useState({
		paymentStatus: '',
		orderStatus: '',
		deliveryMethod: '',
		paymentType: '',
		items: [] as TItem[],
	});
	const [countryForm, setCountryForm] = useState<any>('');
	const [countryRef, setCountryRef] = useState('')
	const [departmentForm, setDepartmentForm] = useState<any>('')
	const [value, setValue] = useState<string | undefined>('');
	const [defaultAddressCourier, setDefaultAddressCourier] = useState<any>('')
	const [coordinates, setCoordinates] = useState<TCoordinates>({
		lat: 0,
		lon: 0
	});

	const Events = {
		inputChangeHandler: (e: SyntheticEvent) => {
			const {name, value} = e.target as HTMLInputElement
			setForm((props) => ({...props, [name]: value}))
		},
		productCardChangeHandler: (input: any) => {
			const _id: string = (input as HTMLInputElement).name
			const count: number = parseInt((input as HTMLInputElement).value)

			setForm((form) => ({
				...form,
				items: form.items.map((item) => (item.product._id === _id ? {...item, count} : item))
			}))
		},
		addProductClickHandler: (products: string[]) => {
			setIsProductPopupActive(false)
			setActiveProductsList(products)
		},
		closePopupClickHandler: () => {
			setIsProductPopupActive(false)
		},
		addButtonClickHandler: () => {
			setIsProductPopupActive(true)
		},
		removeProductClickHandler: (e: SyntheticEvent, _id: string) => {
			setActiveProductsList(activeProductsList.filter((item) => _id !== item))
		},
		saveButtonClickHandler: () => {
			Requests.editOrder()
		},
	};

	const Requests = {
		getOrder: () => {
			dispatch(orderActions.getOrder({_id: id as string, lang: local as string}))
		},
		editOrder: async () => {
			const data: TEditOrderData = {
				orderStatus: form.orderStatus as TOrderStatus,
				paymentType: form.paymentType,
				paymentStatus: form.paymentStatus,
				delivery: {
					cityRecipient: countryRef,
					form: form.deliveryMethod as DeliveryForm,
					address: form.deliveryMethod === 'delivery' ? departmentForm : value,
					lon: form.deliveryMethod === 'delivery' ? 0 : coordinates?.lon,
					lat: form.deliveryMethod === 'delivery' ? 0 : coordinates?.lat,
				},
				items: form.items.map((item) => ({
					count: item.count,
					product: item.product._id
				})) || undefined
			}
			if(form.deliveryMethod === 'delivery'){
				if(countryForm !== '' && departmentForm !== ''){
					dispatch(orderActions.editOrder({_id: id as string, data}))
				}else {
					dispatch(showToastAction.request(generateErrorToast(t('enter.city.enter.department'))))
				}
			}else {
				dispatch(orderActions.editOrder({_id: id as string, data}))
			}
		},
		getProduct: async (_id: string) => {
			return await ApiProductService.getProduct({_id, lang: local as string, token: accessToken as string})
		},
		canceledOrder: () => {
			const data = {
				orderStatus: 'canceled',
				paymentType: form.paymentType,
				paymentStatus: form.paymentStatus,
			}
			dispatch(orderActions.editOrder({_id: id as string, data: data as any}))
			navigate('../orders')
		},
	};

	const calculateSum = form?.items?.reduce((sum: number, el: any) => sum + el.product?.dimensions.weight, 0);

	const Utils = {
		totalSum: (): number => {
			let total = 0
			form.items.forEach((item) => {
				total += (item.count * (item.price ? item.price : item?.product?.discountPrice))
			})
			return parseFloat(total.toFixed(2))
		},
		clearAll: () => {
			setActiveProductsList([])
			dispatch(orderActions.setOrder(null))
			dispatch(orderActions.setOrderState({response: null}))
		}
	};

	const orderStatusOptions = useMemo(
		() =>
			Object.keys(OrderStatuses).map((prop) => ({
				name: t(prop),
				value: OrderStatuses[prop as keyof typeof OrderStatuses]
			})),
		[]
	);

	const deliveryForm = useMemo(
		() =>
			Object.keys(DeliveryForm).map((prop) => ({
				name: t(prop),
				value: DeliveryForm[prop as keyof typeof DeliveryForm]
			})),
		[]
	);

	useEffect(() => {
		if(order){
			setDefaultAddressCourier(order?.delivery?.address)
		}
	}, [order])

	const paymentStatusOptions = useMemo(
		() =>
			Object.keys(PaymentStatus).map((prop) => ({
				name: t(prop),
				value: PaymentStatus[prop as keyof typeof PaymentStatus]
			})),
		[]
	);

	const paymentTypeOptions = useMemo(
		() =>
			Object.keys(PaymentType).map((prop) => ({
				name: t(prop),
				value: PaymentType[prop as keyof typeof PaymentType]
			})),
		[]
	);

	useEffect(() => {
		Requests.getOrder()
	}, [])

	const {ref}: any = usePlacesWidget({
		language: 'ua',
		options: {
			types: ['address'],
		},
		apiKey: "AIzaSyDFIMKjdPiUc47BWjndFq6LlifowMsfz34",
		onPlaceSelected: (place) => {
			setValue(place.formatted_address)
			setCoordinates({
				lat: place.geometry.location.lat(),
				lon: place.geometry.location.lng(),
			})
		},
	});

	useEffect(() => {
		if (response === ORDER_RESPONSE.GET_ORDER_ERROR) navigate('../orders')
		if (response === RESPONSE.REMOVED) {
			navigate('../orders')
		}
	}, [response]);

	// Set items form
	useEffect(() => {
		order?.items &&
		setForm((form) => ({
			...form,
			orderStatus: order.orderStatus,
			paymentStatus: order.paymentStatus,
			paymentType: order.paymentType,
			deliveryMethod: order.delivery.form,
			items: order?.items.map((item: TItem) => item) as TItem[]
		}));
		order &&
		setCoordinates((coordinates) => ({
			...coordinates,
			lon: order?.delivery?.lon,
			lat: order?.delivery?.lat
		}));
		order &&
		setValue(order?.delivery?.address)
		order?.items && setActiveProductsList(order?.items.map((item: any) => item.product._id))
	}, [order])

	useEffect(() => {
		if(order){
			setCoordinates((coordinates) => ({
				...coordinates,
				lon: order?.delivery?.lon,
				lat: order?.delivery?.lat
			}));
			setValue(order?.delivery?.address)
			setCountryRef(order?.delivery?.cityRecipient)
			setDepartmentForm(order?.delivery?.address)
		}
	}, [order])

	useEffect(() => {
		dispatch(deliveryActions.getDelivery({limit: 8100}))
	}, []);

	useEffect(() => {
		;(response === ORDER_RESPONSE.GET_ORDER_SUCCESS || response === ORDER_RESPONSE.EDIT_SUCCESS) &&
		(async () => {
			const productIdList = form.items.map((item) => item.product._id)

			// Check for non existing product in form
			for (let activeProductId of activeProductsList) {
				if (!productIdList.includes(activeProductId)) {
					const response = await Requests.getProduct(activeProductId)
					const product = response.data.data

					product &&
					setForm((form) => ({
						...form,
						items: form.items.concat([
							{
								count: 1,
								price: product.discountPrice !== null ? product.discountPrice : product.price,
								product: product
							}
						])
					}))
				}
			}

			// Remove excessive products
			setForm((form) => ({
				...form,
				items: form.items.filter((item) => {
					return activeProductsList.includes(item.product._id)
				})
			}))
		})()
	}, [activeProductsList]);

	useEffect(() => {
		response === ORDER_RESPONSE.EDIT_ERROR && navigate('../orders')
		response === ORDER_RESPONSE.GET_ORDER_ERROR && navigate('../orders')
	}, [response])

	useEffect(() => {
		return Utils.clearAll()
	}, []);

	useEffect(() => {
		if(countryForm){
			setCountryRef(delivery?.data?.filter((el:any) => el.Description === countryForm)[0]?.Ref)
		}
	}, [countryForm])

	useEffect(() =>{
		if(countryRef && delivery){
			setCountryForm(delivery?.data?.filter((el:any) => el.Ref === countryRef)[0]?.Description)
		}
	}, [countryRef, delivery]);

	const handleBack = () => {
		navigate(-1)
	};

	useEffect(() => {
		if(countryRef){
			dispatch(deliveryActions.getDeliveryWarehouse({limit: 4500, cityRef:countryRef}))
		}
	}, [countryRef]);

	useEffect(() => {
		if(calculateSum){
			const data = {
				cityRecipient: countryRef,
				weight: calculateSum
			}
			dispatch(deliveryActions.createCalculate({ data }))
		}
	}, [calculateSum]);

	const inputHandlerCity = (e:any) => {
		setCountryForm(e.target.value)
	};

	const inputHandlerDepartment = (e:any) => {
		setDepartmentForm(e.target.value)
	};

	useEffect(() => {
		if(countryForm === ''){
			setDepartmentForm('')
		}
	}, [countryForm])

	// useEffect(() => {
	// 	if(form.deliveryMethod === 'delivery' || 'pickup'){
	// 		setDefaultAddressCourier('')
	// 	}
	// }, [form.deliveryMethod])
	//
	// useEffect(() => {
	// 	if(form.deliveryMethod === 'delivery' || 'pickup'){
	// 		setDefaultAddressCourier('')
	// 	}
	// }, [])

	return (
		<>
			<Container>
				<Header>
					<TitleBlock>
						<Image onClick={handleBack} src={Assets.ARROW_LEFT}/>
						<Title>{t('order')} №{order?.id}</Title>
						<DateBlock>
							<DateText>{t('order.date')}: {moment(order?.createdAt).format('YYYY-MM-DD HH:mm')}</DateText>
						</DateBlock>
					</TitleBlock>
					<FlexStyledContainer>
						<ButtonBlock>
							<ButtonNew theme="red" onClick={Requests.canceledOrder}>
								{t('cancel.order')}
							</ButtonNew>
						</ButtonBlock>
						<ButtonBlock>
							<ButtonNew theme="green" onClick={Events.saveButtonClickHandler}>
								{t('save')}
							</ButtonNew>
						</ButtonBlock>
					</FlexStyledContainer>
				</Header>
				<MainContainer>
					<FlexContainer style={{padding: '33px 30px'}} direction="column" gap="30px">
						<FlexContainer gap="62px">
							<UserContainer>
                  <span>
                     {order?.customer?.name} {order?.customer?.secondName}
                  </span>
								<span>{order?.customer?.email}</span>
								<span>{order?.customer?.phone}</span>
							</UserContainer>
							{
								order?.receiver?.anotherReceiver === true ?
									<RecipientContainer>
										<Label>{t('recipient')}</Label>
										<UserContainer>

											<>
												<span>
                     {order?.receiver?.name}
                      </span>
												<span>{order?.receiver?.phone}</span>
											</>
										</UserContainer>
									</RecipientContainer> : null
							}
							<DropDownList
								name="paymentType"
								label={t('payment.type')}
								placeholder={t('select.payment.type')}
								options={paymentTypeOptions}
								value={form.paymentType}
								onChange={Events.inputChangeHandler}
							/>
							<DropDownList
								name="paymentStatus"
								label={t('payment.status')}
								placeholder={t('select.payment.status')}
								options={paymentStatusOptions}
								value={form.paymentStatus}
								onChange={Events.inputChangeHandler}
							/>
							<DropDownList
								name="orderStatus"
								label={t('order.status')}
								placeholder={t('select.order.status')}
								options={orderStatusOptions}
								value={form.orderStatus}
								onChange={Events.inputChangeHandler}
							/>
						</FlexContainer>
						<FlexContainer gap="64px">
							<DropDownList
								name='deliveryMethod'
								label={t('delivery.method')}
								placeholder={t('select.delivery.method')}
								options={deliveryForm}
								value={form.deliveryMethod}
								onChange={Events.inputChangeHandler}
							/>
								<InputBlock>
									<Label>{t('delivery.address')}</Label>
									<InputRef
										ref={ref}
										disabled={form.deliveryMethod !== 'courier'}
										placeholder={t('enter.address')}
										defaultValue={form?.deliveryMethod === 'courier' ? defaultAddressCourier : ''}
									/>
								</InputBlock>
							<FlexContainer align={'center'} gap='50px'>
								{
									form.deliveryMethod === 'delivery' &&
									<FlexContainer gap='64px'>
										<Autocomplete
											style={{width: '318px'}}
											id="city"
											freeSolo
											options={delivery?.data?.map((el: any) => el?.Description)}
											value={countryForm ?? ''}
											renderOption={(props, option) => {
												return (
													<li{...props} key={option?.id}>
														{option}
													</li>
												);
											}}
											renderInput={(params:any) => {
												return <TextField
													{...params}
													name="city"
													label={t('city')}
													onClick={inputHandlerCity}
													onBlur={inputHandlerCity}
													onChange={inputHandlerCity}
												/>
											}}
										/>
										<Autocomplete
											style={{width: '320px'}}
											id="department"
											freeSolo
											options={deliveryWarehouse?.data?.map((el: any) => el?.Description)}
											value={departmentForm ?? ''}
											renderOption={(props, option) => {
												return (
													<li{...props} key={option.id}>
														{option}
													</li>
												);
											}}
											renderInput={(params) => {
												return <TextField
													{...params}
													name="department"
													label={t('department')}
													onClick={inputHandlerDepartment}
													onBlur={inputHandlerDepartment}
													onChange={inputHandlerDepartment}
												/>
											}}
										/>
									</FlexContainer>
								}
							</FlexContainer>
						</FlexContainer>
						<FlexContainer>
							<Textarea disabled={true} label={t('comment')} placeholder={t('no.comment')} width="698px" readonly>
								{order?.comment}
							</Textarea>
						</FlexContainer>
					</FlexContainer>
					<FlexContainer direction="column">
						<H2 style={{paddingLeft: '30px'}}>{t('product')}</H2>
						<FlexContainer direction="row">
							{form?.items.map((item) => (
								<ProductHorizontalCard
									_id={item.product._id}
									key={item.product._id}
									name={item.product._id}
									src={`${api.withImageAPI}/product/preview/${item.product.preview}`}
									productName={item.product?.description?.title}
									productNumber={item.product?.sku}
									price={item.price ? `${item?.price.toFixed(2)} ${t('грн/шт')}` : `${item?.product?.discountPrice.toFixed(0)} ${t('грн/шт')}`}
									onChange={Events.productCardChangeHandler}
									removeButtonClickHandler={Events.removeProductClickHandler}
									amount={item.count}
									readonly={order?.paymentStatus !== 'unpaid'}
								/>
							))}
						</FlexContainer>
						<Divider height={30}/>
						<FlexContainer justify="space-between" style={{padding: '0px 30px'}}>
							{order?.paymentStatus === 'unpaid' && (
								<ButtonNew height={40} width={139} onClick={Events.addButtonClickHandler}>
									{t('add')}
								</ButtonNew>
							)}
							<SumBlock>
								<TotalSum>{`${t('price')}: ${Utils.totalSum()} ${t('uan')}`}</TotalSum>
								{
									form.deliveryMethod === 'delivery' &&
										<TotalSum>{`${t('price.delivery')}`}:{calculate?.data[0]?.Cost ?? '0'} {t('uan')}</TotalSum>
								}
								{
									form.deliveryMethod === 'courier' &&
										<TotalSum>{`${t('price.courier')}`}: 0 {t('uan')}</TotalSum>
								}
							</SumBlock>
						</FlexContainer>
					</FlexContainer>
					{isProductPopupActive && (
						<SelectProductPopup
							closeClickHandler={Events.closePopupClickHandler}
							addProductClickHandler={Events.addProductClickHandler}
							activeItems={activeProductsList}
						/>
					)}
				</MainContainer>
			</Container>
		</>
	)
}

export default ProfileOder
