export const PRODUCT_IMAGES_LENGTH = 5;

export const defaultprod = {
  amount: 10,
  nameDe: "",
  nameEn: "",
  nameUa: "",
  price: undefined,
  show: "true",
  sku: undefined,
  descriptionDe: "",
  descriptionEn: "",
  descriptionUa: "",
  discountPrice: undefined,
  formCharacteristics: [],
  variations: [],
};
