import styled from "styled-components";
import { colors } from "../../../../common";

export const ProductWrap = styled.div`
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid ${colors.gray};
`;

export const ButtonWrap = styled.div`
  margin: 20px 0;
`;

export const MessageWindow = styled.h3`
  font-size: 28px;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  /* position: absolute; */
  right: 0;
  svg {
    width: 26px;
    height: 26px;
    color: ${colors.red};
  }
`;
