import styled from "styled-components";
import { colors } from "../../../../../../common";

export const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${colors.greyBg};
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ItemsWrap = styled.div`
  display: flex;
  gap: 20px;
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: 22px;
`;
