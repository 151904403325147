import styled from "styled-components";
import { colors } from "../../../../common";

export const VariationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const ValuesWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 30px;
`;
export const ColorPickerBlock = styled.div``;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 25px;
  gap: 25px;

  padding-bottom: 25px;
  flex-wrap: wrap;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.strokeInput};
  }
`;
