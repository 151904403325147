import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  ButtonNew,
  colors,
  FlexContainer,
  H2,
  Input,
  Pagination,
  PhotoPicker,
  Popup,
  SubPagesBar,
  Table,
  Textarea,
} from "../../../../common";

import {
  ButtonBlock,
  Container,
  FlexStyledContainer,
  Header,
  MainContainer,
  Title,
} from "../../styled";

import { BRAND_RESPONSE } from "../../../../store";

import { DROP_DOWN_LIST_LIMIT } from "./consts";
import { useBrandLogic } from "./useBrandLogic";

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});

  const {
    tableSortBy,
    removeButtonState,
    response,
    page,
    setPage,
    Events,
    Requests,
    paginationSections,
    columns,
    data,
    popupVisiablity,
    setPopupVisiablity,
    form,
    formType,
    sortParamsForm,
    brands,
    setForm,
  } = useBrandLogic();

  // Request to receive data
  useEffect(() => {
    Requests.getBrands();
    setSearchParams({ ...searchParams, page: "" + page });
  }, [page, sortParamsForm]);

  // Set default page uri
  useEffect(() => {
    setPage(
      !isNaN(parseInt(searchParams.get("page") as string))
        ? parseInt(searchParams.get("page") as string)
        : 0
    );
  }, []);

  useEffect(() => {
    if (response == BRAND_RESPONSE.CREATED) {
      setPopupVisiablity(false);
      setForm({
        name: "",
        photo: null,
        order: 1,
        description: "",
      });
      Requests.getBrands();
    }

    if (response == BRAND_RESPONSE.REMOVED) {
      Requests.getBrands();
    }

    if (response == BRAND_RESPONSE.EDITED) {
      Requests.getBrands();
    }
  }, [response]);

  return (
    <Container>
      <Header>
        <Title>{t("categories")}</Title>
        <FlexStyledContainer>
          {removeButtonState && (
            <ButtonBlock>
              <ButtonNew theme="red" onClick={Events.removeBrands}>
                {t("remove.all")}
              </ButtonNew>
            </ButtonBlock>
          )}
          <ButtonBlock>
            <ButtonNew theme="green" onClick={Events.addButtonClickListner}>
              {t("create.brand")}
            </ButtonNew>
          </ButtonBlock>
        </FlexStyledContainer>
      </Header>
      <MainContainer>
        <SubPagesBar sections={paginationSections} />

        <Table
          columns={columns}
          data={data}
          sortBy={tableSortBy}
          removeClickHandler={Events.removeBrandsHandler}
          checkboxClickHandler={Events.checkboxClickHandler}
          editClickHandler={Events.openEditButtonClickHandler}
          editable
          removable
        />

        <Pagination
          page={page}
          pageCount={
            brands.meta
              ? Math.ceil(brands.meta.totalCount / DROP_DOWN_LIST_LIMIT)
              : 1
          }
          onPageChange={Events.onPageChangeHandler}
        />

        {popupVisiablity && (
          <Popup backgroundClickListener={Events.closePopupClickHandler}>
            <FlexContainer
              justify="space-between"
              direction="column"
              style={{ height: "100%" }}
              wrap="nowrap"
            >
              <FlexContainer gap="20px" align="center" justify="center">
                {formType == "create" && (
                  <H2 style={{ marginTop: "40px" }}>{t("create.brand")}</H2>
                )}
                {formType == "edit" && (
                  <H2 style={{ marginTop: "40px" }}>{t("edit.brand")}</H2>
                )}

                <FlexContainer
                  gap="30px"
                  align="center"
                  justify="center"
                  style={{
                    padding: "30px 0px",
                    borderBottom: `1px solid ${colors.gray}`,
                  }}
                >
                  <Input
                    name="name"
                    label={t("name")}
                    placeholder={t("enter.name")}
                    value={form.name}
                    onChange={Events.inputChangeHandler}
                  />
                </FlexContainer>
                <FlexContainer
                  style={{
                    paddingLeft: "60px",
                  }}
                  gap="30px"
                  justify={"center"}
                >
                  <Textarea
                    width="500px"
                    name="description"
                    height="150px"
                    placeholder={t("enter.description")}
                    label={t("description")}
                    onChange={Events.inputChangeHandler}
                  >
                    {form.description}
                  </Textarea>
                </FlexContainer>
                <PhotoPicker
                  width={150}
                  height={150}
                  label={t("photo")}
                  onChange={Events.onPhotoChange}
                  value={formType == "edit" ? (form.photo as string) : null}
                />
                <FlexContainer
                  justify="center"
                  direction="column"
                  align="center"
                  gap="20px"
                ></FlexContainer>
              </FlexContainer>
              <FlexContainer
                style={{ marginBottom: "40px", marginTop: "20px" }}
                gap="30px"
                justify="center"
              >
                <ButtonNew
                  theme="gray"
                  style="transparant"
                  onClick={Events.closePopupClickHandler}
                >
                  {t("cancel")}
                </ButtonNew>

                {formType == "create" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.createButtonClickHandler}
                  >
                    {t("create")}
                  </ButtonNew>
                )}
                {formType == "edit" && (
                  <ButtonNew
                    theme="green"
                    onClick={Events.editButtonClickHandler}
                  >
                    {t("edit")}
                  </ButtonNew>
                )}
              </FlexContainer>
            </FlexContainer>
          </Popup>
        )}
      </MainContainer>
    </Container>
  );
};

export default IndexPage;
