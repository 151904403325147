import styled from "styled-components";

import { TPopupContainer } from "./types";

import { colors } from "../../styles";

export const BackgroundContainer = styled.div`
  display: flex;
  position: absolute;

  width: 100%;
  height: 100%;
  background-color: rgba(129, 129, 129, 0.8);

  top: 0;
  left: 0;
  z-index: 111;

  overflow-y: hidden;
`;

export const PopupContainer = styled.div<TPopupContainer>`
  width: ${({ width }) => width};
  max-width: 100%;
  max-height: 100%;
  height: ${({ height }) => height};
  background-color: ${colors.white};

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
`;
