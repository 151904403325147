import { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FlexContainer, TSection, TSort, useTypedSelector } from "../../common";
import { useTranslation } from "react-i18next";
import { TForm } from "./types";
import { availabilityActions, getMainAvailabilitySelector } from "../../store";
import { useDispatch } from "react-redux";
import { getLocale } from "../../types/global";
import moment from "moment";

export const useNotificationLogic = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const local = getLocale();
  const { availabilitys } = useTypedSelector(getMainAvailabilitySelector);

  const [searchForm, setSearchForm] = useState<TForm>({
    searchValue: "",
    startDateValue: "",
    endDateValue: "",
  });
  const [page, setPage] = useState<number>(0);
  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [searchParams, setSearchParams] = useSearchParams({});

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const paginationSections: TSection[] = useMemo(
    () => [
      {
        title: t("current.orders"),
        onClickHandler: () => {
          navigate("../orders");
        },
      },
      {
        title: t("notify.when.available"),
        active: true,
      },
      {
        title: t("archive"),
        onClickHandler: () => {
          navigate("../orders/archive");
        },
      },
    ],
    []
  );

  const Requests = {
    getAvailability: () => {
      dispatch(
        availabilityActions.getAvailabilitys({
          limit: 9,
          page,
          lang: local,
          regex: searchForm.searchValue,
          start: searchForm.startDateValue,
          end: searchForm.endDateValue,
          ...sortParamsForm,
        })
      );
    },
    removeAvailability: (_id: string) => {
      dispatch(availabilityActions.removeAvailability({ _id }));
    },
  };

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    onChangeHandler: (e: React.SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setSearchForm({ ...searchForm, [input.name]: input.value });
      Requests.getAvailability();
    },
    onSubmitSearchHandler: (e: SyntheticEvent) => {
      e.preventDefault();
      Requests.getAvailability();
    },
    editClickHandler: (e: SyntheticEvent, index: number) => {
      navigate(`../orders/profile/${availabilitys.data[index]._id}`);
    },
    sortToggleHandler: (sortBy: string, order: any) => {
      setSortParamsForm({ sortBy, order });
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    removeCheckedItemsHandler: () => {
      selectedItems.forEach((index) => {
        const { _id } = availabilitys?.data[index];
        Requests.removeAvailability(_id);
      });
      setRemoveButtonState(false);
      setSelectedItems([]);
      Requests.getAvailability();
    },
  };

  const data = useMemo(
    () =>
      availabilitys.data.map((order: any) => {
        const created_at = order.createdAt;
        return {
          id: <>{order.id}</>,
          createdAt: moment(created_at).format("DD.MM.YYYY HH:mm"),
          order: (
            <FlexContainer align="center" justify="center">
              <FlexContainer
                direction="column"
                align="flex-start"
                justify="flex-start"
                gap="4px"
                width="170px"
                style={{ padding: 20 }}
              >
                <p style={{ textAlign: "start" }}>
                  {order?.product?.description?.title}
                </p>
              </FlexContainer>
            </FlexContainer>
          ),
          customer: (
            <>
              <p>
                {order.customer?.name} {order.customer?.secondName}
              </p>
              <p>{order.customer?.email}</p>
              <p>{order.customer?.phone}</p>
            </>
          ),
        };
      }),
    [availabilitys]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("order.code"),
        accessor: "id",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("date"),
        accessor: "createdAt",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("order"),
        accessor: "order",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("client"),
        accessor: "customer",
        width: 350,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  return {
    page,
    setPage,
    searchForm,
    setSearchForm,
    sortParamsForm,
    searchParams,
    setSearchParams,
    setSortParamsForm,
    tableSortBy,
    paginationSections,
    Requests,
    removeButtonState,
    Events,
    data,
    columns,
  };
};
