import styled from "styled-components";
import { TOption, TStyledSelectProps } from "./types";
import {
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  StylesConfig,
} from "react-select";
import { colors } from "../../styles";

const dot = (color?: string) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: color ? 8 : 0,
    height: color ? 20 : 0,
    width: color ? 20 : 0,
  },
});

export const SelectWrap = styled.div<TStyledSelectProps>`
  position: relative;
  width: ${({ width }) => width};
  p {
    margin-bottom: 6px;
    font-weight: 500;
    margin-left: 12px;
    font-size: 14px;
  }
`;
export const ErrorP = styled.p`
  &.error {
    position: absolute;
    font-size: 12px;
    color: ${colors.red};
    font-weight: 400;
    margin-left: 0;
    top: 70px;
    min-height: 60px;
  }
`;
export const selectStyles = (
  height: string,
  width: string,
  error: boolean
): StylesConfig<TOption> => ({
  control: (
    provided: CSSObjectWithLabel,
    state: ControlProps<{ label: string; value: string }>
  ) => ({
    ...provided,
    height,
    width,
    padding: " 0 4px",
    borderRadius: "8px",
    boxShadow: "none",
    border: !error
      ? state.isFocused || state.menuIsOpen
        ? `2px solid #f39000`
        : `2px solid ${colors.strokeInput}`
      : `2px solid ${colors.red}`,
    "&:hover": {
      border:
        state.isFocused || state.menuIsOpen
          ? `2px solid #f39000`
          : `2px solid ${colors.strokeInput}`,
    },
  }),

  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: "none",
  }),

  dropdownIndicator: (
    provided: CSSObjectWithLabel,
    state: DropdownIndicatorProps<{ label: string; value: string }>
  ) => ({
    ...provided,
    padding: "0",
    display: "flex",
    color: state.isFocused ? "#f39000" : colors.strokeInput,
  }),

  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    width,
    overflow: "auto",
  }),

  option: (provided: CSSObjectWithLabel, { data, isSelected, isFocused }) =>
    // state: OptionProps<{ label: string; value: string ,}>
    ({
      ...provided,
      ...(data.color ? dot(data.color) : {}),
      backgroundColor: isSelected
        ? colors.orange
        : isFocused
        ? "#f8b066"
        : provided.backgroundColor,
      color: data.color ? data.color : colors.black,
      "&:active": {
        backgroundColor: "#f39000",
        color: isSelected ? "white" : provided.color,
      },
    }),

  singleValue: (provided: CSSObjectWithLabel, { data }) => ({
    ...provided,
    ...dot(data.color),
    color: colors.black,
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
});
