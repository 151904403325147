import React, { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  ButtonNew,
  Input,
  RelativePreloader,
  useTypedSelector,
} from "../../common";
import { deliveryActions, getMainDeliverySelector } from "../../store";

import {
  Container,
  Header,
  MainContainer,
  Title,
  FormContainer,
  FormBlock,
  Wrapper,
} from "./styled";
import { TForm } from "./types";

const Delivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState<TForm>({
    deliveryPrice: "",
    freeDeliveryPrice: "",
  });

  const { deliveryConfig, loading } = useTypedSelector(getMainDeliverySelector);

  console.log(deliveryConfig, "delvieryconfit");

  useEffect(() => {
    dispatch(deliveryActions.getDeliveryConfig({}));
  }, []);

  useEffect(() => {
    if (deliveryConfig) {
      setForm({
        deliveryPrice: deliveryConfig?.data?.deliveryPrice,
        freeDeliveryPrice: deliveryConfig?.data?.freeDeliveryPrice,
      });
    }
  }, [deliveryConfig]);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setForm((props) => ({ ...props, [name]: value }));
  };

  const saveDeliveryHandler = () => {
    const data = {
      deliveryPrice: +form.deliveryPrice,
      freeDeliveryPrice: +form.freeDeliveryPrice,
    };
    dispatch(deliveryActions.updateDeliveryConfig({ data }));
    dispatch(deliveryActions.getDeliveryConfig({}));
  };

  return (
    <>
      {!loading ? (
        <Container>
          <Header>
            <Title>{t("delivery")}</Title>
            <ButtonNew onClick={saveDeliveryHandler} theme={"green"}>
              {t("save")}
            </ButtonNew>
          </Header>
          <MainContainer>
            <Wrapper>
              <Title>{t("paid.delivery.within.city")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="deliveryPrice"
                    label={t("price.delivery")}
                    type="number"
                    value={form?.deliveryPrice}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="priceProduct"
                    label={t("price.product")}
                    disabled={true}
                    value={"0"}
                    onChange={() => ""}
                  />
                </FormBlock>
              </FormContainer>
            </Wrapper>
            <Wrapper>
              <Title>{t("free.delivery.within.city")}</Title>
              <FormContainer>
                <FormBlock>
                  <Input
                    width={"500px"}
                    name="freePriceDeliveryText"
                    label={t("price.delivery")}
                    placeholder={t("enter.discount")}
                    value={"0"}
                    disabled={true}
                    onChange={() => ""}
                  />
                </FormBlock>
                <FormBlock>
                  <Input
                    width={"500px"}
                    type="number"
                    name="freeDeliveryPrice"
                    label={t("price.product")}
                    placeholder={t("enter.discount")}
                    value={form.freeDeliveryPrice}
                    onChange={inputChangeHandler}
                  />
                </FormBlock>
              </FormContainer>
            </Wrapper>
          </MainContainer>
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </>
  );
};

export default Delivery;
