import React, { forwardRef } from "react";
import { getMainSettingSelector } from "../../../store";
import { useTypedSelector } from "../../hooks";
import { FlexContainer, Label } from "../Styled";
import { StyledTextarea } from "./styled";
import { TTextarea } from "./types";

const Textarea = forwardRef<HTMLTextAreaElement, TTextarea>(
  (
    {
      children,
      label,
      readonly,
      height = "192px",
      width = "100%",
      placeholder = "",
      onChange,
      name = "",
      disabled,
      defaultValue,
    },
    ref
  ) => {
    const { logo } = useTypedSelector(getMainSettingSelector);

    return (
      <>
        <FlexContainer direction="column">
          {label && <Label>{label}</Label>}
          <StyledTextarea
            ref={ref}
            disabled={disabled}
            color={logo?.data?.mainColor}
            name={name}
            readOnly={readonly}
            value={children as string}
            height={height}
            width={width}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </FlexContainer>
      </>
    );
  }
);

export default Textarea;
