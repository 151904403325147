import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DefaultInput, Textarea } from "../../../../common";
import { FormValues } from "../../types";
import { FormBlock } from "./styled";

export const NameWrap = ({ productIndex }: { productIndex?: number }) => {
  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const basePath =
    productIndex !== undefined
      ? (`variationProducts.${productIndex}` as const)
      : ("product" as const);

  const langArray = ["ua", "en", "de"] as const;

  return (
    <FormBlock>
      {langArray.map((item) => {
        const name = () => {
          if (item === "ua") {
            return "nameUa";
          } else if (item === "de") {
            return "nameDe";
          } else if (item === "en") {
            return "nameEn";
          } else return "nameUa";
        };

        return (
          <div key={item}>
            {" "}
            <DefaultInput
              width="100%"
              {...register(`${basePath}.${name()}`)}
              label={t(`enter.name.${item}`)}
              placeholder={t(`enter.name.${item}`)}
              error={
                productIndex === undefined
                  ? errors?.product?.[name()]?.message
                  : errors?.variationProducts?.[productIndex]?.[name()]?.message
              }
            />
          </div>
        );
      })}
      {langArray.map((item, i) => {
        const name = () => {
          if (item === "ua") {
            return "descriptionUa";
          } else if (item === "de") {
            return "descriptionDe";
          } else if (item === "en") {
            return "descriptionEn";
          } else return "descriptionUa";
        };

        return (
          <Controller
            control={control}
            key={item + i}
            name={`${basePath}.${name()}`}
            render={({ field: { value, onChange } }) => (
              <Textarea
                width="100%"
                height="260px"
                placeholder={t(`enter.description.${item}`)}
                label={t("description")}
                onChange={onChange}
                // ref={ref}
              >
                {value}
              </Textarea>
            )}
          />
        );
      })}
    </FormBlock>
  );
};
