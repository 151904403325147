import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Assets, ButtonNew, DefaultInput } from "../../../../common";

import { ColorPickerProduct } from "../ColorPickerProduct";

import { Delete, Image, Label, Name, VariationBlock } from "../../styled";
import { FormValues } from "../../types";
import { useFormContext, useFieldArray } from "react-hook-form";
import {
  ValuesWrapper,
  VariationsHeader,
  ColorPickerBlock,
  InputsWrapper,
} from "./styled";
import Dialog from "../../../../common/components/Dialog/Dialog";
import { defaultValue } from "./helpers";

const VariationValue = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [indexElement, setIndexElement] = useState(0);

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const onDeleteClick = (i: number) => {
    setIndexElement(i);
    openDialog();
  };

  const onConfirm = () => {
    const currentVariationId = watch(`variations.${index}`).id;
    const currentValueId = watch(
      `variations.${index}.values.${indexElement}`
    ).id;

    const variationProducts = watch("variationProducts");

    const updatedVariationProducts = variationProducts?.map((product) => ({
      ...product,
      variations: product.variations?.map((variation) => {
        if (
          variation.variation === currentVariationId &&
          variation.value === currentValueId
        ) {
          return { ...variation, value: "" };
        }
        return variation;
      }),
    }));

    setValue("variationProducts", updatedVariationProducts);

    remove(indexElement);
  };

  const {
    control,
    watch,
    setValue,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `variations.${index}.values`,
  });

  const addValues = () => {
    append(defaultValue(index));
  };

  const onSelectColor = (color: string, i: number) => {
    setValue(`variations.${index}.values.${i}.color`, color);
    setValue(`variations.${index}.values.${i}.bgUrl`, color);
    trigger(`variations.${index}.values.${i}.color`);
  };

  const selectedColors: string[] =
    watch(`variations`)
      ?.[index]?.values?.map((item) => item.color)
      .filter((color): color is string => !!color) || [];

  return (
    <VariationBlock>
      <Dialog onConfirm={onConfirm} open={open} onClose={closeDialog} />
      <VariationsHeader>
        <Name>{t("value.for.variation")}</Name>
      </VariationsHeader>
      <ValuesWrapper>
        {fields?.map((item, i) => {
          return (
            <InputsWrapper key={item.id}>
              {watch("variations")?.[index]?.type === "text" ? (
                <>
                  <DefaultInput
                    width="300px"
                    placeholder={`${t("enter.title")} ua`}
                    {...register(`variations.${index}.values.${i}.title.ua`)}
                    error={
                      errors?.variations?.[index]?.values?.[i]?.title?.ua
                        ?.message
                    }
                  />
                  <DefaultInput
                    width="300px"
                    placeholder={`${t("enter.title")} en`}
                    {...register(`variations.${index}.values.${i}.title.en`)}
                    error={
                      errors?.variations?.[index]?.values?.[i]?.title?.en
                        ?.message
                    }
                  />
                  <DefaultInput
                    width="300px"
                    placeholder={`${t("enter.title")} de`}
                    {...register(`variations.${index}.values.${i}.title.de`)}
                    error={
                      errors?.variations?.[index]?.values?.[i]?.title?.de
                        ?.message
                    }
                  />
                </>
              ) : (
                <ColorPickerBlock>
                  <Label>{t("display.type")}</Label>
                  <ColorPickerProduct
                    error={
                      errors?.variations?.[index]?.values?.[i]?.color?.message
                    }
                    selectedColors={selectedColors}
                    changeColor={(color) => onSelectColor(color, i)}
                    selectColor={
                      watch(`variations`)?.[index]?.values?.[i]?.color || ""
                    }
                  />
                </ColorPickerBlock>
              )}
              {fields.length > 1 && (
                <Delete onClick={() => onDeleteClick(i)}>
                  <Image src={Assets.CROSS} />
                  {t("delete")}
                </Delete>
              )}
            </InputsWrapper>
          );
        })}
      </ValuesWrapper>
      <ButtonNew onClick={addValues} width={139} height={40}>
        {t("add")}
      </ButtonNew>
    </VariationBlock>
  );
};

export default VariationValue;
