import { SyntheticEvent, useMemo, useState } from "react";
import {
  ApiCategoryService,
  getMainSubcategorySelector,
  getUserSelector,
  showToastAction,
  subcategoryActions,
} from "../../../../store";
import {
  TOption,
  TSort,
  generateErrorToast,
  useTypedSelector,
  useValidation,
} from "../../../../common";
import { DROP_DOWN_LIST_LIMIT } from "./consts";
import { TCategory } from "../../../../store/category/types";
import { TForm, TFormTypes, TSubCategoryValidationSchema } from "./types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLocale } from "../../../../types";
import { useNavigate } from "react-router-dom";
import { PAGE_LIMIT } from "../../consts";

export const useSubcategoriesLogic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { subcategories, description } = useTypedSelector(
    getMainSubcategorySelector
  );
  const { accessToken } = useTypedSelector(getUserSelector);

  const locale = getLocale();

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);

  const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

  const [popupVisiablity, setPopupVisiablity] = useState<boolean>(false);

  const [formType, setFormType] = useState<TFormTypes>("create");

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [form, setForm] = useState<TForm>({
    nameUa: "",
    nameEn: "",
    nameDe: "",
    _id: "",
    category: { value: "", label: "" },
  });

  const [sortParamsForm, setSortParamsForm] = useState({
    sortBy: "",
    order: "" as TSort,
  });

  const tableSortBy = useMemo(() => {
    {
      if (!sortParamsForm.order) {
        return { id: null, desc: false };
      }
      return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 };
    }
  }, [sortParamsForm]);

  const data = useMemo(
    () =>
      subcategories.data.map((subcategory) => {
        return {
          title: subcategory.title,
          productsTotalCount: <>{subcategory.productsTotalCount}</>,
        };
      }),
    [subcategories]
  );

  const Requests = {
    getSubcategories: () => {
      dispatch(
        subcategoryActions.getSubcategories({
          limit: PAGE_LIMIT,
          page: page,
          lang: locale,
          ...sortParamsForm,
        })
      );
    },

    getCategories: async (page: number) => {
      return await ApiCategoryService.getCategories({
        token: accessToken as string,
        page,
        lang: locale,
        limit: DROP_DOWN_LIST_LIMIT,
      });
    },
    createSubcategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameDe,
          lang: "de",
        })
      );
      data.append("category", form.category.value);
      dispatch(subcategoryActions.createSubcategory({ data }));
    },
    removeSubcategories: (_ids: string[]) => {
      dispatch(subcategoryActions.removeSubcategories({ _ids }));
    },
    editSubcategory: () => {
      const data = new FormData();

      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameEn,
          lang: "en",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameUa,
          lang: "ua",
        })
      );
      data.append(
        "description[]",
        JSON.stringify({
          title: form.nameDe,
          lang: "de",
        })
      );
      form.category && data.append("category", form.category.value);
      dispatch(
        subcategoryActions.editSubcategory({
          data,
          _id: form._id as string,
        })
      );
    },
    getCategory: async (_id: string) => {
      return await ApiCategoryService.getCategory({
        token: accessToken as string,
        _id,
        lang: locale,
      });
    },
  };

  const schema = useMemo<TSubCategoryValidationSchema>(
    () => ({
      name: {
        condition: !!form.nameDe && !!form.nameEn && !!form.nameUa,
        error: t("enter.the.subcategory.name.in.all.languages"),
      },
      category: {
        condition: !!form.category.value,
        error: t("select.category"),
      },
    }),
    [form]
  );

  const {
    validation,
    enableValidation,
    disableValidation,
    //eslint-disable-next-line
  } = useValidation(schema);

  const Events = {
    onPageChangeHandler: ({ selected }: { selected: number }) => {
      setPage(selected);
    },
    sortToggleHandler: (sortBy: string, order: any) => {
      setSortParamsForm({ sortBy, order });
    },
    closePopupClickHandler: () => {
      setPopupVisiablity(false);
    },
    inputChangeHandler: (e: SyntheticEvent) => {
      const { name, value } = e.target as HTMLInputElement;
      setForm((props) => ({ ...props, [name]: value }));
    },
    createButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.createSubcategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    editButtonClickHandler: async () => {
      try {
        enableValidation();
        await validation();
        Requests.editSubcategory();
        setPopupVisiablity(false);
        disableValidation();
      } catch (error: any) {
        error?.map((err: string) => {
          dispatch(showToastAction.request(generateErrorToast(err)));
        });
      }
    },
    addButtonClickListner: () => {
      setForm({
        nameUa: "",
        nameEn: "",
        nameDe: "",
        category: { value: "", label: "" },
      });
      setFormType("create");
      setPopupVisiablity(true);
    },
    removeSubcategories: () => {
      Requests.removeSubcategories(
        selectedItems.map((index) => subcategories.data[index]._id)
      );
      setRemoveButtonState(false);
      setSelectedItems([]);
    },

    loadCategoriesOptions: async (
      search: string,
      loadOptions: any,
      additions: any
    ) => {
      const page = additions?.page || 0;

      const options = await Requests.getCategories(page as number);

      return {
        options: (options.data.data as TCategory[]).map((item) => ({
          value: item._id,
          label: item.title,
        })),
        hasMore: options.data.meta.totalCount > page * DROP_DOWN_LIST_LIMIT,
        additional: {
          page: page + 1,
        },
      };
    },
    paginateDropDownChangeHandler: (value: TOption) => {
      setForm((form) => ({ ...form, category: value }));
    },
    removeCategoryHandler: (e: SyntheticEvent, index: number) => {
      Requests.removeSubcategories([subcategories.data[index]._id]);
    },
    checkboxClickHandler: (
      e: SyntheticEvent,
      hasCheckboxesActiveState: boolean,
      ckeckedItemsIndex: number[]
    ) => {
      setRemoveButtonState(hasCheckboxesActiveState);
      setSelectedItems(ckeckedItemsIndex);
    },
    openEditButtonClickHandler: async (e: SyntheticEvent, index: number) => {
      const response = await Requests.getCategory(
        subcategories.data[index].category
      );
      await dispatch(
        subcategoryActions.getSubcategoryDescription({
          _id: subcategories.data[index]._id,
        })
      );
      setForm({
        nameUa: description?.data[1]?.title,
        nameEn: description?.data[0]?.title,
        nameDe: description?.data[2]?.title,
        _id: subcategories.data[index]._id,
        category: {
          value: subcategories.data[index].category,
          label: response.data.data.title,
        },
      });
      setFormType("edit");
      setPopupVisiablity(true);
    },
    onChange: (e: SyntheticEvent) => {
      const input = e.target as HTMLInputElement;
      setForm({ ...form, [input.name]: input.value });
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "title",
        width: 500,
        sortToggleHandler: Events.sortToggleHandler,
      },
      {
        Header: t("total.product"),
        accessor: "productsTotalCount",
        width: 500,
        sortToggleHandler: Events.sortToggleHandler,
      },
    ],
    []
  );

  const paginationSections = useMemo(
    () => [
      // {
      //   title: t("sections"),
      //   onClickHandler: () => {
      //     navigate("/catalog/sections");
      //   },
      // },
      {
        title: t("categories"),
        onClickHandler: () => {
          navigate("/catalog/categories");
        },
      },
      // {
      //   title: t("subcategories"),
      //   active: true,
      // },
      {
        title: t("brands"),
        onClickHandler: () => {
          navigate("/catalog/brand");
        },
      },
    ],
    []
  );
  return {
    Requests,
    Events,
    paginationSections,
    removeButtonState,
    setRemoveButtonState,
    page,
    setPage,
    columns,
    tableSortBy,
    data,
    form,
    setForm,
    setFormType,
    formType,
    sortParamsForm,
    setSortParamsForm,
    popupVisiablity,
    setPopupVisiablity,
  };
};
